import React, { FC, useEffect } from 'react';
import { ModalProps } from '@meindach/ui-kit';
import { P, Tag } from '@meindach/ui-kit';
import { Content, Name, Company, Header, StyledModal } from './styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '@meindach/ui-kit/dist/assets/ui/close.svg';
import { ReactComponent as InfoIcon } from '@meindach/ui-kit/dist/assets/icons/info.svg';
import { ReactComponent as LeadIcon } from '@meindach/ui-kit/dist/assets/icons/lead-assigned.svg';
import { OpportunityDetails } from './OpportunityDetails';
import { TestAttribute } from '../../types/TestAttribute';
import { trackEvent } from '../../util/trackEvent';
import { OpportunityActions } from './OpportunityActions';
import { Opportunity } from '../../types/Opportunity';
import { PanelTabs } from '../../components/panel/PanelTabs';
import { InfoTable } from './InfoTable';
import { User } from '../../types/User';
import {
  OpportunityStatusTag,
  OpportunityStatusIcon,
} from '../../components/opportunityCard/OpportunityStatus';

interface Props extends Omit<ModalProps, 'onClose'> {
  userId: User['id'];
  opportunity: Opportunity;
  onClose: () => void;
}

export const OpportunityModal: FC<Props> = ({
  opportunity,
  onClose,
  userId,
}) => {
  const { t } = useTranslation('opportunityInfo');
  const {
    id,
    name,
    fullName,
    salesStage,
    solar,
    grant,
    roofType,
  } = opportunity;
  const referenceNumber = name.split(' ')[0];

  useEffect(() => {
    trackEvent('opportunity', 'openInfo', id);
  }, [id]);

  return (
    <StyledModal onClose={onClose}>
      <Header>
        <div>
          <OpportunityStatusIcon roofType={roofType} salesStage={salesStage} />
        </div>
        <Content>
          <P>{referenceNumber}</P>
          <Name>{fullName}</Name>
          <Company>
            <OpportunityStatusTag salesStage={salesStage} t={t} />
            {grant && <Tag type="warning">{t('type:grant')}</Tag>}
            {solar && <Tag type="success">{t('type:solar')}</Tag>}
          </Company>
        </Content>
        <div>
          <CloseIcon
            onClick={onClose}
            data-test-id={TestAttribute.ModalClose}
          />
        </div>
      </Header>
      <PanelTabs
        tabs={[
          {
            icon: InfoIcon,
            component: <OpportunityDetails {...opportunity} />,
          },
          {
            icon: LeadIcon,
            component: <InfoTable {...opportunity} />,
          },
        ]}
      />
      <OpportunityActions {...opportunity} onClose={onClose} userId={userId} />
    </StyledModal>
  );
};
