import styled, { css } from '../../styled-components';

export const Tabs = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  box-shadow: 0 -4px 4px 0 ${({ theme }) => theme.color.shadow};

  > * {
    border-left: 0.5px solid ${({ theme }) => theme.color.mercury};
    border-right: 0.5px solid ${({ theme }) => theme.color.mercury};
  }

  :first-child {
    border-left-color: transparent;
  }
  :last-child {
    border-right-color: transparent;
  }
`;

export interface TabProps {
  active?: boolean;
  disabled?: boolean;
}

export const Tab = styled.div<TabProps>`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  cursor: pointer;
  padding: 0 ${({ theme }) => theme.spacing.s};

  overflow: hidden;

  > svg {
    flex-shrink: 0;
    width: 29px;
    height: 29px;

    margin-right: ${({ theme }) => theme.spacing.s};
  }
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 16px;
  font-weight: 600;

  ${({ active, theme }) =>
    css`
      background-color: ${active ? theme.color.cyan200 : theme.color.white};
      color: ${active ? theme.color.blue : theme.color.silver};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      > svg,
      > span {
        opacity: 0.4;
      }
    `}
`;
