import React, { FC } from 'react';
import { trackEvent } from '../../util/trackEvent';
import { ReactComponent as PhoneIcon } from '@meindach/ui-kit/dist/assets/icons/phone.svg';
import { ReactComponent as MailIcon } from '@meindach/ui-kit/dist/assets/icons/mail.svg';
import { Consultant } from '../../types/Consultant';
import { ModalActions } from '../../components/modalActions/ModalActions';

type Props = Pick<Consultant, 'id' | 'phone' | 'email'>;

export const ConsultantActions: FC<Props> = ({ id, phone, email }) => (
  <ModalActions>
    <a
      href={`tel:${phone}`}
      onClick={() => trackEvent('consultant', 'call', id)}
    >
      <PhoneIcon />
    </a>
    <a
      href={`mailto:${email}`}
      onClick={() => trackEvent('consultant', 'email', id)}
    >
      <MailIcon />
    </a>
  </ModalActions>
);
