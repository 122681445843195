import { useState, useEffect } from 'react';
import { useConsultants } from './useConsultants';
import { Consultant } from '../types/Consultant';

export const useConsultant = (consultantEmail: string) => {
  const consultants = useConsultants();
  const [consultant, setConsultant] = useState<Consultant | null>(null);

  useEffect(() => {
    if (!consultantEmail) {
      return;
    }
    if (consultants.length < 1) {
      setConsultant(null);
      return;
    }
    setConsultant(
      consultants.find(({ email }) => email === consultantEmail) || null,
    );
  }, [consultants, consultantEmail]);

  return consultant;
};
