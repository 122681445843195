import styled from '../../styled-components';
import { Title } from '@meindach/ui-kit';

export const Container = styled.div`
  position: relative;
  z-index: 1;
  height: 74px;
  background: ${({ theme }) => theme.color.alabaster};

  box-shadow: 0 4px 4px 0 ${({ theme }) => theme.color.shadow};
  box-sizing: border-box;

  display: flex;
  align-items: center;

  padding: 0 ${({ theme }) => theme.spacing.m};

  > ${Title} {
    margin: 0 ${({ theme }) => theme.spacing.l} 0 0;
  }
`;
