import React, { FC, memo } from 'react';
import { Select, SelectProps, Option } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { Sort } from '../../types/Sort';
import { Container, SortIcon, StyledLabel } from './styled';

interface Props {
  sort: Sort;
  setSort: (sort: Sort) => void;
  options?: Option<Sort>[];
}

const SortSelect = Select as FC<SelectProps<Sort>>;
const defaultOptions: Option<Sort>[] = [
  { value: Sort.AlphabeticalAscending },
  { value: Sort.AlphabeticalDescending },
];

export const ListSort: FC<Props> = memo(
  ({ sort, setSort, options = defaultOptions }) => {
    const { t } = useTranslation('sorting');
    return (
      <>
        <StyledLabel>{t('label')}</StyledLabel>
        <Container>
          <SortSelect
            options={options.map(({ value }) => ({ value, label: t(value) }))}
            selectedValues={[sort]}
            onChange={setSort}
            icon={SortIcon}
          />
        </Container>
      </>
    );
  },
);
