import React, { FC, useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Title, P, FeedbackMessage } from '@meindach/ui-kit';
import { useTranslation, Trans } from 'react-i18next';
import { Container, IllustrationContainer, Content } from './styled';
import { ReactComponent as Illustration } from '../../assets/illustration-check-mail.svg';
import { RouteComponentProps } from 'react-router';
import { TestAttribute } from '../../types/TestAttribute';

type Status = 'invalid' | 'success' | 'fail' | null;
type Props = Pick<RouteComponentProps, 'location'>;

export const EmailVerified: FC<Props> = ({ location }) => {
  const { t } = useTranslation('emailVerified');
  const { changePassword } = useAuth();
  const [emailStatus, setEmailStatus] = useState<Status>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    if (email) {
      const callChangePassword = async (email: string) => {
        try {
          await changePassword(email);
          setEmailStatus('success');
        } catch {
          setEmailStatus('fail');
        }
      };
      callChangePassword(email);
    } else {
      setEmailStatus('invalid');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const getFeedbackType = (
    status: Status,
  ): 'success' | 'danger' | undefined => {
    switch (status) {
      case 'success':
        return 'success';
      case 'fail':
      case 'invalid':
        return 'danger';
      default:
        return undefined;
    }
  };
  const getInfoText = (status: Status): string => {
    switch (status) {
      case 'success':
        return 'sendSuccess';
      case 'fail':
        return 'sendFail';
      case 'invalid':
        return 'invalid';
      default:
        return 'sending';
    }
  };
  return (
    <Container>
      <Content>
        {emailStatus !== 'invalid' && (
          <>
            <Title xl>{t('title')}</Title>
            <P>
              <Trans i18nKey="emailVerified:info">
                <strong />
                <br />
              </Trans>
            </P>
          </>
        )}
        <FeedbackMessage
          type={getFeedbackType(emailStatus)}
          data-test-id={TestAttribute.EmailVerifiedFeedback}
        >
          {t(getInfoText(emailStatus))}
        </FeedbackMessage>
        <IllustrationContainer>
          <Illustration />
        </IllustrationContainer>
      </Content>
    </Container>
  );
};
