import styled from '../../styled-components';
import { P, Modal, media } from '@meindach/ui-kit';
import { ReactComponent as LoadingIcon } from '@meindach/ui-kit/dist/assets/loading/loading.svg';

export const StyledModal = styled(Modal)`
  max-width: 410px;
  margin: 0 auto;
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.18px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m};
  margin-bottom: -1px;

  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &:first-child {
      flex: 0;
      margin-right: ${({ theme }) => theme.spacing.m};
    }

    &:last-child {
      flex: 0;
      margin-left: ${({ theme }) => theme.spacing.m};
      color: ${({ theme }) => theme.color.silver};
      cursor: pointer;

      > svg {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  ${Name} {
    margin: 0;
  }
  ${P} {
    margin: 0 0 ${({ theme }) => theme.spacing.xs};
    color: ${({ theme }) => theme.color.silver};
    font-size: 14px;
    line-height: 14px;
    ${media.md`
      font-size: 14px;
      line-height: 14px;
    `}
  }
`;

export const Company = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${({ theme }) => theme.color.red};
    margin: 0;
    margin-right: ${({ theme }) => theme.spacing.m};
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.18px;
  }
  *:not(p) {
    margin-right: ${({ theme }) => theme.spacing.s};
    margin-top: ${({ theme }) => theme.spacing.xs};
  }

  > svg {
    width: auto;
    height: 24px;
  }
`;

export const Entry = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  display: flex;

  > ${P} {
    flex: 60;
    margin: ${({ theme }) => theme.spacing.s} 0;
    &:first-child {
      flex: 40;
      margin-right: ${({ theme }) => theme.spacing.s};
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const Details = styled.div`
  padding: ${({ theme }) => theme.spacing.m};
`;

export const DisabledLink = styled.a`
  pointer-events: none;
  &&& > svg {
    color: ${({ theme }) => theme.color.silver};
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 121px;
  pointer-events: none;
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

export const PassiveAction = styled(Entry)`
  > ${P} {
    display: flex;
    align-items: center;
    &:first-child {
      flex: 0;
      color: ${({ theme }) => theme.color.storm};
    }
    > svg {
      width: 25px;
      height: 25px;
    }
  }
`;

export const InfoContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.m};
`;

export const EntryLabel = styled.div`
  background: ${({ theme }) => theme.color.alabaster};
  font-weight: 600;
  color: ${({ theme }) => theme.color.slate};
  font-size: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  height: 36px;
  line-height: 36px;
  padding: 0 ${({ theme }) => theme.spacing.s};
`;

export const EntryValue = styled.div`
  color: ${({ theme }) => theme.color.slate};
  font-size: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  height: 44px;
  line-height: 36px;
  padding: 0 ${({ theme }) => theme.spacing.s};

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  }
`;

export const Loading = styled(LoadingIcon)`
  width: 160px;
  height: 20px;
  margin: ${({ theme }) => theme.spacing.m} auto;
  display: block;
`;
