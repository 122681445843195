import styled, { createGlobalStyle, css } from '../../styled-components';
import { hexToRGBA } from '../../utils/hexToRGBA';

export const Overlay = styled.div<{ clickable: boolean }>`
  position: absolute;
  background-color: ${({ theme }) => hexToRGBA(theme.color.black, 0.33)};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

export const Container = styled.div`
  position: absolute;
  padding: ${({ theme }) => theme.spacing.s};
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  min-height: 100%;
  align-items: center;
`;

export const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.color.silver};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 2px 10px 0 ${({ theme }) => hexToRGBA(theme.color.black, 0.07)};
  width: 100%;
  position: relative;
`;

export const FixedContainer = styled.div`
  position: fixed;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`;

export const GlobalStyle = createGlobalStyle`
  #root {
    overflow: hidden;
  }
`;
