import { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';

export const useLocationChangeCallback = (
  {
    pathname,
    hash,
  }: Pick<RouteComponentProps['location'], 'pathname' | 'hash'>,
  callback: () => void,
) => {
  const locationChangeRef = useRef<boolean>();
  useEffect(() => {
    if (locationChangeRef.current) {
      callback();
    }
    locationChangeRef.current = true;
  }, [pathname, hash, callback]);
};
