import { useMemo } from 'react';
import { useConsultantFilters } from './useConsultantFilters';
import { useConsultants } from './useConsultants';

export const useFilteredConsultants = () => {
  const allConsultants = useConsultants();
  const { manager, search } = useConsultantFilters();

  const consultants = useMemo(
    () =>
      allConsultants
        .filter(
          ({ salesConsultantManager }) =>
            !manager || salesConsultantManager === manager,
        )
        .filter(
          ({ fullName, postalCode }) =>
            fullName.toLowerCase().includes(search.toLowerCase()) ||
            postalCode.includes(search),
        ),
    [allConsultants, manager, search],
  );

  return { consultants };
};
