import styled from '../../styled-components';
import { media } from '@meindach/ui-kit';
import { OpportunityOwnerBar } from '../../components/opportunityOwnerBar/OpportunityOwnerBar';

export const Container = styled.div`
  height: 100%;
  > div {
    height: 100%;
  }

  ${media.md`
    height: auto;
    display: flex;
    > div {
      width: 50%;
      height: calc(100vh - 138px);
      flex: 1;
    }
  `}
`;

export const StickyBar = styled(OpportunityOwnerBar)`
  position: sticky;
  top: 52px;
  z-index: 1;
`;
