import styled, { createGlobalStyle, css } from '../../styled-components';
import { P } from '@meindach/ui-kit';

export const PanelStyle = createGlobalStyle`
  #panelContainer > * {
    display: none;
    &:last-child {
      display: block;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  overflow: auto;
  background: ${({ theme }) => theme.color.white};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.color.mercury};
  padding: 0 ${({ theme }) => theme.spacing.m};
  margin: 0 ${({ theme }) => theme.spacing.m};
  flex-grow: 0;
`;

export const TabPanel = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  box-sizing: border-box;
  height: 52px;
  display: flex;
`;

export const Tab = styled.div<{ active: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.color.mercury};
  border-right: 1px solid ${({ theme }) => theme.color.mercury};
  border-bottom: 1px solid
    ${({ theme, active }) => (active ? theme.color.white : theme.color.mercury)};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 52px;
  padding: ${({ theme }) => theme.spacing.m};

  > * {
    color: ${({ theme, active }) =>
      active ? theme.color.red : theme.color.slate};
  }
  ${({ active }) =>
    !active &&
    css`
      cursor: pointer;
    `}
  > ${P} {
    margin: 0;
    font-size: 16px;
    margin-left: ${({ theme }) => theme.spacing.s};
  }

  > svg {
    width: 30px;
    height: 30px;
  }
`;
