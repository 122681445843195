import React, { FC, memo } from 'react';
import { Marker } from 'react-map-gl';
import { StyledPin, StyledPinSelected, Text } from './styled';
import { Consultant } from '../../types/Consultant';
import { Coordinates } from '../../types/Coordinates';

type Props = Coordinates &
  Partial<Pick<Consultant, 'id'>> &
  Pick<Consultant, 'firstName' | 'lastName'> & {
    hovered: boolean;
    onClick?: (id: string) => void;
    onMouseEnter?: (id: string) => void;
  };

export const ConsultantMarker: FC<Props> = memo(
  ({ lat, lon, id, firstName, lastName, hovered, onClick, onMouseEnter }) => {
    const handleClick = onClick && !!id ? () => onClick(id) : undefined;
    const highlight = onMouseEnter && !!id ? () => onMouseEnter(id) : undefined;
    return (
      <Marker longitude={lon} latitude={lat}>
        {hovered ? (
          <StyledPinSelected onClick={handleClick} />
        ) : (
          <StyledPin
            onClick={handleClick}
            onMouseEnter={highlight}
            onTouchStart={highlight}
          />
        )}
        <Text selected={hovered}>
          {firstName.charAt(0)}
          {lastName.charAt(0)}
        </Text>
      </Marker>
    );
  },
);
