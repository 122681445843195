import React from 'react';
import { Container, CardContainer } from './styled';
import { usePlatform } from '@meindach/ui-kit';
import { config } from '../../config';
import { Map } from '../map/Map';
import { Store } from '../../types/Store';
import { useId } from '../../hooks/useId';
import { OpportunityCard } from '../opportunityCard/OpportunityCard';
import { useTranslation } from 'react-i18next';
import { OpportunityMarker } from '../opportunityMarker/OpportunityMarker';
import { TestAttribute } from '../../types/TestAttribute';
import { useFilteredOpportunities } from '../../hooks/useFilteredOpportunities';
import { usePositionAndZoom } from '../../hooks/usePositionAndZoom';

export const OpportunityMap = () => {
  const { t } = useTranslation();
  const { desktop } = usePlatform();
  const { id: hoverId, setId: setHoverId } = useId(Store.OpportunityHoverId);
  const { setId } = useId(Store.OpportunitySelectedId);
  const { opportunities = [] } = useFilteredOpportunities();
  const opportunity = opportunities.find(({ id }) => hoverId === id);
  const mapState = usePositionAndZoom(opportunities);

  return (
    <Container data-test-id={TestAttribute.Map}>
      {!desktop && opportunity && (
        <CardContainer onClick={() => setId(opportunity.id)}>
          <OpportunityCard opportunity={opportunity} t={t} />
        </CardContainer>
      )}
      <Map mapboxApiAccessToken={config.mapBoxApiToken} {...mapState}>
        {opportunities.map(({ id, latLon, salesStage, roofType }) => (
          <OpportunityMarker
            key={id}
            id={id}
            hovered={id === hoverId}
            {...latLon}
            setId={setId}
            setHoverId={setHoverId}
            desktop={desktop}
            salesStage={salesStage}
            roofType={roofType}
          />
        ))}
      </Map>
    </Container>
  );
};
