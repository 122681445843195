import React, { FC, memo } from 'react';
import { Container, PageOffset } from './styled';
import { NavItem, usePlatform } from '@meindach/ui-kit';
import { ReactComponent as Consultants } from '@meindach/ui-kit/dist/assets/icons/consultants.svg';
import { ReactComponent as Roofer } from '@meindach/ui-kit/dist/assets/icons/roofer.svg';
import { ReactComponent as Opportunities } from '@meindach/ui-kit/dist/assets/icons/house.svg';
import { ReactComponent as Help } from '@meindach/ui-kit/dist/assets/icons/help.svg';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Route } from '../../types/Route';
import { useAuth } from '../../hooks/useAuth';
import { UserType } from '../../types/UserType';
import { useUser } from '../../hooks/useUser';

const PageOffsetMemo = memo(() => <PageOffset />);

const Component: FC<RouteComponentProps> = memo(({ location }) => {
  const { mobile } = usePlatform();
  const { authenticated } = useAuth();
  const { user } = useUser();
  if (mobile) {
    return null;
  }
  const isManager =
    user && [UserType.Manager, UserType.Director].includes(user.type);
  const isActiveRoute = (route: Route) => location.pathname.startsWith(route);
  return (
    <Container>
      <PageOffsetMemo />
      {authenticated && !isManager && (
        <>
          <Link to={Route.Roofers}>
            <NavItem active={isActiveRoute(Route.Roofers)}>
              <Roofer />
            </NavItem>
          </Link>
          <Link to={Route.Opportunities}>
            <NavItem active={isActiveRoute(Route.Opportunities)}>
              <Opportunities />
            </NavItem>
          </Link>
        </>
      )}
      {authenticated && (
        <Link to={Route.Consultants}>
          <NavItem active={isActiveRoute(Route.Consultants)}>
            <Consultants />
          </NavItem>
        </Link>
      )}
      <Link to={Route.Help}>
        <NavItem active={isActiveRoute(Route.Help)}>
          <Help />
        </NavItem>
      </Link>
    </Container>
  );
});

export const Navigation = withRouter(Component);
