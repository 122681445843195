import { StorageId } from '../types/StorageId';
import { config } from '../config';
import { getHeaders } from './getHeaders';

export const getOpportunities = (consultantEmailOrRooferId?: string) => {
  const key = consultantEmailOrRooferId;
  const isEmail = !key || key.includes('@');
  const options = {
    headers: getHeaders(),
  };

  if (!isEmail) {
    return fetch(
      `${config.apiBaseUrl}/api/v1/roofers/${key}/opportunities`,
      options,
    );
  }

  const overrideEmail = key || localStorage.getItem(StorageId.EmailOverride);
  const params = overrideEmail
    ? `?emailOverride=${encodeURIComponent(overrideEmail)}`
    : '';
  return fetch(
    `${config.apiBaseUrl}/api/v1/sales-consultants/me/opportunities${params}`,
    options,
  );
};
