import React, { FC, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { Container, StyledCloseIcon } from './styled';
import { NumericInputProps, NumericInput } from '../input/NumericInput';

type Props = NumericInputProps & { className?: string };

export const NumericSearch: FC<Props> = ({
  value: controlledValue = '',
  onChange,
  className,
  ...props
}) => {
  const [value, setValue] = useState<string>(`${controlledValue}`);
  const [key, setKey] = useState<string>('');
  const handleChange = (numericValue: number) => {
    onChange(numericValue);
    setValue(isNaN(numericValue) ? '' : `${numericValue}`);
  };
  const onClear = () => {
    onChange(NaN);
    setValue('');
    setKey(`${Math.random()}`);
  };
  return (
    <Container key={key} className={className}>
      <NumericInput value={value} onChange={handleChange} {...props} />
      {value.length < 1 ? (
        <SearchIcon />
      ) : (
        <StyledCloseIcon onClick={onClear} />
      )}
    </Container>
  );
};
