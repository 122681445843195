import { Partner } from '../types/Partner';
import { RooferType } from '../types/RooferType';
import { createStore, useStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { useCallback } from 'react';

interface Filters {
  partners: Partner[];
  types: RooferType[];
  zipCode: string;
  search: string;
}

interface Setters {
  setPartners: (partners: Partner[]) => void;
  setTypes: (types: RooferType[]) => void;
  setZipCode: (zipCode: string) => void;
  setSearch: (search: string) => void;
  clear: () => void;
}

type Output = Filters & Setters & { active: boolean };

export const initialState: Filters = {
  partners: [],
  types: [],
  zipCode: '',
  search: '',
};
export const store = createStore<Filters>(Store.RooferFilters, initialState);

export const useRooferFilters = (): Output => {
  const [filters, setFilters] = useStore<Filters>(Store.RooferFilters);
  return {
    active: Object.values(filters).some(({ length }) => length > 0),
    ...filters,
    setPartners: useCallback(
      (partners) => setFilters({ ...filters, partners }),
      [filters, setFilters],
    ),
    setTypes: useCallback((types) => setFilters({ ...filters, types }), [
      filters,
      setFilters,
    ]),
    setZipCode: useCallback((zipCode) => setFilters({ ...filters, zipCode }), [
      filters,
      setFilters,
    ]),
    setSearch: useCallback((search) => setFilters({ ...filters, search }), [
      filters,
      setFilters,
    ]),
    clear: useCallback(() => setFilters(initialState), [setFilters]),
  };
};
