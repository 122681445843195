import { useEffect, useState } from 'react';
import { getQuote } from '../api/getQuote';
import { getLeadPdf } from '../api/getLeadPdf';
import { FileType } from '../types/FileType';

const fetchPayload = async (
  opportunityId: string,
  fileType: FileType,
  resolve: (file: any) => void,
  reject: () => void,
) => {
  try {
    const request = fileType === FileType.Quote ? getQuote : getLeadPdf;
    const response = await request(opportunityId);
    const data = await response.json();
    if (response.ok) {
      resolve(data.payload);
      return;
    }
  } catch {}
  reject();
};

export const useOpportunityFile = (
  opportunityId: string,
  fileType: FileType,
) => {
  const [file, setFile] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(true);

  useEffect(() => {
    fetchPayload(
      opportunityId,
      fileType,
      (file) => mounted && setFile(file),
      () => mounted && setError(true),
    );
    return () => {
      setMounted(false);
    };
  }, [fileType, mounted, opportunityId]);

  return { file, error };
};
