import React, { FC } from 'react';
import { GlobalStyle } from '@meindach/ui-kit';
import { Header } from '../components/header/Header';
import { Navigation } from '../components/navigation/Navigation';
import { PageContainer } from './styled';
import { ModalsAndPanels } from './ModalsAndPanels';
import { PageViewTracker } from '../components/pageViewTracker/PageViewTracker';

export const Layout: FC = ({ children }) => (
  <>
    <GlobalStyle />
    <ModalsAndPanels />
    <PageViewTracker />
    <PageContainer>{children}</PageContainer>
    <Navigation />
    <Header />
  </>
);
