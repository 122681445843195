import { useRoofers } from './useRoofers';
import { useMemo } from 'react';

export const useRoofer = (rooferId: string | null, consultant?: string) => {
  const { roofers } = useRoofers(consultant);
  return useMemo(() => {
    if (!rooferId || !roofers) {
      return null;
    }
    return roofers.find(({ id }) => id === rooferId) || null;
  }, [roofers, rooferId]);
};
