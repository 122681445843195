import React, { FC } from 'react';
import { NumericSearch } from '@meindach/ui-kit';
import { useRooferFilters } from '../../hooks/useRooferFilters';
import { trackEvent } from '../../util/trackEvent';

interface Props {
  name?: string;
  placeHolder?: string;
}

export const ZipCodeFilter: FC<Props> = ({ name, placeHolder }) => {
  const { zipCode, setZipCode } = useRooferFilters();
  return (
    <NumericSearch
      name={name}
      placeholder={placeHolder}
      value={zipCode}
      onChange={(value: number) => setZipCode(isNaN(value) ? '' : `${value}`)}
      onBlur={() => trackEvent('filterRoofers', 'zipCode', zipCode)}
    />
  );
};
