import styled, { css } from '../../styled-components';

interface Props {
  small?: boolean;
  big?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  border-radius: 100%;

  color: ${({ theme }) => theme.color.storm};
  font-weight: 300;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  text-align: center;
  letter-spacing: 1px;

  position: relative;
  overflow: hidden;

  height: 60px;
  width: 60px;
  font-size: 26px;

  ${({ small }) =>
    small &&
    css`
      height: 40px;
      width: 40px;
      font-size: 18px;
    `}

  ${({ big }) =>
    big &&
    css`
      height: 70px;
      width: 70px;
      font-size: 32px;
    `}
`;

export const Border = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid ${({ theme }) => theme.color.storm};
  border-radius: 100%;
  box-sizing: border-box;
`;

interface ImageProps {
  src?: string;
}

/**
 * Using div instead of img because to avoid corrupted image icon if the image load fails
 */
export const Image = styled.div<ImageProps>`
  background: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;
