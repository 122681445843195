import React, { useState } from 'react';
import { MobileContainer, Clear } from './styled';
import { Label } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { RoofTypeFilter } from './RoofTypeFilter';
import { ZipCodeFilter } from './ZipCodeFilter';
import { useOpportunityFilters } from '../../hooks/useOpportunityFilters';
import { SalesStageFilter } from './SalesStageFilter';
import { StartDateFilter } from './StartDateFilter';

export const MobileOpportunityFilters = () => {
  const { t } = useTranslation('filters');
  const [key, setKey] = useState<string>('');
  const { clear } = useOpportunityFilters();
  const onClear = () => {
    clear();
    setKey(`${Math.random()}`);
  };
  return (
    <MobileContainer key={key}>
      <Label htmlFor="rooTypes">{t('roofTypes')}</Label>
      <RoofTypeFilter name="rooTypes" placeHolder={t('allRoofTypes')} />
      <Label htmlFor="salesStages">{t('salesStages')}</Label>
      <SalesStageFilter name="salesStages" placeHolder={t('allSalesStages')} />
      <Label htmlFor="startDates">{t('startDates')}</Label>
      <StartDateFilter name="startDates" placeHolder={t('allStartDates')} />
      <Label htmlFor="zipCode">{t('zipCode')}</Label>
      <ZipCodeFilter name="zipCode" placeHolder={t('allZipCodes')} />
      <Clear onClick={onClear}>{t('clear')}</Clear>
    </MobileContainer>
  );
};
