import React, { FC, memo } from 'react';
import { LeadRating as LeadRatingType } from '../../types/LeadRating';
import { ReactComponent as A } from '../../assets/ratings/leads/a.svg';
import { ReactComponent as B } from '../../assets/ratings/leads/b.svg';
import { ReactComponent as C } from '../../assets/ratings/leads/c.svg';
import { ReactComponent as R } from '../../assets/ratings/leads/r.svg';

interface Props {
  rating: LeadRatingType;
}

export const LeadRating: FC<Props> = memo(({ rating }) => {
  switch (rating) {
    case 'A':
      return <A />;
    case 'B':
      return <B />;
    case 'C':
      return <C />;
    case 'R':
      return <R />;
  }
  return null;
});
