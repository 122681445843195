import styled, { createGlobalStyle } from '../../styled-components';
import { PageContainer } from '../../layout/styled';

export const Container = styled.nav`
  position: fixed;
  left: 0;
  height: 100%;
  z-index: 1;

  box-sizing: border-box;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;

  top: 0;
  width: 64px;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.color.mercury};

  > a {
    :last-child {
      margin-top: auto;
    }
  }
`;

export const PageOffset = createGlobalStyle`
  ${PageContainer} {
    padding-left: 64px;
  }
`;
