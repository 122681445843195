import Rollbar from 'rollbar';

const accessToken = process.env.REACT_APP_ROLLBAR_TOKEN;

new Rollbar({
  accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ROLLBAR_ENV,
  },
  enabled: !!accessToken,
});
