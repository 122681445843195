import { Route } from '../types/Route';

interface Event {
  event: 'event';
  eventAction:
    | 'login'
    | 'logout'
    | 'sort'
    | 'change'
    | 'partners'
    | 'manager'
    | 'types'
    | 'roofTypes'
    | 'salesStages'
    | 'startDates'
    | 'zipCode'
    | 'search'
    | 'openInfo'
    | 'openMap'
    | 'call'
    | 'email'
    | 'quotePdf'
    | 'leadPdf'
    | Route;
  eventCategory:
    | 'roofer'
    | 'consultant'
    | 'manager'
    | 'opportunity'
    | 'pageView'
    | 'user'
    | 'error'
    | 'filterRoofers'
    | 'filterConsultants'
    | 'filterOpportunities';
  eventLabel: string | null;
}

type WindowWithDataLayer = Window & {
  dataLayer?: Event[] | null;
};

export const trackEvent = (
  eventCategory: Event['eventCategory'],
  eventAction: Event['eventAction'],
  eventLabel: Event['eventLabel'] = '',
) =>
  (window as WindowWithDataLayer).dataLayer &&
  (window as WindowWithDataLayer).dataLayer!.push({
    event: 'event',
    eventAction,
    eventCategory,
    eventLabel,
  });
