import React, { FC, ButtonHTMLAttributes, memo } from 'react';
import { Button as StyledButton, ButtonProps as Props, Text } from './styled';

export type ButtonProps = Props & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: FC<ButtonProps> = memo(({ children, type, ...rest }) => (
  <StyledButton type={type || 'button'} {...rest}>
    <Text>{children}</Text>
  </StyledButton>
));
