import React from 'react';
import { Title, P } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { Container, IllustrationContainer, Content } from './styled';
import { ReactComponent as Illustration } from '../../assets/illustration-start.svg';
import { LoginButton } from '../../components/loginButton/LoginButton';

export const Logout = () => {
  const { t } = useTranslation('logout');
  return (
    <Container>
      <Content>
        <Title xl>{t('title')}</Title>
        <P>{t('info')}</P>
        <LoginButton />
        <IllustrationContainer>
          <Illustration />
        </IllustrationContainer>
      </Content>
    </Container>
  );
};
