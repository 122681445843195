import React, { FC, MouseEvent } from 'react';
import { LinkProps } from 'react-router-dom';
import { StyledLink } from './styled';
import { P } from '@meindach/ui-kit';
import { ReactComponent as Arrow } from '@meindach/ui-kit/dist/assets/icons/arrow-right.svg';
import { UseTranslationResponse } from 'react-i18next';

type Translation = UseTranslationResponse['t'];

export interface LinkActionProps {
  icon: FC;
  text: string | number | ReturnType<Translation>;
  link: LinkProps['to'];
  onClick?: (e: MouseEvent) => void;
}

export const LinkAction: FC<LinkActionProps> = ({
  text,
  link,
  icon: Icon,
  onClick,
}) => (
  <StyledLink to={link} onClick={onClick}>
    <Icon />
    <P>{text}</P>
    <Arrow />
  </StyledLink>
);
