import React, { FC, memo } from 'react';
import { Content, Details, Name, StyledCard } from './styled';
import { ReactComponent as LocationIcon } from '@meindach/ui-kit/dist/assets/icons/location.svg';
import { ReactComponent as RoofSizeIcon } from '@meindach/ui-kit/dist/assets/icons/roof-size.svg';
import { ReactComponent as DateIcon } from '@meindach/ui-kit/dist/assets/icons/calendar.svg';
import { P, timestampToDate, scrollParentToChildPos } from '@meindach/ui-kit';
import { OpportunityStatus } from './OpportunityStatus';
import { Opportunity } from '../../types/Opportunity';

export interface OpportunityCardProps {
  selected?: boolean;
  highlighted?: boolean;
  opportunity: Pick<
    Opportunity,
    | 'id'
    | 'fullName'
    | 'roofSize'
    | 'roofType'
    | 'postalCode'
    | 'city'
    | 'dateEntered'
    | 'salesStage'
    | 'clonedOpportunity'
  >;
  setId?: (id: string | null) => void;
  setHoverId?: (id: string | null) => void;
  onMouseLeave?: () => any;
  t: (key: string) => string;
  mobile?: boolean;
}

export const scrollToCard = (id: string, instant: boolean = false) =>
  scrollParentToChildPos(
    document.querySelector(`[data-id=card${id}]`),
    instant,
  );

export const OpportunityCard: FC<OpportunityCardProps> = memo(
  ({ opportunity, setId, setHoverId, mobile, t, ...rest }) => {
    const {
      id,
      fullName,
      roofSize,
      roofType,
      postalCode,
      city,
      dateEntered,
      salesStage,
      clonedOpportunity,
    } = opportunity;
    const onMouseEnter = mobile
      ? undefined
      : () => setHoverId && setHoverId(id);
    const onClick = () => {
      if (setId) {
        setId(id);
      }
      if (mobile && setHoverId) {
        setHoverId(id);
      }
    };
    return (
      <StyledCard
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        {...rest}
        data-id={`card${id}`}
      >
        <OpportunityStatus roofType={roofType} salesStage={salesStage} t={t} />
        <Content>
          <Name>
            {fullName} {clonedOpportunity && t('cloneTags:clone')}
          </Name>
          <P>
            <LocationIcon />
            <span>
              {postalCode} {city}
            </span>
          </P>
          <Details>
            <DateIcon />
            <P>{dateEntered ? timestampToDate(dateEntered) : '-'}</P>
            <RoofSizeIcon />
            <P>{isNaN(parseFloat(roofSize)) ? '-' : roofSize} m²</P>
          </Details>
        </Content>
      </StyledCard>
    );
  },
);
