import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { useAuth } from '../../hooks/useAuth';
import { Route } from '../../types/Route';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { useUser } from '../../hooks/useUser';
import { UserType } from '../../types/UserType';

export const Callback = () => {
  const { authenticated, error, authenticate } = useAuth();
  const { user, fetching } = useUser();
  useEffect(() => {
    if (!authenticated) {
      authenticate();
    }
  }, [authenticate, authenticated]);

  if (error) {
    return <Redirect to={Route.Login} />;
  }
  if (!authenticated || fetching) {
    return <PageLoading />;
  }
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');

  if (id) {
    return (
      <Redirect
        to={{ pathname: Route.Opportunities, search: window.location.search }}
      />
    );
  }
  const isManager =
    user && [UserType.Manager, UserType.Director].includes(user.type);

  return <Redirect to={isManager ? Route.Consultants : Route.Roofers} />;
};
