import React, { memo, useCallback } from 'react';
import { useSort } from '../../hooks/useSort';
import { Store } from '../../types/Store';
import { Sort } from '../../types/Sort';
import { trackEvent } from '../../util/trackEvent';
import { SortContainer } from '../rooferList/styled';
import { ConsultantSearch } from '../consultantFilters/ConsultantSearch';
import { ListSort } from '../listSort/ListSort';

export const ConsultantSort = memo(() => {
  const { sort, setSort } = useSort(Store.ConsultantSort);
  const sortChange = useCallback(
    (sort: Sort) => {
      trackEvent('consultant', 'sort', sort);
      setSort(sort);
    },
    [setSort],
  );
  return (
    <SortContainer>
      <ConsultantSearch />
      <ListSort sort={sort} setSort={sortChange} />
    </SortContainer>
  );
});
