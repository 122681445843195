import { Opportunity } from '../types/Opportunity';
import { OpportunityStatus } from '../types/OpportunityStatus';

export const getOpportunityStatus = (
  salesStage: Opportunity['salesStage'],
): OpportunityStatus =>
  ['roofer_picking', 'Resubmission'].includes(salesStage)
    ? 'open'
    : ['completion', 'Cleared'].includes(salesStage)
    ? 'done'
    : 'active';
