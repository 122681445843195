import React, { FC } from 'react';
import { Details, Entry, Counties } from './styled';
import { P, Tag } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { Consultant } from '../../types/Consultant';
import { UserType } from '../../types/UserType';

type Props = Pick<Consultant, 'countyNames' | 'salesConsultantManager'> & {
  directorView: boolean;
  close: () => any;
};

export const ConsultantInfoModalDetails: FC<Props> = ({
  countyNames,
  salesConsultantManager,
  directorView,
}) => {
  const { t } = useTranslation('consultantInfo');
  return (
    <Details>
      {directorView && (
        <Entry>
          <P>{t(`userType:${UserType.Manager}`)}</P>
          <P>{salesConsultantManager}</P>
        </Entry>
      )}
      <P>{t('places')}</P>
      <Counties>
        {countyNames.map((name) => (
          <Tag key={name} type="info">
            {name}
          </Tag>
        ))}
      </Counties>
    </Details>
  );
};
