import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Route } from '../../types/Route';

const Component: FC<RouteComponentProps> = memo(({ location }) => {
  const { t } = useTranslation('pageTitle');
  const isActive = (route: Route) => location.pathname.startsWith(route);

  if (isActive(Route.Consultants)) {
    return t('consultants');
  }
  if (isActive(Route.Roofers)) {
    return t('roofers');
  }
  if (isActive(Route.Opportunities)) {
    return t('opportunities');
  }
  if (isActive(Route.Account)) {
    return t('account');
  }
  if (isActive(Route.Help)) {
    return t('help');
  }
  return t('default');
});

export const PageTitle = withRouter(Component);
