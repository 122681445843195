import styled, { css } from '../../styled-components';
import { Select, Label, media, Search } from '@meindach/ui-kit';

export const StyledSelect = styled(Select)`
  ${media.md`
    margin-right: ${({ theme }) => theme.spacing.m};
    width: 35%;
    max-width: 280px;
  `}
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  padding: ${({ theme }) => theme.spacing.s};

  > ${Label} {
    font-size: 18px;
    ${({ theme }) => css`
      margin: ${theme.spacing.m} 0 ${theme.spacing.s};
    `}
  }
`;

export const StyledSearch = styled(Search)`
  svg {
    pointer-events: all;
    cursor: pointer;
  }
`;
