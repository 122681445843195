import styled from '../../styled-components';
import { P, Modal } from '@meindach/ui-kit';

export const StyledModal = styled(Modal)`
  max-width: 410px;
  margin: 0 auto;
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.18px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing.s};

  ${Name} {
    margin: 0;
    font-size: 18px;
  }
  ${P} {
    margin: 0;
    font-size: 14px;
  }
`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spacing.m};
  box-shadow: 0 4px 4px 0 ${({ theme }) => theme.color.shadow};

  display: flex;
  align-items: center;

  > div {
    margin-top: auto;
    margin-bottom: auto;
    &:last-child {
      display: flex;
      align-items: center;
      margin-left: auto;
      padding-left: ${({ theme }) => theme.spacing.s};
      color: ${({ theme }) => theme.color.silver};
      cursor: pointer;

      > svg {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

export const Entry = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  display: flex;

  > ${P} {
    flex: 65;
    margin: ${({ theme }) => theme.spacing.s} 0;
    display: flex;
    align-items: center;

    &:first-child {
      flex: 35;
    }

    > svg {
      margin-right: ${({ theme }) => theme.spacing.s};
      width: 25px;
      height: 25px;
    }
  }
  &:first-of-type {
    > ${P} {
      flex: 50;
      &:first-child {
        flex: 50;
      }
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
`;

export const Details = styled.div`
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m};

  button {
    margin-left: auto;
  }
`;
