import React from 'react';
import { MobileContainer } from './styled';
import { Label } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { ManagerFilter } from './ManagerFilter';

export const MobileConsultantFilters = () => {
  const { t } = useTranslation('filters');
  return (
    <MobileContainer>
      <Label htmlFor="manager">{t('manager')}</Label>
      <ManagerFilter name="manager" placeHolder={t('manager')} />
    </MobileContainer>
  );
};
