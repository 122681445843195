import { useEffect } from 'react';
import { getConsultants } from '../api/getConsultants';
import { useStore, createStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { useAuth } from './useAuth';
import { Consultant } from '../types/Consultant';

interface State {
  consultants?: Consultant[];
  error?: boolean;
  fetching?: boolean;
}

export const store = createStore<State>(Store.Consultants, {});

const fetchUser = async (setState: (state: State) => void) => {
  setState({ fetching: true });
  try {
    const consultantList = await getPagedConsultants();
    setState({ consultants: consultantList });
  } catch {
    setState({ error: true });
  }
};

const getPagedConsultants = async (
  fetchLastId?: string,
): Promise<Consultant[]> => {
  const response = await getConsultants(fetchLastId);
  if (response.ok) {
    const consultantPage = await response.json();
    if (consultantPage && consultantPage.length > 0) {
      return [
        ...consultantPage,
        ...(await getPagedConsultants(
          consultantPage[consultantPage.length - 1].id,
        )),
      ];
    }
    return []; //exit condition no more pages
  }
  //non ok response - throw error
  throw new Error(`Error fetching consultants`);
};

export const useAllConsultants = () => {
  const { authenticated } = useAuth();
  const [state, setState] = useStore<State>(Store.Consultants);
  const { consultants = null, error = false, fetching = false } = state;

  useEffect(() => {
    if (authenticated) {
      // Get fetching through store to get latest value while hook will only be updated after
      if (!consultants && !store.getState().fetching) {
        fetchUser(setState);
      }
    } else if (consultants) {
      setState({});
    }
  }, [authenticated, consultants, setState]);

  return { consultants: consultants || [], fetching, error };
};
