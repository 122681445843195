import React, { FC } from 'react';
import { Details, Entry } from './styled';
import { P, capitalizeFirstLetter, Button } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ConsultantsIcon } from '@meindach/ui-kit/dist/assets/icons/consultants.svg';
import { Manager } from '../../types/Manager';
import { useConsultantFilters } from '../../hooks/useConsultantFilters';

type Props = Pick<Manager, 'brand' | 'fullName' | 'managedSalesConsultants'> & {
  close: () => void;
};

export const ManagerInfoModalDetails: FC<Props> = ({
  brand,
  fullName,
  managedSalesConsultants,
  close,
}) => {
  const { t } = useTranslation('managerInfo');
  const { setManager } = useConsultantFilters();
  const onClick = () => {
    setManager(fullName);
    close();
  };
  return (
    <Details>
      <Entry>
        <P>
          <ConsultantsIcon />
          {managedSalesConsultants.length} {t('consultants')}
        </P>
        <P>
          <Button onClick={onClick} extraSmall empty>
            {t('common:open')}
          </Button>
        </P>
      </Entry>
      <Entry>
        <P>{t('common:brand')}</P>
        <P>{capitalizeFirstLetter(brand)}</P>
      </Entry>
    </Details>
  );
};
