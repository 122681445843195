import styled from '../../styled-components';

export const Actions = styled.div`
  box-shadow: 0 -4px 4px 0 ${({ theme }) => theme.color.shadow};
  display: flex;
  justify-content: space-around;
  align-items: center;

  > a {
    display: flex;
    padding: ${({ theme }) => theme.spacing.m};
    > svg {
      color: ${({ theme }) => theme.color.blue};
      width: 34px;
      height: 30px;
    }
  }

  > button {
    display: flex;
    padding: ${({ theme }) => theme.spacing.m};
    > svg {
      color: ${({ theme }) => theme.color.blue};
      width: 34px;
      height: 30px;
    }
  }
`;
