import { Route } from './types/Route';

interface Auth0 {
  domain: string;
  clientId: string;
  callbackUrl: string;
  logoutUrl: string;
}

interface Config {
  apiBaseUrl: string;
  apiPublicBaseUrl: string;
  mapBoxApiToken: string;
  googleMapsApiToken: string;
  auth0: Auth0;
}

const {
  NODE_ENV,
  REACT_APP_CLASSIFICATION_URL,
  REACT_APP_BACKEND_PUBLIC_URL,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_MAPBOX_API_TOKEN,
  REACT_APP_GOOGLE_MAPS_API_TOKEN,
  REACT_APP_ROLLBAR_ENV,
  REACT_APP_ROLLBAR_TOKEN,
} = process.env;

if (NODE_ENV === 'production') {
  Object.entries({
    REACT_APP_CLASSIFICATION_URL,
    REACT_APP_BACKEND_PUBLIC_URL,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_MAPBOX_API_TOKEN,
    REACT_APP_GOOGLE_MAPS_API_TOKEN,
    REACT_APP_ROLLBAR_ENV,
    REACT_APP_ROLLBAR_TOKEN,
  }).forEach(([key, value]) => {
    if (!value) {
      throw new Error(`Please set ${key}`);
    }
  });
}

export const config: Config = {
  apiBaseUrl:
    REACT_APP_CLASSIFICATION_URL ||
    'https://develop.md-classification.api.meindach.de',
  apiPublicBaseUrl:
    REACT_APP_BACKEND_PUBLIC_URL ||
    'https://develop.backend-public.api.meindach.de',
  mapBoxApiToken:
    REACT_APP_MAPBOX_API_TOKEN ||
    'pk.eyJ1IjoibWVpbmRhY2giLCJhIjoiY2p0cHNwb3JiMDdkZzRkcjBiODBjcXQ0cCJ9.m-xryZsNGOX6Db9apGO6vQ',
  googleMapsApiToken:
    REACT_APP_GOOGLE_MAPS_API_TOKEN ||
    'AIzaSyAQaHPyaXUkWa9YOFeaxmd1iLs_iEdfDK8',
  auth0: {
    domain: REACT_APP_AUTH0_DOMAIN || 'meindach.eu.auth0.com',
    clientId: REACT_APP_AUTH0_CLIENT_ID || 'XtcNkKAOiXalBlhMze1lb7IR6NvYgAO3',
    callbackUrl: `${window.location.origin}${Route.Callback}`,
    logoutUrl: `${window.location.origin}${Route.Logout}`,
  },
};
