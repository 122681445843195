import { createStore, useStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { useCallback } from 'react';

interface Filters {
  manager: string | null;
  search: string;
}

interface Setters {
  setManager: (manager: string | null) => void;
  setSearch: (zipCode: string) => void;
}

type Output = Filters & Setters & { active: boolean };

const initialState = { search: '', manager: null };

export const store = createStore<Filters>(
  Store.ConsultantFilters,
  initialState,
);

export const useConsultantFilters = (): Output => {
  const [filters, setFilters] = useStore<Filters>(Store.ConsultantFilters);
  return {
    active: Object.values(filters).some((filter) => !!filter),
    ...filters,
    setManager: useCallback(
      (manager: string | null) => setFilters({ ...filters, manager }),
      [filters, setFilters],
    ),
    setSearch: useCallback(
      (search: string) => setFilters({ ...filters, search }),
      [filters, setFilters],
    ),
  };
};
