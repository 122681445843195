import { SalesStage } from '../types/SalesStage';

export const isQuoteAvailable = (salesStage: SalesStage) =>
  ![
    'roofer_picking',
    'inspection',
    'proposal_creation',
    'Resubmission',
    'give_away',
    'data_erased',
    'Closed Lost',
    'project_delayed',
  ].includes(salesStage);
