import styled, { css } from '../../styled-components';

export interface NavItemProps {
  active?: boolean;
}

export const NavItem = styled.div<NavItemProps>`
  width: 100%;
  min-width: 52px;
  height: 52px;
  background-color: ${({ theme }) => theme.color.white};

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  cursor: pointer;

  > svg {
    width: 29px;
    height: 29px;
    transition: 0.3s ease-in-out;
  }

  border-width: 3px;
  border-right: solid transparent;

  ${({ active, theme }) =>
    css`
      color: ${active ? theme.color.red : theme.color.storm};
      border-left: solid ${active ? theme.color.red : 'transparent'};

      ${!active &&
      css`
        > svg {
          transform: scale(0.93);
        }

        &:hover {
          > svg {
            color: ${theme.color.red};
            transform: scale(1);
          }
        }
      `}
    `}
`;
