import styled, { css } from '../../styled-components';
import { ReactComponent as FlatPinIcon } from '@meindach/ui-kit/dist/assets/map/opportunity-flat.svg';
import { ReactComponent as FlatPinIconSelected } from '@meindach/ui-kit/dist/assets/map/opportunity-flat-selected.svg';
import { ReactComponent as PitchedPinIcon } from '@meindach/ui-kit/dist/assets/map/opportunity-pitched.svg';
import { ReactComponent as PitchedPinIconSelected } from '@meindach/ui-kit/dist/assets/map/opportunity-pitched-selected.svg';
import { OpportunityStatus } from '../../types/OpportunityStatus';

type Props = { status: OpportunityStatus };

const color = css<Props>`
  color: ${({ status, theme }) => {
    switch (status) {
      case 'open':
        return theme.color.cyan;
      case 'done':
        return theme.color.success;
    }
    return '#ffae11';
  }};
`;

const pinStyle = css`
  cursor: pointer;
  width: auto;
  height: 35px;
  ${color};
`;

export const FlatPin = styled(FlatPinIcon)<Props>`
  ${pinStyle};
`;

export const PitchedPin = styled(PitchedPinIcon)<Props>`
  ${pinStyle};
`;

const selectedPinStyle = css`
  cursor: pointer;
  width: auto;
  height: 60px;
  ${color};
`;

export const FlatPinSelected = styled(FlatPinIconSelected)<Props>`
  ${selectedPinStyle};
`;

export const PitchedPinSelected = styled(PitchedPinIconSelected)<Props>`
  ${selectedPinStyle};
`;
