import React from 'react';
import { Button, usePlatform } from '@meindach/ui-kit';
import { TestAttribute } from '../../types/TestAttribute';
import { useAuth } from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../util/trackEvent';

export const LoginButton = () => {
  const { t } = useTranslation('login');
  const { mobile } = usePlatform();
  const { login } = useAuth();
  const onClick = () => {
    trackEvent('user', 'login');
    login();
  };
  return (
    <Button
      data-test-id={TestAttribute.LoginButton}
      onClick={onClick}
      fullWidth={mobile}
      success
    >
      {t('login')}
    </Button>
  );
};
