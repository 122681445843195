import React, { FC, useEffect } from 'react';
import { ModalProps, Avatar } from '@meindach/ui-kit';
import { P } from '@meindach/ui-kit';
import { Content, Name, Header, StyledModal } from './styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '@meindach/ui-kit/dist/assets/ui/close.svg';
import { TestAttribute } from '../../types/TestAttribute';
import { trackEvent } from '../../util/trackEvent';
import { ManagerActions } from './ManagerActions';
import { ManagerInfoModalDetails } from './ManagerInfoModalDetails';
import { Manager } from '../../types/Manager';

interface Props extends Omit<ModalProps, 'onClose'> {
  manager: Manager;
  onClose: () => void;
}

export const ManagerInfoModal: FC<Props> = ({ manager, onClose }) => {
  const { t } = useTranslation('managerInfo');
  const { id, firstName, lastName } = manager;
  useEffect(() => {
    trackEvent('manager', 'openInfo', id);
  }, [id]);
  return (
    <StyledModal onClose={onClose}>
      <Header>
        <div>
          <Avatar firstName={firstName} lastName={lastName} small />
        </div>
        <Content>
          <P>{t('title')}</P>
          <Name>
            {firstName} {lastName}
          </Name>
        </Content>
        <div>
          <CloseIcon
            onClick={onClose}
            data-test-id={TestAttribute.ModalClose}
          />
        </div>
      </Header>
      <ManagerInfoModalDetails {...manager} close={onClose} />
      <ManagerActions {...manager} />
    </StyledModal>
  );
};
