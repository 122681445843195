import { useStore, createStore } from 'react-hookstore';
import { Store } from '../types/Store';

type Value = string | null;

interface Output {
  setId: (id: Value) => void;
  id: Value;
}

export const stores = {
  [Store.ConsultantHoverId]: createStore<Value>(Store.ConsultantHoverId, null),
  [Store.ConsultantSelectedId]: createStore<Value>(
    Store.ConsultantSelectedId,
    null,
  ),
  [Store.ManagerSelectedId]: createStore<Value>(Store.ManagerSelectedId, null),
  [Store.RooferHoverId]: createStore<Value>(Store.RooferHoverId, null),
  [Store.RooferSelectedId]: createStore<Value>(Store.RooferSelectedId, null),
  [Store.OpportunityHoverId]: createStore<Value>(
    Store.OpportunityHoverId,
    null,
  ),
  [Store.OpportunitySelectedId]: createStore<Value>(
    Store.OpportunitySelectedId,
    null,
  ),
};

type StoreId = keyof typeof stores;

export const useId = (storeId: StoreId): Output => {
  const [id, setId] = useStore<Value>(storeId);
  return { id, setId };
};
