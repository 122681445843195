import { config } from './../config';

interface Context {
  id: string;
  text: string;
}

interface Feature {
  context: Context[];
}

interface GeocodingApiResponse {
  features: Feature[];
}

export const getZipCode = async (latitude: number, longitude: number) => {
  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?type=postcode&limit=1&access_token=${config.mapBoxApiToken}`,
    );
    const data: GeocodingApiResponse = await response.json();
    if (data.features.length < 1) {
      return null;
    }
    const postCodeEntry = data.features[0].context.find(({ id }) =>
      id.startsWith('postcode'),
    );
    if (postCodeEntry) {
      return postCodeEntry.text;
    }
  } catch {}
  return null;
};
