import './Polyfills';
// Rollbar should be the first thing that is imported after the polyfills
import './Rollbar';
import './i18n/i18n';
import React from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from './styled-components';
import { theme } from '@meindach/ui-kit';
import { Routes } from './Routes';
import { Layout } from './layout/Layout';
import { BrowserRouter } from 'react-router-dom';

render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Layout>
        <Routes />
      </Layout>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root'),
);
