import React from 'react';
import { ApiErrorModal } from '../modals/apiErrorModal/ApiErrorModal';
import { SelectedConsultantInfo } from '../modals/selectedConsultantInfo/SelectedConsultantInfo';
import { SelectedRooferInfo } from '../modals/selectedRooferInfo/SelectedRooferInfo';
import { SelectedOpportunityInfo } from '../modals/selectedOpportunityInfo/SelectedOpportunityInfo';
import { SelectedManagerModal } from '../modals/selectedManagerModal/SelectedManagerModal';
import { SelectedFileModal } from '../modals/selectedFileModal/SelectedFileModal';

export const ModalsAndPanels = () => (
  <>
    <SelectedRooferInfo />
    <SelectedConsultantInfo />
    <SelectedManagerModal />
    <SelectedOpportunityInfo />
    <SelectedFileModal />
    <ApiErrorModal />
  </>
);
