import React, { FC } from 'react';
import { useConsultant } from '../../hooks/useConsultant';
import { useTranslation } from 'react-i18next';
import { useId } from '../../hooks/useId';
import { Store } from '../../types/Store';
import { Route } from '../../types/Route';
import { InfoBar } from '../infoBar/InfoBar';
import { config } from '../../config';

interface Props {
  className?: string;
}

export const ActiveConsultantBar: FC<Props> = ({ className }) => {
  const { t } = useTranslation('consultantInfo');
  const { setId } = useId(Store.ConsultantSelectedId);
  const consultant = useConsultant(window.location.hash.replace('#', ''));
  if (!consultant) {
    return null;
  }
  const { firstName, lastName, id, imageHash } = consultant;
  return (
    <InfoBar
      title={t('title')}
      firstName={firstName}
      lastName={lastName}
      imageUrl={
        imageHash &&
        `${config.apiBaseUrl}/api/v1/public/images/sales-consultants/${id}/${imageHash}/size?assetType=THUMBNAIL`
      }
      className={className}
      onClick={() => setId(id)}
      closeRoute={Route.Consultants}
    />
  );
};
