import React, { FC, memo } from 'react';
import { StyledSelect } from './styled';
import { trackEvent } from '../../util/trackEvent';
import { useUser } from '../../hooks/useUser';
import { useConsultantFilters } from '../../hooks/useConsultantFilters';

interface Props {
  name?: string;
  placeHolder?: string;
}

export const ManagerFilter: FC<Props> = memo(({ name, placeHolder }) => {
  const { manager, setManager } = useConsultantFilters();
  const { user } = useUser();
  if (!user) {
    return null;
  }
  const options = [
    '-',
    ...user.managedSalesManagers.map(({ fullName }) => fullName),
  ].map((value) => ({ value }));

  const onChange = (manager: string) =>
    setManager(manager === '-' ? null : manager);
  return (
    <StyledSelect
      key={manager || '-'}
      name={name}
      options={options}
      placeHolder={placeHolder}
      onChange={onChange}
      selectedValues={manager ? [manager] : []}
      onBlur={() =>
        trackEvent('filterConsultants', 'manager', JSON.stringify(manager))
      }
    />
  );
});
