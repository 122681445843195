import { Sort } from '../types/Sort';
import { useStore, createStore } from 'react-hookstore';
import { Store } from '../types/Store';

interface Output {
  sort: Sort;
  setSort: (sort: Sort) => void;
}

export const stores = {
  [Store.RooferSort]: createStore<Sort>(
    Store.RooferSort,
    Sort.AlphabeticalAscending,
  ),
  [Store.ConsultantSort]: createStore<Sort>(
    Store.ConsultantSort,
    Sort.AlphabeticalAscending,
  ),
  [Store.OpportunitySort]: createStore<Sort>(
    Store.OpportunitySort,
    Sort.Newest,
  ),
};

type StoreId = keyof typeof stores;

export const useSort = (storeId: StoreId): Output => {
  const [sort, setSort] = useStore<Sort>(storeId);
  return { sort, setSort };
};
