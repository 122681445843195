import React, { useCallback, useEffect } from 'react';
import { Container } from './styled';
import { NoConsultants } from './NoConsultants';
import { usePlatform } from '@meindach/ui-kit';
import { useSort } from '../../hooks/useSort';
import { sortByFullName } from '../../util/sortByFullName';
import { LoadingList } from './LoadingList';
import { ConsultantCard } from '../consultantCard/ConsultantCard';
import { useId } from '../../hooks/useId';
import { Store } from '../../types/Store';
import { useTranslation } from 'react-i18next';
import { ConsultantSort } from './ConsultantSort';
import { useFilteredAllConsultants } from '../../hooks/useFilteredAllConsultants';

export const AllConsultantList = () => {
  const { t } = useTranslation();
  const { sort } = useSort(Store.ConsultantSort);
  const { mobile } = usePlatform();
  const { id, setId } = useId(Store.ConsultantSelectedId);
  const { id: hoverId, setId: setHoverId } = useId(Store.ConsultantHoverId);
  const { consultants, fetching } = useFilteredAllConsultants();

  const callback = useCallback((id: string) => setHoverId(id), [setHoverId]);
  const onMouseEnter = mobile ? undefined : callback;
  const onClick = useCallback(
    (id: string) => {
      setId(id);
      if (mobile) {
        setHoverId(id);
      }
    },
    [setId, setHoverId, mobile],
  );

  const sortedConsultants = sortByFullName(sort, consultants);

  useEffect(() => {
    if (!mobile && !id && consultants.length > 0) {
      setId(sortedConsultants[0].id);
    }
  }, [id, mobile, consultants, setId, sortedConsultants]);

  return (
    <Container>
      <ConsultantSort />
      {fetching && <LoadingList />}
      {!fetching && consultants.length < 1 && <NoConsultants />}
      {!fetching &&
        sortedConsultants.map((consultant) => (
          <ConsultantCard
            key={consultant.id}
            consultant={consultant}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            selected={consultant.id === id}
            highlighted={consultant.id === hoverId}
            mobile={mobile}
            t={t}
          />
        ))}
    </Container>
  );
};
