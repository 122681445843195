import React, { FC, memo } from 'react';
import {
  Container,
  Content,
  GlobalStyle,
  FixedContainer,
  Overlay,
} from './styled';

export interface ModalProps {
  onClose?: () => void;
  className?: string;
}

export const Modal: FC<ModalProps> = memo(
  ({ onClose, className, children }) => (
    <FixedContainer>
      <GlobalStyle />
      <Container>
        <Overlay onClick={() => onClose && onClose()} clickable={!!onClose} />
        <Content className={className}>{children}</Content>
      </Container>
    </FixedContainer>
  ),
);
