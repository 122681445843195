import React, { FC, memo } from 'react';
import { Partner } from '../../types/Partner';
import { ReactComponent as Braas } from '../../assets/partners/braas.svg';
import { ReactComponent as Icopal } from '../../assets/partners/icopal.svg';
import { ReactComponent as Isover } from '../../assets/partners/isover.svg';
import { ReactComponent as Rathscheck } from '../../assets/partners/rathscheck.svg';
import { ReactComponent as Rheinzink } from '../../assets/partners/rheinzink.svg';
import { ReactComponent as Vedag } from '../../assets/partners/vedag.svg';
import { ReactComponent as Velux } from '../../assets/partners/velux.svg';
import { ReactComponent as Wolfin } from '../../assets/partners/wolfin.svg';

interface Props {
  partner: Partner;
}

export const PartnerLogo: FC<Props> = memo(({ partner }) => {
  switch (partner) {
    case 'braas':
      return <Braas />;
    case 'icopal':
      return <Icopal />;
    case 'isover':
      return <Isover />;
    case 'rathscheck':
      return <Rathscheck />;
    case 'rheinzink':
      return <Rheinzink />;
    case 'vedag':
      return <Vedag />;
    case 'velux':
      return <Velux />;
    case 'wolfin':
      return <Wolfin />;
  }
  return null;
});
