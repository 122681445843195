import React, { FC, useEffect } from 'react';
import { ModalProps, Avatar } from '@meindach/ui-kit';
import { P } from '@meindach/ui-kit';
import { Content, Name, Header, StyledModal, Links } from './styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '@meindach/ui-kit/dist/assets/ui/close.svg';
import { ReactComponent as RooferIcon } from '@meindach/ui-kit/dist/assets/icons/roofer.svg';
import { ReactComponent as OpportunityIcon } from '@meindach/ui-kit/dist/assets/icons/house.svg';
import { TestAttribute } from '../../types/TestAttribute';
import { trackEvent } from '../../util/trackEvent';
import { Consultant } from '../../types/Consultant';
import { ConsultantActions } from './ConsultantActions';
import { ConsultantInfoModalDetails } from './ConsultantInfoModalDetails';
import { LinkAction } from '../../components/linkAction/LinkAction';
import { Route } from '../../types/Route';
import { UserType } from '../../types/UserType';
import { config } from '../../config';

interface Props extends ModalProps {
  consultant: Consultant;
  userType: UserType;
}

export const ConsultantInfoModal: FC<Props> = ({
  consultant,
  userType,
  onClose,
}) => {
  const { t } = useTranslation('consultantInfo');
  const {
    id,
    firstName,
    lastName,
    rooferCount,
    email,
    opportunityCount,
    imageHash,
  } = consultant;
  useEffect(() => {
    trackEvent('consultant', 'openInfo', id);
  }, [id]);
  const close = () => onClose && onClose();
  return (
    <StyledModal onClose={onClose}>
      <Header>
        <div>
          <Avatar
            small
            firstName={firstName}
            lastName={lastName}
            src={
              imageHash &&
              `${config.apiBaseUrl}/api/v1/public/images/sales-consultants/${id}/${imageHash}/size?assetType=THUMBNAIL`
            }
          />
        </div>
        <Content>
          <P>{t('title')}</P>
          <Name>
            {firstName} {lastName}
          </Name>
          {userType !== UserType.Consultant && (
            <Links>
              <LinkAction
                icon={RooferIcon}
                text={rooferCount}
                link={`${Route.Roofers}#${email}`}
                onClick={close}
              />
              <LinkAction
                icon={OpportunityIcon}
                text={opportunityCount}
                link={`${Route.Opportunities}#${email}`}
                onClick={close}
              />
            </Links>
          )}
        </Content>
        <div>
          <CloseIcon onClick={close} data-test-id={TestAttribute.ModalClose} />
        </div>
      </Header>
      <ConsultantInfoModalDetails
        {...consultant}
        directorView={userType === UserType.Director}
        close={close}
      />
      <ConsultantActions {...consultant} />
    </StyledModal>
  );
};
