import styled from '../../styled-components';
import { Modal, Title, P } from '@meindach/ui-kit';

export const StyledModal = styled(Modal)`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  > svg {
    padding: ${({ theme }) => theme.spacing.l};
  }

  .annotationLayer {
    display: none;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  padding: ${({ theme }) => theme.spacing.m};

  display: flex;
  flex-wrap: wrap;

  > ${Title} {
    margin: 0 auto;
    width: 100%;
    padding: 0 60px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    pointer-events: none;
  }

  > :last-child {
    margin-left: auto;
    padding-left: ${({ theme }) => theme.spacing.s};
    color: ${({ theme }) => theme.color.silver};
    cursor: pointer;

    > svg {
      width: 30px;
      height: 30px;
    }
  }
`;

export const Viewer = styled.div`
  text-align: center;
  svg {
    padding: ${({ theme }) => theme.spacing.l};
  }
  canvas {
    width: 100% !important;
    height: auto !important;
  }
  .react-pdf__Page__textContent {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.m};
  box-shadow: 0 -4px 4px 0 ${({ theme }) => theme.color.shadow};
  position: relative;

  > ${P} {
    margin: 0 auto;
  }
`;

export const ReviewButtonContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.m} 0;

  > :first-child {
    margin-right: ${({ theme }) => theme.spacing.m};
  }
`;
