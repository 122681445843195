import { useEffect } from 'react';
import { getUser } from '../api/getUser';
import { useStore, createStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { useAuth } from './useAuth';
import { User } from '../types/User';

interface State {
  user?: User;
  error?: boolean;
  fetching?: boolean;
}

export const store = createStore<State>(Store.User, {});

export const useUser = () => {
  const { authenticated, logout } = useAuth();
  const [state, setState] = useStore<State>(Store.User);
  const { user = null, error = false, fetching = false } = state;

  useEffect(() => {
    if (authenticated) {
      // Get fetching through store to get latest value while hook will only be updated after
      if (!user && !store.getState().fetching) {
        const fetchUser = async () => {
          setState({ fetching: true });
          try {
            const response = await getUser();
            if ([401, 403].includes(response.status)) {
              logout();
            } else if (response.ok) {
              setState({ user: await response.json() });
            } else {
              setState({
                error: [404, 500].includes(response.status),
              });
            }
          } catch {
            setState({ error: true });
          }
        };
        fetchUser();
      }
    } else if (user) {
      setState({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return { user, fetching, error };
};
