import styled, { css } from '../../styled-components';
import { P, Card, media, touchSupport } from '@meindach/ui-kit';

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-left: 1px solid ${({ theme }) => theme.color.mercury};

  > div:first-child {
    border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  }
  > div,
  > a {
    width: 51px;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.color.blue};

    > svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
  }
`;

export const IdContainer = styled.div`
  border-right: 1px solid ${({ theme }) => theme.color.mercury};

  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 18px;
  letter-spacing: 0.2px;

  align-items: center;

  padding: ${({ theme }) => theme.spacing.s};
  display: none;

  ${media.md`
    display: flex;
  `}
`;

export const StyledCard = styled(Card)`
  width: 100%;
  height: 108px;
  cursor: pointer;
  > div {
    padding: 0;
  }

  ${IdContainer} {
    ${({ selected, highlighted }) =>
      !selected &&
      highlighted &&
      media.md`
        color: ${({ theme }) => theme.color.blue};
      `}
    ${({ selected }) =>
      selected
        ? css`
            color: ${({ theme }) => theme.color.blue};
          `
        : !touchSupport &&
          media.md`
            &:hover {
              color: ${({ theme }) => theme.color.blue};
            }
          `};
  }
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.18px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.md`
    font-size: 18px;
    letter-spacing: 0.2px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;

  padding: 12px ${({ theme }) => theme.spacing.m};

  ${Name} {
    margin: 0;
  }
  ${P} {
    margin: 0;
    display: flex;
    align-items: center;

    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > svg {
      width: 20px;
      height: 20px;
      margin-left: -${({ theme }) => theme.spacing.xs};
      flex-shrink: 0;
    }
  }
`;

export const Company = styled.div`
  display: flex;
  align-items: center;

  svg,
  div {
    margin-right: ${({ theme }) => theme.spacing.s};
  }

  svg {
    width: auto;
    height: 24px;
  }
`;
