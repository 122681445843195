import styled, { css } from '../../styled-components';
import { ReactComponent as Pin } from '@meindach/ui-kit/dist/assets/map/consultant-pin.svg';
import { ReactComponent as PinSelected } from '@meindach/ui-kit/dist/assets/map/consultant-pin-selected.svg';

export const StyledPin = styled(Pin)`
  cursor: pointer;
  width: auto;
  height: 35px;
`;

export const StyledPinSelected = styled(PinSelected)`
  cursor: pointer;
  width: auto;
  height: 60px;
`;

interface Props {
  selected?: boolean;
}

export const Text = styled.p<Props>`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  color: ${({ theme }) => theme.color.white};

  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  margin: 14px 0 0;
  pointer-events: none;

  ${({ selected }) =>
    selected &&
    css`
      margin: 22px 0 0;
      font-size: 16px;
      font-weight: 600;
      color: #009fe3;
    `}
`;
