import React, { FC, useState, memo, ReactElement } from 'react';
import { TabPanel, Tab } from './styled';
import { P } from '@meindach/ui-kit';

interface TabType {
  icon: FC;
  label?: string;
  component: ReactElement;
}

interface Props {
  tabs: TabType[];
}

export const PanelTabs: FC<Props> = memo(({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const ActiveComponent = tabs[activeTab].component;
  return (
    <>
      <TabPanel>
        {tabs.map(({ icon: Icon, label }, index) => (
          <Tab
            key={index}
            onClick={() => setActiveTab(index)}
            active={index === activeTab}
          >
            <Icon />
            {label && <P>{label}</P>}
          </Tab>
        ))}
      </TabPanel>
      {ActiveComponent}
    </>
  );
});
