import styled, { css } from '../../../styled-components';
import { media } from '../../../styles/media';

export interface ParagraphProps {
  lead?: boolean;
  ellipsis?: boolean;
}

export const P = styled.p<ParagraphProps>`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 1em;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.color.storm};

  ${media.md`
    font-size: 1em;
    line-height: 25px;
    letter-spacing: initial;
  `}

  ${({ lead }) =>
    lead &&
    css`
      line-height: 22px;
      letter-spacing: 0.2px;

      ${media.md`
        color: ${({ theme }) => theme.color.storm};
        font-size: 24px;
        line-height: 35px;
        letter-spacing: initial;
      `}
    `}

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;
