import React, { FC } from 'react';
import { Tag } from '@meindach/ui-kit';
import { Status, IconContainer } from './styled';
import { ReactComponent as PitchedRoof } from '@meindach/ui-kit/dist/assets/icons/house.svg';
import { ReactComponent as FlatRoof } from '@meindach/ui-kit/dist/assets/icons/roof-flat.svg';
import { Opportunity } from '../../types/Opportunity';
import { getOpportunityStatus } from '../../util/getOpportunityStatus';

type TypeProps = Pick<Opportunity, 'roofType' | 'salesStage'>;

type Props = TypeProps & {
  t: (key: string) => string;
};

const getType = (salesStage: Opportunity['salesStage']) =>
  ['roofer_picking', 'Resubmission'].includes(salesStage)
    ? 'info'
    : ['completion', 'Cleared'].includes(salesStage)
    ? 'success'
    : 'warning';

export const OpportunityStatus: FC<Props> = ({ roofType, salesStage, t }) => (
  <Status>
    <OpportunityStatusIcon roofType={roofType} salesStage={salesStage} />
    <OpportunityStatusTag salesStage={salesStage} t={t} />
  </Status>
);

export const OpportunityStatusIcon: FC<TypeProps> = ({
  roofType,
  salesStage,
}) => (
  <IconContainer status={getOpportunityStatus(salesStage)}>
    {roofType === 'flat_roof' ? <FlatRoof /> : <PitchedRoof />}
  </IconContainer>
);

export const OpportunityStatusTag: FC<Omit<Props, 'roofType'>> = ({
  salesStage,
  t,
}) => (
  <Tag type={getType(salesStage)}>
    {t(`opportunityStatus:${getOpportunityStatus(salesStage)}`)}
  </Tag>
);
