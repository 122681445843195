import React from 'react';
import { ReactComponent as Icon } from '@meindach/ui-kit/dist/assets/icons/consultant-none.svg';
import { NoConsultantsContainer } from './styled';
import { Title, P } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { TestAttribute } from '../../types/TestAttribute';

export const NoConsultants = () => {
  const { t } = useTranslation('consultantList');
  return (
    <NoConsultantsContainer data-test-id={TestAttribute.NoConsultants}>
      <Icon />
      <Title m>{t('emptyListTitle')}</Title>
      <P>{t('emptyListText')}</P>
    </NoConsultantsContainer>
  );
};
