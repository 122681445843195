import React from 'react';
import { ReactComponent as Icon } from '@meindach/ui-kit/dist/assets/icons/roofer-none.svg';
import { NoRoofersContainer } from './styled';
import { Title, P } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { TestAttribute } from '../../types/TestAttribute';

export const NoRoofersFound = () => {
  const { t } = useTranslation('rooferList');
  return (
    <NoRoofersContainer data-test-id={TestAttribute.NoRoofersFound}>
      <Icon />
      <Title m>{t('emptyListTitle')}</Title>
      <P>{t('emptyListText')}</P>
    </NoRoofersContainer>
  );
};
