import React from 'react';
import { MobileRooferFilters } from '../../components/rooferFilters/MobileRooferFilters';
import { usePlatform } from '@meindach/ui-kit';
import { Container, StickyConsultantBar } from './styled';
import { RooferList } from '../../components/rooferList/RooferList';
import { RooferMap } from '../../components/rooferMap/RooferMap';
import { MobileNavigation } from '../../components/mobileNavigation/MobileNavigation';
import { Route as RouteType } from '../../types/Route';
import { Route, Redirect, Switch } from 'react-router-dom';
import { DesktopRooferFilters } from '../../components/rooferFilters/DesktopRooferFilters';
import { PanelContainer } from '../../components/panel/PanelContainer';
import { useRooferFilters } from '../../hooks/useRooferFilters';

const DesktopRoofers = () => (
  <>
    <DesktopRooferFilters />
    <Container>
      <RooferList />
      <PanelContainer>
        <RooferMap />
      </PanelContainer>
    </Container>
  </>
);

export const Roofers = () => {
  const { active } = useRooferFilters();
  const { desktop } = usePlatform();
  if (desktop) {
    return (
      <Switch>
        <Route exact path={RouteType.Roofers} component={DesktopRoofers} />
        <Redirect to={RouteType.Roofers} />
      </Switch>
    );
  }
  return (
    <>
      <StickyConsultantBar />
      <Container>
        <Switch>
          <Route exact path={RouteType.Roofers} component={RooferList} />
          <Route exact path={RouteType.RooferMap} component={RooferMap} />
          <Route
            exact
            path={RouteType.RooferFilters}
            component={MobileRooferFilters}
          />
          <Redirect to={RouteType.Roofers} />
        </Switch>
      </Container>
      <MobileNavigation
        tabs={[
          { route: RouteType.Roofers, label: 'list' },
          { route: RouteType.RooferMap, label: 'map' },
          { route: RouteType.RooferFilters, label: 'filters', active },
        ]}
      />
    </>
  );
};
