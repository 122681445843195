import React from 'react';
import { ReactComponent as Icon } from '@meindach/ui-kit/dist/assets/icons/consultants.svg';
import { ReactComponent as Loading } from '@meindach/ui-kit/dist/assets/loading/loading.svg';
import { LoadingContainer } from './styled';
import { Title, P } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { TestAttribute } from '../../types/TestAttribute';

export const LoadingList = () => {
  const { t } = useTranslation('consultantList');
  return (
    <LoadingContainer data-test-id={TestAttribute.LoadingConsultants}>
      <Icon />
      <Title m>{t('loadingTitle')}</Title>
      <P>{t('loadingText')}</P>
      <Loading />
    </LoadingContainer>
  );
};
