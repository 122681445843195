import styled, { css } from '../../styled-components';
import { P, Modal, Title, media } from '@meindach/ui-kit';
import { ReactComponent as LoadingIcon } from '@meindach/ui-kit/dist/assets/loading/loading.svg';

export const StyledModal = styled(Modal)`
  max-width: 410px;
  margin: 0 auto;
`;

export const Name = styled(Title)`
  margin: 0;
`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spacing.m};
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;

  > div {
    &:first-child {
      flex: 0;
      display: flex;
      align-items: center;
      margin-right: ${({ theme }) => theme.spacing.m};
    }
    &:nth-child(2) {
      flex: 1;
    }
  }
  > a {
    display: flex;
    align-items: center;
    margin-left: ${({ theme }) => theme.spacing.m};
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  height: 52px;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.m};

  > svg,
  > ${P} {
    cursor: pointer;
  }
  &,
  > ${P} {
    color: ${({ theme }) => theme.color.blue};
  }
  > ${P} {
    font-weight: 600;
    margin: 0;
    padding: ${({ theme }) => theme.spacing.s} 0;
  }
  > svg {
    width: 16px;
    height: 20px;
    transform: rotate(180deg);
    padding: ${({ theme }) => theme.spacing.s};
    margin-left: -${({ theme }) => theme.spacing.s};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  ${Name} {
    margin: 0;
  }
  ${P} {
    margin: 0;
    display: flex;
    align-items: center;

    > svg {
      width: 20px;
      height: 20px;
      margin-left: -${({ theme }) => theme.spacing.xs};
    }
  }
`;

export const Entry = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  display: flex;

  > ${P} {
    flex: 70;
    margin: ${({ theme }) => theme.spacing.s} 0;

    &:first-child {
      flex: 30;
      display: flex;
      color: ${({ theme }) => theme.color.silver};
    }

    > svg {
      margin-right: ${({ theme }) => theme.spacing.s};
      width: 25px;
      height: 25px;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const PassiveAction = styled(Entry)`
  > ${P} {
    &:first-child {
      flex: 0;
      color: ${({ theme }) => theme.color.storm};
    }
  }
`;

export const Details = styled.div`
  padding: ${({ theme }) => theme.spacing.m};

  > ${P} {
    font-size: 16px;
    margin: 0;
    line-height: 43px;
    border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 174px;
  pointer-events: none;
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

export const Interests = styled(P)`
  ${media.md`
    font-size: 16px;
    && {
      margin: ${({ theme }) => theme.spacing.s} 0;
      line-height: 25px;
      border-bottom: none;
    }
  `}
`;

export const Table = styled.table`
  padding: ${({ theme }) => theme.spacing.m};
  width: ${({ theme }) => `calc(100% + ${theme.spacing.m})`};
  text-align: left;
  margin: 0 -${({ theme }) => theme.spacing.s};
  border-spacing: ${({ theme }) => theme.spacing.s} 0;

  td {
    border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
    height: 36px;
    width: 50%;
    padding: 0 ${({ theme }) => theme.spacing.s};
  }
  > tbody > tr {
    color: ${({ theme }) => theme.color.slate};
    font-size: 14px;
    &:nth-child(odd) {
      background: ${({ theme }) => theme.color.alabaster};
      font-weight: 600;
    }
    &:nth-child(even) > td {
      border-collapse: collapse;
      border-bottom: ${({ theme }) => theme.spacing.s} solid transparent;
    }
    &:last-of-type > td {
      border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.color.mercury};
  padding: 0 ${({ theme }) => theme.spacing.m};
  margin: 0 ${({ theme }) => theme.spacing.m};
  flex-grow: 0;
`;

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: ${({ theme }) => theme.spacing.xs} 0;
  &,
  > ${P} {
    color: ${({ theme }) => theme.color.blue};
    margin: 0;
    font-weight: 600;
  }
  > svg {
    width: auto;
    height: 24px;
    &:first-of-type {
      margin-right: ${({ theme }) => theme.spacing.s};
    }
    &:last-of-type {
      height: 13px;
      margin-left: ${({ theme }) => theme.spacing.s};
    }
  }
`;

export const DownloadActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.color.mercury};
  padding: 0 ${({ theme }) => theme.spacing.m};
  margin: 0 ${({ theme }) => theme.spacing.m};
  flex-grow: 0;
`;

interface ActionProps {
  disabled: boolean;
}

export const Action = styled(P)<ActionProps>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xs} 0;
  color: ${({ theme }) => theme.color.blue};
  margin: 0;
  font-weight: 600;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.color.storm};
    `}

  > svg {
    width: 19px;
    height: 19px;
    &:first-of-type {
      margin-right: ${({ theme }) => theme.spacing.s};
    }
    &:last-of-type {
      height: 13px;
      margin-left: ${({ theme }) => theme.spacing.s};
    }
  }
`;

export const Loading = styled(LoadingIcon)`
  width: 160px;
  height: 20px;
  margin: ${({ theme }) => theme.spacing.m} auto;
  display: block;
`;
