import React, { FC } from 'react';
import { Title } from '@meindach/ui-kit';
import { Container } from './styled';
import { PageTitle } from '../pageTitle/PageTitle';

export const PageHeader: FC = ({ children }) => (
  <Container>
    <Title l>
      <PageTitle />
    </Title>
    {children}
  </Container>
);
