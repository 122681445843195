import styled from '../../styled-components';
import { P, Modal, Avatar } from '@meindach/ui-kit';

export const StyledModal = styled(Modal)`
  max-width: 410px;
  margin: 0 auto;
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.18px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  padding: ${({ theme }) => theme.spacing.m};

  display: flex;
  flex-wrap: wrap;

  > div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: ${({ theme }) => theme.spacing.m};
    }

    &:last-child {
      padding-left: ${({ theme }) => theme.spacing.s};
      color: ${({ theme }) => theme.color.silver};
      cursor: pointer;

      > svg {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 50px;
  height: 50px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  flex: 1;

  ${Name} {
    margin: 0;
  }
  > ${P} {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 14px;

    > svg {
      width: 20px;
      height: 20px;
      margin-left: -${({ theme }) => theme.spacing.xs};
    }
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
`;

export const Entry = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  display: flex;

  > ${P} {
    flex: 65;
    margin: ${({ theme }) => theme.spacing.s} 0;

    &:first-child {
      flex: 35;
      display: flex;
    }

    > svg {
      margin-right: ${({ theme }) => theme.spacing.s};
      width: 25px;
      height: 25px;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const Details = styled.div`
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m};
`;
