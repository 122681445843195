import { Coordinates } from '../types/Coordinates';

const getMaxDelta = (coordinates: Coordinates[]) => {
  const latitudes = coordinates.map(({ lat }) => lat);
  const longitudes = coordinates.map(({ lon }) => lon);
  return Math.max(
    Math.max(...latitudes) - Math.min(...latitudes),
    Math.max(...longitudes) - Math.min(...longitudes),
  );
};

export const getZoomLevel = (coordinates: Coordinates[]) => {
  const delta = getMaxDelta(coordinates);
  if (delta < 1.1) {
    return 8;
  }
  if (delta < 2) {
    return 7;
  }
  if (delta < 4) {
    return 6;
  }
  return 5;
};
