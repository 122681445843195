export const translatedFieldNames = [
  'salesStage',
  'startDate',
  'roofType',
  'reason',
  'material',
  'buildingType',
  'requestType',
  'maskCurrentTile',
  'tileShapeType',
  'tileColorType',
  'tileFinishType',
  'insulation',
  'maskBudget',
  'financeType',
  'windowType',
  'maskEnergyRoof',
  'maskSpecialities',
];
