import React, { FC, memo } from 'react';
import { ReactComponent as A } from '../../assets/ratings/roofers/a.svg';
import { ReactComponent as B } from '../../assets/ratings/roofers/b.svg';
import { ReactComponent as C } from '../../assets/ratings/roofers/c.svg';
import { ReactComponent as Y } from '../../assets/ratings/roofers/y.svg';
import { RooferRating as Rating } from '../../types/RooferRating';

interface Props {
  rating: Rating;
}

export const RooferRating: FC<Props> = memo(({ rating }) => {
  switch (rating) {
    case 'A':
      return <A />;
    case 'B':
      return <B />;
    case 'C':
      return <C />;
    case 'Y':
      return <Y />;
  }
  return null;
});
