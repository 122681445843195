import { useEffect } from 'react';
import { getTranslations } from '../api/getTranslations';
import { createStore, useStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { useAuth } from './useAuth';

interface State {
  fields?: Record<string, Record<string, string>>;
  error?: boolean;
  fetching?: boolean;
}

export const store = createStore<State>(Store.Translations, {});

const fetchTranslations = async (
  fields: string[],
  setState: (state: State) => void,
) => {
  setState({ fetching: true });
  try {
    const response = await getTranslations(fields);
    if (response.ok) {
      const fields = await response.json();
      setState({ fields });
      return;
    }
  } catch (e) {}
  setState({ error: true });
};

export const useFieldTranslations = (requestedFields: string[]) => {
  const { authenticated } = useAuth();
  const [state, setState] = useStore<State>(Store.Translations);
  const { fields, error = false, fetching = false } = state;

  useEffect(() => {
    if (authenticated && !fields && !store.getState().fetching) {
      fetchTranslations(requestedFields, setState);
    }
  }, [authenticated, fields, requestedFields, setState]);

  return { fields, fetching, error };
};
