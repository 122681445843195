import React, { useRef, useState } from 'react';
import {
  Container,
  ContentContainer,
  InnerContainer,
  LinkContent,
} from './styled';
import { useTranslation } from 'react-i18next';
import { Avatar, Title } from '@meindach/ui-kit';
import { ReactComponent as MenuIcon } from '@meindach/ui-kit/dist/assets/icons/menu.svg';
import { ReactComponent as ConsultantsIcon } from '@meindach/ui-kit/dist/assets/icons/consultants.svg';
import { ReactComponent as RooferIcon } from '@meindach/ui-kit/dist/assets/icons/roofer.svg';
import { ReactComponent as OpportunitiesIcon } from '@meindach/ui-kit/dist/assets/icons/house.svg';
import { ReactComponent as HelpIcon } from '@meindach/ui-kit/dist/assets/icons/help.svg';
import { Link } from 'react-router-dom';
import { Route } from '../../types/Route';
import { useAuth } from '../../hooks/useAuth';
import { useUser } from '../../hooks/useUser';
import { useClickOutside } from 'use-events';
import { UserType } from '../../types/UserType';
import { config } from '../../config';

export const Menu = () => {
  const { t } = useTranslation('menu');
  const [open, setOpen] = useState<boolean>(false);
  const { authenticated } = useAuth();
  const { user } = useUser();
  const ref = useRef(null);
  useClickOutside([ref], () => open && setOpen(false));
  const toggle = () => setOpen(!open);
  const isManager =
    user && [UserType.Manager, UserType.Director].includes(user.type);
  const isActiveRoute = (route: Route) =>
    window.location.pathname.startsWith(route);
  return (
    <Container open={open} onClick={toggle} ref={ref}>
      <InnerContainer>
        <MenuIcon />
        <Title s>{t('title')}</Title>
      </InnerContainer>
      {open && (
        <ContentContainer>
          {authenticated && !isManager && (
            <>
              <Link to={Route.Roofers}>
                <LinkContent active={isActiveRoute(Route.Roofers)}>
                  <RooferIcon />
                  <Title s>{t('pageTitle:roofers')}</Title>
                </LinkContent>
              </Link>
              <Link to={Route.Opportunities}>
                <LinkContent active={isActiveRoute(Route.Opportunities)}>
                  <OpportunitiesIcon />
                  <Title s>{t('pageTitle:opportunities')}</Title>
                </LinkContent>
              </Link>
            </>
          )}
          {authenticated && (
            <>
              <Link to={Route.Consultants}>
                <LinkContent active={isActiveRoute(Route.Consultants)}>
                  <ConsultantsIcon />
                  <Title s>{t('pageTitle:consultants')}</Title>
                </LinkContent>
              </Link>
              <Link to={Route.Account}>
                <LinkContent active={isActiveRoute(Route.Account)}>
                  {user ? (
                    <Avatar
                      small
                      firstName={user.firstName}
                      lastName={user.lastName}
                      src={
                        user.imageHash &&
                        `${config.apiBaseUrl}/api/v1/public/images/sales-consultants/${user.id}/${user.imageHash}/size?assetType=THUMBNAIL`
                      }
                    />
                  ) : (
                    <Avatar small />
                  )}
                  <Title s>{t('pageTitle:account')}</Title>
                </LinkContent>
              </Link>
            </>
          )}
          <Link to={Route.Help}>
            <LinkContent active={isActiveRoute(Route.Help)}>
              <HelpIcon />
              <Title s>{t('pageTitle:help')}</Title>
            </LinkContent>
          </Link>
        </ContentContainer>
      )}
    </Container>
  );
};
