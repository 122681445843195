import React, { FC } from 'react';
import { Roofer } from '../../types/Roofer';
import { trackEvent } from '../../util/trackEvent';
import { getGoogleMapsUrl } from '../../util/getGoogleMapsUrl';
import { ReactComponent as MapIcon } from '@meindach/ui-kit/dist/assets/icons/google-maps.svg';
import { ReactComponent as PhoneIcon } from '@meindach/ui-kit/dist/assets/icons/phone.svg';
import { ReactComponent as MailIcon } from '@meindach/ui-kit/dist/assets/icons/mail.svg';
import { Action } from './styled';
import { P, formatPhoneNumber } from '@meindach/ui-kit';

export type ContactActionsProps = Pick<
  Roofer,
  'city' | 'street' | 'latLon' | 'postalCode' | 'id' | 'phone' | 'email'
>;

export const ContactActions: FC<ContactActionsProps> = ({
  id,
  phone,
  email,
  street,
  latLon,
  postalCode,
  city,
}) => (
  <>
    <Action
      href={`tel:${phone}`}
      onClick={() => trackEvent('roofer', 'call', id)}
    >
      <PhoneIcon />
      <P>{formatPhoneNumber(phone)}</P>
    </Action>
    <Action
      href={getGoogleMapsUrl(`${street}, ${postalCode} ${city}`, latLon)}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => trackEvent('roofer', 'openMap', id)}
    >
      <MapIcon />
      <P>{`${street}, ${postalCode} ${city}`}</P>
    </Action>
    <Action
      href={`mailto:${email}`}
      onClick={() => trackEvent('roofer', 'email', id)}
    >
      <MailIcon />
      <P>{email}</P>
    </Action>
  </>
);
