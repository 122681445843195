import React, { FC, memo } from 'react';
import {
  IdContainer,
  Content,
  Company,
  Name,
  Actions,
  StyledCard,
} from './styled';
import { ReactComponent as LocationIcon } from '@meindach/ui-kit/dist/assets/icons/location.svg';
import { ReactComponent as RooferIcon } from '@meindach/ui-kit/dist/assets/icons/handshake.svg';
import { ReactComponent as InfoIcon } from '@meindach/ui-kit/dist/assets/icons/info.svg';
import { ReactComponent as OpportunityIcon } from '@meindach/ui-kit/dist/assets/icons/house.svg';
import {
  P,
  Tag,
  PartnerLogo,
  padNumber,
  scrollParentToChildPos,
} from '@meindach/ui-kit';
import { Roofer } from '../../types/Roofer';
import { Link } from 'react-router-dom';
import { Route } from '../../types/Route';

interface Props {
  selected?: boolean;
  highlighted?: boolean;
  id?: number;
  roofer: Pick<
    Roofer,
    | 'id'
    | 'name'
    | 'postalCode'
    | 'city'
    | 'plusPartner'
    | 'new'
    | 'partner'
    | 'classification'
    | 'imageHash'
  >;
  setId?: (id: string | null) => void;
  setHoverId?: (id: string | null) => void;
  onMouseLeave?: () => any;
  t: (key: string) => string;
  mobile?: boolean;
}

export const scrollToCard = (index: number, instant: boolean = false) =>
  scrollParentToChildPos(
    document.querySelector(`[data-id=card${index + 1}]`),
    instant,
  );

export const RooferCard: FC<Props> = memo(
  ({ id = 0, roofer, setId, setHoverId, mobile, t, ...rest }) => {
    const {
      name,
      postalCode,
      city,
      partner,
      plusPartner,
      new: newPartner,
      classification,
    } = roofer;
    const onMouseEnter = mobile
      ? undefined
      : () => setHoverId && setHoverId(roofer.id);
    const onClick = () => {
      if (setId) {
        setId(roofer.id);
      }
      if (mobile && setHoverId) {
        setHoverId(roofer.id);
      }
    };
    return (
      <StyledCard onMouseEnter={onMouseEnter} {...rest} data-id={`card${id}`}>
        <IdContainer onClick={onClick}>#{padNumber(id)}</IdContainer>
        <Content onClick={onClick}>
          <Name>{name}</Name>
          <P>
            <LocationIcon />
            <span>
              {postalCode} {city}
            </span>
          </P>
          <Company>
            <PartnerLogo partner={partner} />
            <Tag type="success">
              <RooferIcon />
              {classification && classification.conversionRate}%
            </Tag>
            {plusPartner && <Tag type="info">{t('type:plus')}</Tag>}
            {newPartner && <Tag type="warning">{t('type:new')}</Tag>}
          </Company>
        </Content>
        <Actions>
          <div onClick={onClick}>
            <InfoIcon />
          </div>
          <Link
            to={{
              pathname: Route.Opportunities,
              hash: `#${roofer.id}`,
              search: window.location.hash
                ? `?consultant=${window.location.hash.substr(1)}`
                : '',
            }}
          >
            <OpportunityIcon />
          </Link>
        </Actions>
      </StyledCard>
    );
  },
);
