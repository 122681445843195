export enum Route {
  Root = '/',
  Account = '/account',
  Callback = '/callback',
  ConsultantFilters = '/consultants/filters',
  ConsultantMap = '/consultants/map',
  Consultants = '/consultants',
  EmailVerified = '/email-verified',
  Help = '/help',
  Login = '/login',
  Logout = '/logout',
  Opportunities = '/opportunities',
  OpportunityFilters = '/opportunities/filters',
  OpportunityMap = '/opportunities/map',
  RooferFilters = '/roofers/filters',
  RooferMap = '/roofers/map',
  Roofers = '/roofers',
}
