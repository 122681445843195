import { ActiveConsultantBar } from './../activeConsultantBar/ActiveConsultantBar';
import styled, { css } from '../../styled-components';
import { Select, Label, P, media } from '@meindach/ui-kit';

export const StyledSelect = styled(Select)`
  ${media.md`
    margin-right: ${({ theme }) => theme.spacing.m};
    width: 35%;
    max-width: 280px;
    :last-of-type {
      max-width: 265px;
    }
  `}
`;

export const StyledConsultantBar = styled(ActiveConsultantBar)`
  max-width: 375px;
  margin-right: ${({ theme }) => theme.spacing.m};
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  padding: ${({ theme }) => theme.spacing.s};

  > ${Label} {
    font-size: 18px;
    ${({ theme }) => css`
      margin: ${theme.spacing.m} 0 ${theme.spacing.s};
    `}
  }
`;

export const Clear = styled(P)`
  color: ${({ theme }) => theme.color.blue};
  font-weight: 600;
  margin: ${({ theme }) => theme.spacing.l} auto
    ${({ theme }) => theme.spacing.m};
  padding: ${({ theme }) => theme.spacing.m};
`;
