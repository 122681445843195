import React, {
  FC,
  useState,
  ChangeEvent,
  useRef,
  ComponentClass,
} from 'react';
import {
  Container,
  Placeholder,
  StyledLabel,
  OptionsContainer,
  Option,
  SelectContainer,
  StyledChevron,
} from './styled';
import { NativeSelect } from './NativeSelect';
import { TestAttribute } from '../../types/TestAttribute';
import { Checkbox } from '../checkbox/Checkbox';
import { useClickOutside } from 'use-events';

export interface Option<T extends string = string> {
  value: T;
  /**
   * Display value of the option. If not provided will use value as the label.
   */
  label?: string;
  disabled?: boolean;
}

export interface SelectProps<T extends string = string> {
  name?: string;
  className?: string;
  placeHolder?: string;
  test?: HTMLSelectElement;
  options: Option<T>[];
  multiple?: boolean;
  small?: boolean;
  required?: boolean;
  selectedValues?: T[];
  onChange?: ((selectedValue: T) => any) | ((selectedValues: T[]) => any);
  onBlur?: () => void;
  icon?: FC | ComponentClass;
  error?: boolean;
}

const desktop = !navigator.userAgent.match(
  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i,
);

export const Select: FC<SelectProps> = ({
  name,
  options,
  selectedValues = [],
  placeHolder,
  onChange: onChangeCallback,
  onBlur,
  multiple,
  className,
  small,
  error,
  icon: Icon,
}) => {
  const [open, setOpenState] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>(selectedValues);
  const setOpen = (openState: boolean) => {
    if (!openState && onBlur) {
      onBlur();
    }
    setOpenState(openState);
  };
  const ref = useRef(null);
  useClickOutside([ref], () => desktop && open && setOpen(false));

  const isSelected = (value: string) => selected.includes(value);

  const selectedOptions = options.filter(({ value }) => isSelected(value));
  const label = selectedOptions
    .map(({ label, value }) => label || value)
    .join(', ');

  const toggle = () => desktop && setOpen(!open);

  const onChange = ({ target }: ChangeEvent<HTMLSelectElement>) =>
    multiple
      ? updateValues(
          new Array(target.selectedOptions.length)
            .fill(null)
            .map((_, index) => target.selectedOptions[index].value),
        )
      : changeSelected(target.value);

  const changeSelected = (value: string) => {
    if (!multiple) {
      updateValues([value]);
      return;
    }
    if (selected.includes(value)) {
      updateValues(selected.filter((val) => val !== value));
    } else {
      updateValues([...selected, value]);
    }
  };
  const updateValues = (values: string[]) => {
    setSelected(values);
    if (onChangeCallback) {
      type Callback = (value: string | string[]) => any;
      (onChangeCallback as Callback)(multiple ? values : values[0]);
    }
    if (!multiple && open) {
      setOpenState(false);
    }
  };

  return (
    <Container
      selected={selected.length > 0}
      {...{ ref, open, className, error }}
    >
      <SelectContainer
        desktop={desktop}
        small={small}
        onClick={toggle}
        data-test-id={TestAttribute.SelectContainer}
      >
        {selected.length < 1 && (
          <Placeholder error={error} ellipsis>
            {placeHolder}
          </Placeholder>
        )}
        {selected.length > 0 && <StyledLabel ellipsis>{label}</StyledLabel>}
        <NativeSelect
          {...{ onChange, onBlur, options, selected, multiple, name }}
        />
        {Icon ? <Icon /> : <StyledChevron />}
      </SelectContainer>
      {desktop && open && (
        <OptionsContainer
          data-test-id={TestAttribute.SelectOptions}
          small={small}
          error={error}
        >
          {options.map(({ value, label, disabled }) => (
            <Option
              key={value}
              value={value}
              onClick={() => !disabled && changeSelected(value)}
              selected={isSelected(value)}
              disabled={disabled}
            >
              {multiple && (
                <Checkbox checked={isSelected(value)} disabled={disabled} />
              )}
              <StyledLabel ellipsis>{label || value}</StyledLabel>
            </Option>
          ))}
        </OptionsContainer>
      )}
    </Container>
  );
};
