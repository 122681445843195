import React, { FC, memo, useRef } from 'react';
import { ViewState } from 'react-map-gl';
import { StyledNavigationControl, StyledFullscreenControl } from './styled';

interface Props {
  onViewportChange?: (vp: ViewState) => void;
}

export const Controls: FC<Props> = memo(({ onViewportChange }) => {
  const ref = useRef(null);
  return (
    <>
      <StyledNavigationControl
        onViewportChange={onViewportChange}
        showCompass={false}
      />
      <StyledFullscreenControl ref={ref} container={ref.current} />
    </>
  );
});
