import { css } from '../styled-components';
import { ThemedCssFunction } from 'styled-components';
import { theme } from './theme';

export type Breakpoint = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

export const breakpoints: Record<Breakpoint, number> = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 0,
};

type Media = Record<Breakpoint, ThemedCssFunction<typeof theme>>;

export const media: Media = Object.keys(breakpoints).reduce(
  (acc: Media, label: string) => {
    const breakpoint = label as Breakpoint;
    acc[breakpoint] = (first: any, ...args: any[]) => css`
      @media (min-width: ${breakpoints[breakpoint]}px) {
        ${css(first, ...args)}
      }
    `;

    return acc;
  },
  {} as Media,
);
