import React, { ChangeEvent, FC } from 'react';
import { SelectProps } from './Select';

type Props = Pick<SelectProps, 'options' | 'multiple' | 'name'> & {
  selected: string[];
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: () => void;
};

export const NativeSelect: FC<Props> = ({
  options,
  selected,
  onChange,
  onBlur,
  multiple,
  name,
}) => (
  <select
    value={multiple ? selected : selected[0]}
    {...{ onChange, onBlur, multiple, name }}
  >
    {options.map(({ value, label, disabled }) => (
      <option key={value} value={value} disabled={disabled}>
        {label || value}
      </option>
    ))}
  </select>
);
