export enum Store {
  Auth = 'auth',
  User = 'user',
  Panel = 'panel',
  Roofers = 'roofers',
  RooferFilters = 'rooferFilters',
  RooferSort = 'rooferSort',
  RooferHoverId = 'rooferHoverId',
  RooferSelectedId = 'rooferSelectedId',
  ConsultantFilters = 'consultantFilters',
  ConsultantSort = 'consultantSort',
  ConsultantHoverId = 'consultantHoverId',
  ConsultantSelectedId = 'consultantSelectedId',
  ManagerSelectedId = 'managerSelectedId',
  Opportunities = 'opportunities',
  OpportunityFilters = 'opportunityFilters',
  OpportunitySort = 'opportunitySort',
  OpportunityHoverId = 'opportunityHoverId',
  OpportunitySelectedId = 'opportunitySelectedId',
  SelectedFile = 'selectedFile',
  Consultants = 'consultants',
  Translations = 'translations',
}
