import styled, { css } from '../../styled-components';
import { Title } from '@meindach/ui-kit';

interface Props {
  open: boolean;
}

export const Container = styled.div<Props>`
  height: 51px;
  padding: 0 ${({ theme }) => theme.spacing.m};
  z-index: 1;

  ${({ open, theme }) =>
    open &&
    css`
      background-color: ${open ? theme.color.cyan200 : theme.color.white};
    `};
`;

export const InnerContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${({ theme }) => theme.color.blue};

  > svg {
    width: 14px;
    margin-right: ${({ theme }) => theme.spacing.s};
  }
  > ${Title} {
    color: ${({ theme }) => theme.color.blue};
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
`;

export const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  position: absolute;
  top: 52px;
  right: 0;
  width: 100%;
  box-shadow: 0 4px 4px 0 ${({ theme }) => theme.color.shadow};

  > a > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    box-sizing: border-box;
    border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
    padding: 0 ${({ theme }) => theme.spacing.m};

    > ${Title} {
      color: ${({ theme }) => theme.color.storm};
      margin: 0 0 0 ${({ theme }) => theme.spacing.m};
    }

    > svg {
      color: ${({ theme }) => theme.color.storm};
      width: 38px;
      height: 38px;
    }
  }
`;

interface LinkContentProps {
  active: boolean;
}

export const LinkContent = styled.div<LinkContentProps>`
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.color.blue};
      background: ${({ theme }) => theme.color.cyan200};
    `}
`;
