import React, { FC } from 'react';
import { Opportunity } from '../../types/Opportunity';
import { useTranslation } from 'react-i18next';
import { Table, Loading } from './styled';
import { capitalizeFirstLetter } from '@meindach/ui-kit';
import { useFieldTranslations } from '../../hooks/useFieldTranslations';
import { translatedFieldNames } from '../../data/translatedFieldNames';

export const InfoTable: FC<Opportunity> = ({
  requestType,
  reason,
  buildingType,
  roofSize,
  roofType,
  maskCurrentTile,
  tileShapeType,
  windowTypes,
  windowsNumberActual,
  windowsNumberTarget,
  insulation,
  maskEnergyRoof,
  maskEnergeticRoof,
  maskTopFloor,
  maskAtticInsulation,
  constructionYear,
  tileColorType,
  tileFinishType,
  financeTypes,
  maskSpecialities,
  maskConstructionPlans,
  budget,
}) => {
  const { t } = useTranslation('opportunityInfoTable');
  const { fields } = useFieldTranslations(translatedFieldNames);
  if (!fields) {
    return <Loading />;
  }
  const empty = '-';
  return (
    <Table>
      <tbody>
        <tr>
          <td>{t('requestType')}</td>
          <td>{t('opportunityInfo:restorationReason')}</td>
        </tr>
        <tr>
          <td>{fields.requestType[requestType] || empty}</td>
          <td>{fields.reason[reason] || empty}</td>
        </tr>
        <tr>
          <td>{t('buildingType')}</td>
          <td>{t('constructionYear')}</td>
        </tr>
        <tr>
          <td>{fields.buildingType[buildingType] || empty}</td>
          <td>{constructionYear || empty}</td>
        </tr>
        <tr>
          <td>{t('roofType')}</td>
          <td>{t('roofSize')}</td>
        </tr>
        <tr>
          <td>{fields.roofType[roofType] || empty}</td>
          <td>
            {isNaN(parseFloat(roofSize)) ? roofSize : `ca. ${roofSize} m²`}
          </td>
        </tr>
        <tr>
          <td>{t('maskCurrentTile')}</td>
          <td>{t('tileShapeType')}</td>
        </tr>
        <tr>
          <td>{fields.maskCurrentTile[maskCurrentTile] || empty}</td>
          <td>{fields.tileShapeType[tileShapeType] || empty}</td>
        </tr>
        <tr>
          <td>{t('tileColorType')}</td>
          <td>{t('tileFinishType')}</td>
        </tr>
        <tr>
          <td>{fields.tileColorType[tileColorType] || empty}</td>
          <td>{fields.tileFinishType[tileFinishType] || empty}</td>
        </tr>
        <tr>
          <td>{t('maskAtticInsulation')}</td>
          <td>{t('maskTopFloor')}</td>
        </tr>
        <tr>
          <td>
            {maskAtticInsulation ? t(`common:${maskAtticInsulation}`) : empty}
          </td>
          <td>{maskTopFloor ? t(`common:${maskTopFloor}`) : empty}</td>
        </tr>
        <tr>
          <td>{t('maskEnergeticRoof')}</td>
          <td>{t('insulation')}</td>
        </tr>
        <tr>
          <td>{maskEnergeticRoof || empty}</td>
          <td>{fields.insulation[insulation] || empty}</td>
        </tr>
        <tr>
          <td>{t('budget')}</td>
          <td>{t('financeTypes')}</td>
        </tr>
        <tr>
          <td>{fields.maskBudget[budget] || empty}</td>
          <td>
            {financeTypes.map((type) => fields.financeType[type]).join(', ') ||
              empty}
          </td>
          <td />
        </tr>
        <tr>
          <td>{t('windowType')}</td>
          <td />
        </tr>
        <tr>
          <td>
            {windowTypes.map((type) => fields.windowType[type]).join(', ') ||
              empty}
          </td>
          <td />
        </tr>
        <tr>
          <td>{t('windowCountActual')}</td>
          <td>{t('windowCountTarget')}</td>
        </tr>
        <tr>
          <td>{windowsNumberActual || empty}</td>
          <td>{windowsNumberTarget || empty}</td>
        </tr>
        <tr>
          <td>{t('maskEnergy')}</td>
          <td>{t('maskSpecialities')}</td>
        </tr>
        <tr>
          <td>{fields.maskEnergyRoof[maskEnergyRoof] || empty}</td>
          <td>
            {maskSpecialities
              .map((type) => fields.maskSpecialities[type])
              .join(', ') || empty}
          </td>
        </tr>
        <tr>
          <td>{t('maskConstructionPlans')}</td>
        </tr>
        <tr>
          <td>
            {maskConstructionPlans
              ? capitalizeFirstLetter(maskConstructionPlans)
              : empty}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
