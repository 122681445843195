import React, { FC, memo } from 'react';
import { StyledPin, StyledPinSelected, Text } from './styled';
import { Roofer } from '../../types/Roofer';
import { padNumber } from '@meindach/ui-kit';
import { Coordinates } from '../../types/Coordinates';
import { Marker } from 'react-map-gl';
import { scrollToCard } from '../rooferCard/RooferCard';

type Props = Pick<Roofer, 'id'> &
  Coordinates & {
    index?: number;
    hovered: boolean;
    desktop: boolean;
    setId: (id: string | null) => void;
    setHoverId: (id: string | null) => void;
  };

export const RooferMarker: FC<Props> = memo(
  ({ lat, lon, id, index, hovered, setId, setHoverId, desktop }) => {
    const onClick = desktop ? () => setId(id) : () => setHoverId(id);
    const highlight = desktop
      ? () => {
          setHoverId(id);
          if (index !== undefined) {
            scrollToCard(index);
          }
        }
      : undefined;
    return (
      <Marker longitude={lon} latitude={lat}>
        {hovered ? (
          <StyledPinSelected onClick={onClick} />
        ) : (
          <StyledPin
            onClick={onClick}
            onMouseEnter={highlight}
            onTouchStart={highlight}
          />
        )}
        {desktop && index !== undefined && (
          <Text selected={hovered}>{padNumber(index + 1)}</Text>
        )}
      </Marker>
    );
  },
);
