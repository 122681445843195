import { useEffect } from 'react';
import { Store } from '../types/Store';
import { createStore, useStore } from 'react-hookstore';

interface Props {
  id: string;
  onClose: () => void;
}

const store = createStore<Props | null>(Store.Panel, null);

export const useUniquePanel = (id: Props['id'], onClose: Props['onClose']) => {
  const [state, setState] = useStore<Props | null>(Store.Panel);

  // Keep only one panel open at a time by closing the previous one when a new one is created
  useEffect(() => {
    if (state && state.id !== id) {
      state.onClose();
    }
    setState({ id, onClose });
    return () => {
      const state = store.getState();
      if (state && state.id === id) {
        setState(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
