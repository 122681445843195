import React, { FC, memo } from 'react';
import { Coordinates } from '../../types/Coordinates';
import { Marker } from 'react-map-gl';
import { scrollToCard } from '../opportunityCard/OpportunityCard';
import { Opportunity } from '../../types/Opportunity';
import { OpportunityPin } from './OpportunityPin';

type Props = Pick<Opportunity, 'id' | 'salesStage' | 'roofType'> &
  Coordinates & {
    hovered: boolean;
    desktop: boolean;
    setId: (id: string | null) => void;
    setHoverId: (id: string | null) => void;
  };

export const OpportunityMarker: FC<Props> = memo(
  ({ lat, lon, id, hovered, setId, setHoverId, desktop, ...rest }) => {
    const onClick = desktop ? () => setId(id) : () => setHoverId(id);
    const highlight = desktop
      ? () => {
          setHoverId(id);
          scrollToCard(id);
        }
      : undefined;
    return (
      <Marker longitude={lon} latitude={lat}>
        <OpportunityPin
          hovered={hovered}
          onClick={onClick}
          onMouseEnter={hovered ? undefined : highlight}
          onTouchStart={hovered ? undefined : highlight}
          {...rest}
        />
      </Marker>
    );
  },
);
