import styled from '../../styled-components';
import { Modal, Title } from '@meindach/ui-kit';

export const StyledModal = styled(Modal)`
  padding: ${({ theme }) => theme.spacing.m};
  max-width: 410px;
  margin: 0 auto;
  text-align: center;

  ${Title} {
    margin: 0;
  }

  > a {
    display: block;
  }

  button {
    min-width: 230px;

    svg {
      margin-bottom: -5px;
    }

    &:last-child {
      margin-top: ${({ theme }) => theme.spacing.m};
    }
  }
`;
