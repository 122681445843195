import React from 'react';
import { Container, CardContainer } from './styled';
import { usePlatform } from '@meindach/ui-kit';
import { config } from '../../config';
import { Map } from '../map/Map';
import { Store } from '../../types/Store';
import { useId } from '../../hooks/useId';
import { ConsultantCard, scrollToCard } from '../consultantCard/ConsultantCard';
import { ConsultantMarker } from '../consultantMarker/ConsultantMarker';
import { useTranslation } from 'react-i18next';
import { useFilteredConsultants } from '../../hooks/useFilteredConsultants';
import { type, features } from '../../assets/map/4_niedrig.geojson.json';
import { usePositionAndZoom } from '../../hooks/usePositionAndZoom';

export const ConsultantMap = () => {
  const { t } = useTranslation();
  const { mobile } = usePlatform();
  const { id: hoverId, setId: setHoverId } = useId(Store.ConsultantHoverId);
  const { setId } = useId(Store.ConsultantSelectedId);
  const { consultants } = useFilteredConsultants();
  const mapState = usePositionAndZoom(consultants);
  const consultant = consultants.find(({ id }) => id === hoverId);
  const dataSource = {
    type,
    features:
      consultant && consultant.counties.length > 0
        ? features.filter(({ id }) => consultant.counties.includes(id))
        : [],
  };

  const onClick = mobile
    ? (id: string) => setHoverId(id)
    : (id: string) => setId(id);
  const onMouseEnter = mobile
    ? undefined
    : (id: string) => {
        setHoverId(id);
        scrollToCard(id);
      };

  return (
    <Container>
      {mobile && consultant && (
        <CardContainer onClick={() => setId(consultant.id)}>
          <ConsultantCard consultant={consultant} t={t} />
        </CardContainer>
      )}
      <Map
        mapboxApiAccessToken={config.mapBoxApiToken}
        dataSource={dataSource}
        {...mapState}
      >
        {consultants.map(({ id, firstName, lastName, latLon }) => (
          <ConsultantMarker
            key={id}
            id={id}
            hovered={id === hoverId}
            firstName={firstName}
            lastName={lastName}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            {...latLon}
          />
        ))}
      </Map>
    </Container>
  );
};
