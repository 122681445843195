import styled from '../../styled-components';
import { media, Label } from '@meindach/ui-kit';
import { ReactComponent as Icon } from '@meindach/ui-kit/dist/assets/icons/arrows-sort.svg';

export const SortIcon = styled(Icon)`
  width: 26px;
  height: 20px;
  &&& {
    color: ${({ theme }) => theme.color.black};
    margin: 0;
  }
  ${media.md`
    width: 20px;
  `}
`;

export const Container = styled.div`
  label {
    display: none;
    ${media.md`
      display: block;
    `}
  }
  > div {
    border-color: ${({ theme }) => theme.color.silver};
  }
  ${media.md`
    width: 100%;
    max-width: 120px;

    &:hover {
      ${SortIcon} {
        color: ${({ theme }) => theme.color.blue};
      }
    }
  `}
`;

export const StyledLabel = styled(Label)`
  margin: 0 ${({ theme }) => theme.spacing.s};
  display: none;
  ${media.md`
    display: block;
  `}
`;
