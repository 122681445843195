import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useId } from '../../hooks/useId';
import { Store } from '../../types/Store';
import { Route } from '../../types/Route';
import { InfoBar } from '../infoBar/InfoBar';
import { useRoofer } from '../../hooks/useRoofer';
import { config } from '../../config';

interface Props {
  id: string;
  className?: string;
}

export const ActiveRooferBar: FC<Props> = ({ id, className }) => {
  const { t } = useTranslation('rooferInfo');
  const { setId } = useId(Store.RooferSelectedId);
  const params = new URLSearchParams(window.location.search);
  const consultant = params.get('consultant');
  const roofer = useRoofer(id, consultant || undefined);
  if (!roofer) {
    return null;
  }
  const { name, imageHash } = roofer;
  return (
    <InfoBar
      title={t('title')}
      firstName={name}
      className={className}
      imageUrl={
        imageHash &&
        `${config.apiBaseUrl}/api/v1/public/images/roofers/${id}/${imageHash}/size?assetType=THUMBNAIL`
      }
      onClick={() => setId(id)}
      closeRoute={`${Route.Roofers}${consultant ? `#${consultant}` : ''}`}
    />
  );
};
