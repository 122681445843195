import React, { FC } from 'react';
import { useId } from '../../hooks/useId';
import { Store } from '../../types/Store';
import { OpportunityModal } from '../opportunityModal/OpportunityModal';
import { useOpportunities } from '../../hooks/useOpportunities';
import { usePlatform } from '@meindach/ui-kit';
import { OpportunityPanel } from '../opportunityPanel/OpportunityPanel';
import { useUser } from '../../hooks/useUser';

type Props = ReturnType<typeof useId>;

const Info: FC<Props> = ({ id, setId }) => {
  const { opportunities } = useOpportunities();
  const { mobile } = usePlatform();
  const { user } = useUser();
  if (!opportunities) {
    return null;
  }
  const opportunity = opportunities.find(
    (opportunity) => id === opportunity.id,
  );
  if (!opportunity) {
    return null;
  }
  const Component = mobile ? OpportunityModal : OpportunityPanel;
  return (
    <Component
      opportunity={opportunity}
      onClose={() => setId(null)}
      userId={user!.id}
    />
  );
};

export const SelectedOpportunityInfo = () => {
  const data = useId(Store.OpportunitySelectedId);
  if (!data.id) {
    return null;
  }
  return <Info {...data} />;
};
