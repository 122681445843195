import React, { FC } from 'react';
import { Details, Entry, MapContainer, Counties } from './styled';
import { P, Tag } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { ContactActions, ContactActionsProps } from './ContactActions';
import { Consultant } from '../../types/Consultant';
import { UserType } from '../../types/UserType';
import { type, features } from '../../assets/map/4_niedrig.geojson.json';
import { config } from '../../config';
import { Map } from '../../components/map/Map';
import { ConsultantMarker } from '../../components/consultantMarker/ConsultantMarker';
import { ReactComponent as LocationIcon } from '@meindach/ui-kit/dist/assets/icons/location.svg';

type Props = Pick<
  Consultant,
  | 'salesConsultantManager'
  | 'counties'
  | 'countyNames'
  | 'latLon'
  | 'firstName'
  | 'lastName'
> &
  ContactActionsProps & {
    directorView: boolean;
  };

export const InfoSection: FC<Props> = ({
  directorView,
  salesConsultantManager,
  counties,
  countyNames,
  latLon,
  firstName,
  lastName,
  ...rest
}) => {
  const { t } = useTranslation('consultantInfo');
  const dataSource = {
    type,
    features:
      counties.length > 0
        ? features.filter(({ id }) => counties.includes(id))
        : [],
  };
  return (
    <Details>
      {directorView && (
        <>
          <P>{t('common:info')}</P>
          <Entry>
            <P>{t(`userType:${UserType.Manager}`)}</P>
            <P>{salesConsultantManager}</P>
          </Entry>
        </>
      )}
      <P>{t('common:contactInfo')}</P>
      <ContactActions {...rest} />
      <P>{t('mapSection')}</P>
      <MapContainer>
        <Map
          mapboxApiAccessToken={config.mapBoxApiToken}
          dataSource={dataSource}
          latitude={latLon.lat}
          longitude={latLon.lon}
          interactive={false}
        >
          <ConsultantMarker
            hovered
            firstName={firstName}
            lastName={lastName}
            {...latLon}
          />
        </Map>
      </MapContainer>
      <Counties>
        <LocationIcon />
        <div>
          {countyNames.map((name) => (
            <Tag key={name} type="info">
              {name}
            </Tag>
          ))}
        </div>
      </Counties>
    </Details>
  );
};
