/**
 * Scroll direct child parent to have the child as close to the center of it as possible.
 * Note: The parent element must have style `position: relative;` to work correctly.
 */
export const scrollParentToChildPos = (
  child: HTMLElement | null,
  instant: boolean = false,
) => {
  if (!child || !child.parentElement) {
    return;
  }
  const parent: HTMLElement = child.parentElement;
  const top: number =
    child.offsetTop - parent.clientHeight * 0.5 + child.clientHeight * 0.5;

  if (instant || !parent.scrollTo) {
    parent.scrollTop = top;
  } else {
    parent.scrollTo({ top, behavior: 'smooth' });
  }
};
