import styled, { createGlobalStyle, css } from '../../styled-components';
import { PageContainer } from '../../layout/styled';
import { ReactComponent as FilterIcon } from '@meindach/ui-kit/dist/assets/icons/filter.svg';
import { ReactComponent as MapIcon } from '@meindach/ui-kit/dist/assets/icons/map.svg';
import { ReactComponent as ListIcon } from '@meindach/ui-kit/dist/assets/icons/card-list.svg';

export const Container = styled.nav`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;

  a {
    width: 100%;
  }
`;

export const PageOffset = createGlobalStyle`
  ${PageContainer} {
    padding-bottom: 52px;
  }
`;

export const DisabledLink = styled.a`
  pointer-events: none;
`;

export const ActiveFilterIcon = styled.circle`
  height: 12px;
  width: 12px;
  background-color: ${({ theme }) => theme.color.red};
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  bottom: 23px;
  left: 23px;
`;

export const IconContainer = styled.div`
  position: relative;
  width: 29px;
  height: 29px;
  margin-right: 8px;
`;

const iconStyle = css`
  flex-shrink: 0;
  width: 29px;
  height: 29px;
`;

export const StyledFilterIcon = styled(FilterIcon)`
  ${iconStyle}
`;

export const StyledMapIcon = styled(MapIcon)`
  ${iconStyle}
`;

export const StyledListIcon = styled(ListIcon)`
  ${iconStyle}
`;
