import styled from '../../styled-components';
import { Link } from 'react-router-dom';
import { P } from '@meindach/ui-kit';

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: ${({ theme }) => theme.spacing.xs} 0;
  &,
  > ${P} {
    color: ${({ theme }) => theme.color.blue};
    margin: 0;
    font-weight: 600;
  }
  > svg {
    width: auto;
    height: 24px;
    &:first-of-type {
      margin-right: ${({ theme }) => theme.spacing.s};
    }
    &:last-of-type {
      height: 13px;
      margin-left: ${({ theme }) => theme.spacing.s};
    }
  }
`;
