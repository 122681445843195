import styled, { css } from '../../styled-components';
import { touchSupport } from '../../utils/touchSupport';
import { hexToRGBA } from '../../utils/hexToRGBA';

interface Props {
  /**
   * @default false
   */
  selected?: boolean;
  disabled?: boolean;
  highlighted?: boolean;
}

export const Container = styled.div<Props>`
  transition: padding 0.35s;
  padding: ${({ theme }) => theme.spacing.xs};
  height: 112px;
  box-sizing: border-box;
`;

export const InnerContainer = styled.div<Props>`
  transition: box-shadow 0.35s, transform 0.35s, border 0.35s;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.color.mercury};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 2px 5px 0 ${({ theme }) => hexToRGBA(theme.color.black, 0.07)};
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xs};
  display: flex;
  ${({ selected, disabled }) =>
    selected
      ? css`
          border: 1px solid ${({ theme }) => theme.color.blue200};
          /* Hardcoded color follows just for one-time use, we might later port it in the theme if we find a case */
          background: #f7feff;
        `
      : !touchSupport &&
        !disabled &&
        css`
          &:hover {
            ${hoveredStyle};
          }
        `};
  ${({ highlighted, selected, disabled }) =>
    highlighted && !selected && !disabled && !touchSupport && highlightedStyle}
  ${({ highlighted }) =>
    highlighted &&
    css`
      transform: translateY(-3px);
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      filter: grayscale(100);
      background: ${({ theme }) => hexToRGBA(theme.color.white, 0.8)};
      border: 1px solid ${({ theme }) => hexToRGBA(theme.color.silver, 0.3)};
      cursor: default;
      box-shadow: none;
      opacity: 0.8;
    `}
`;

const hoveredStyle = css`
  box-shadow: 0 4px 10px -1px ${({ theme }) => hexToRGBA(theme.color.silver, 0.6)};
  border: 1px solid ${({ theme }) => hexToRGBA(theme.color.blue, 0.5)};
  cursor: pointer;
  transform: translateY(-3px);
`;

const highlightedStyle = css`
  box-shadow: 0 4px 10px -1px ${({ theme }) => hexToRGBA(theme.color.silver, 0.6)};
  border: 1px solid ${({ theme }) => hexToRGBA(theme.color.blue, 0.5)};
  cursor: pointer;
  &:hover {
    background: #fafeff;
    transform: translateY(-2px);
    box-shadow: 0 3px 10px -1px ${({ theme }) => hexToRGBA(theme.color.silver, 0.5)};
  }
`;
