import React from 'react';
import { usePlatform } from '@meindach/ui-kit';
import { Container } from './styled';
import { ConsultantList } from '../../components/consultantList/ConsultantList';
import { useUser } from '../../hooks/useUser';
import { UserType } from '../../types/UserType';
import { Route, Redirect, Switch } from 'react-router';
import { Route as RouteType } from '../../types/Route';
import { ConsultantMap } from '../../components/consultantMap/ConsultantMap';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { MobileNavigation } from '../../components/mobileNavigation/MobileNavigation';
import { DesktopConsultantFilters } from '../../components/consultantFilters/DesktopConsultantFilters';
import { PageHeader } from '../../components/pageHeader/PageHeader';
import { MobileConsultantFilters } from '../../components/consultantFilters/MobileConsultantFilters';
import { PanelContainer } from '../../components/panel/PanelContainer';
import { AllConsultantList } from '../../components/consultantList/AllConsultantList';
import { useConsultantFilters } from '../../hooks/useConsultantFilters';

export const Consultants = () => {
  const { desktop } = usePlatform();
  const { user } = useUser();
  const { active } = useConsultantFilters();
  if (!user) {
    return <PageLoading />;
  }
  if (user.type === UserType.Consultant) {
    return (
      <>
        {desktop && <PageHeader />}
        <Container>
          <AllConsultantList />
          <PanelContainer />
        </Container>
      </>
    );
  }
  const isDirector = user.type === UserType.Director;
  if (desktop) {
    return (
      <Switch>
        <Route
          exact
          path={RouteType.Consultants}
          render={() => (
            <>
              <PageHeader>
                {isDirector && <DesktopConsultantFilters />}
              </PageHeader>
              <Container>
                <ConsultantList />
                <PanelContainer>
                  <ConsultantMap />
                </PanelContainer>
              </Container>
            </>
          )}
        />
        <Redirect to={RouteType.Consultants} />
      </Switch>
    );
  }
  return (
    <>
      <Container>
        <Switch>
          <Route
            exact
            path={RouteType.Consultants}
            component={ConsultantList}
          />
          <Route
            exact
            path={RouteType.ConsultantMap}
            component={ConsultantMap}
          />
          <Route
            exact
            path={RouteType.ConsultantFilters}
            component={MobileConsultantFilters}
          />
          <Redirect to={RouteType.Consultants} />
        </Switch>
      </Container>
      <MobileNavigation
        tabs={[
          { route: RouteType.Consultants, label: 'list' },
          { route: RouteType.ConsultantMap, label: 'map' },
          { route: RouteType.ConsultantFilters, label: 'filters', active },
        ]}
      />
    </>
  );
};
