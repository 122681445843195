import styled, { css } from '../../styled-components';
import { media } from '../../styles/media';

const placeholderStyle = css`
  color: ${({ theme }) => theme.color.silver};
`;

export const Input = styled.input<{ error?: boolean }>`
  width: 100%;
  height: 44px;
  padding: 0 ${({ theme }) => theme.spacing.s};
  border: 1px solid ${({ theme }) => theme.color.silver};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.white};
  box-sizing: border-box;

  color: ${({ theme }) => theme.color.midnight};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 16px;
  letter-spacing: 0.4px;
  appearance: none;
  outline: none;

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${media.md`
    font-size: 20px;
  `}

  :focus,
  :hover {
    border-color: ${({ theme }) => theme.color.blue};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.color.error};
      background: ${({ theme }) => theme.color.white};
      :focus,
      :hover {
        border-color: ${({ theme }) => theme.color.error};
      }
    `}

  /* The placeholder styles cannot be combined because otherwise the browsers dont recognize them */
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    ${placeholderStyle}
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    ${placeholderStyle}
  }
  ::placeholder {
    ${placeholderStyle}
  }
  &[placeholder] {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
