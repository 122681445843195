import React, {
  FC,
  ChangeEvent,
  InputHTMLAttributes,
  useState,
  memo,
  ReactElement,
} from 'react';
import { Input } from '../input/Input';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { Container, StyledCloseIcon } from './styled';

type InputProps = InputHTMLAttributes<HTMLInputElement>;
type Props = Omit<InputProps, 'onChange' | 'type'> & {
  onChange: (value: string) => void;
  className?: string;
  icon?: ReactElement;
};

export const Search: FC<Props> = memo(
  ({
    value: controlledValue = '',
    onChange,
    className,
    icon = <SearchIcon />,
    ...props
  }) => {
    const [value, setValue] = useState<string>(`${controlledValue}`);
    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { value } = target;
      onChange(value);
      setValue(value);
    };
    const onClear = () => {
      onChange('');
      setValue('');
    };
    return (
      <Container className={className}>
        <Input value={value} onChange={handleChange} {...props} />
        {value.length < 1 ? icon : <StyledCloseIcon onClick={onClear} />}
      </Container>
    );
  },
);
