import { UserType } from '../types/UserType';
import { useUser } from './useUser';
import { useMemo } from 'react';
import { Consultant } from '../types/Consultant';

export const useConsultants = () => {
  const { user } = useUser();
  return useMemo((): Consultant[] => {
    if (!user) {
      return [];
    }
    return user.type === UserType.Director
      ? user.managedSalesManagers.flatMap(
          (manager) => manager.managedSalesConsultants,
        )
      : user.managedSalesConsultants;
  }, [user]);
};
