import styled, { css, keyframes } from '../../styled-components';
import { hexToRGBA } from '../../utils/hexToRGBA';

export interface ContainerProps {
  /**
   * @default 'default'
   */
  type?: 'default' | 'danger' | 'warning' | 'success';
}

const popIn = keyframes`
0% {
  opacity: 0;
  transform: scale(0.5);
}
65% {
  opacity: 1;
  transform: scale(1.1);
}
100% {
  opacity: 1;
  transform: scale(1);
}
`;

export const Container = styled.div<ContainerProps>`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m}
    ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.xs};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 22px;
  text-align: left;
  color: ${({ theme }) => theme.color.midnight};
  box-sizing: border-box;

  animation: ${popIn} 0.3s;
  display: inline-flex;
  width: 100%;

  > svg {
    max-height: 18px;
    max-width: 18px;
    margin: auto ${({ theme }) => theme.spacing.s};
    flex-shrink: 0;
  }

  ${({ type }) => {
    switch (type) {
      case 'success':
        return css`
          border: 1px solid ${({ theme }) => theme.color.success};
          box-shadow: 0 0 12px 0
            ${({ theme }) => hexToRGBA(theme.color.success, 0.5)};
          svg {
            color: ${({ theme }) => theme.color.success};
          }
        `;
      case 'danger':
        return css`
          border: 1px solid ${({ theme }) => theme.color.error};
          box-shadow: 0 0 12px 0
            ${({ theme }) => hexToRGBA(theme.color.error, 0.3)};
          svg {
            color: ${({ theme }) => theme.color.error};
          }
        `;
      case 'warning':
        return css`
          border: 1px solid ${({ theme }) => theme.color.alert};
          box-shadow: 0 0 12px 0
            ${({ theme }) => hexToRGBA(theme.color.alert, 0.3)};
          svg {
            color: ${({ theme }) => theme.color.alert};
          }
        `;
    }
    return css`
      border: 1px solid ${({ theme }) => theme.color.blue};
      box-shadow: 0 0 12px 0 ${({ theme }) => hexToRGBA(theme.color.blue, 0.3)};
      svg {
        color: ${({ theme }) => theme.color.blue};
      }
    `;
  }}
`;
