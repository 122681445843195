import styled from '../../styled-components';
import { Title, media } from '@meindach/ui-kit';

export const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;

  padding: ${({ theme }) => theme.spacing.m};
  ${media.md`
    padding: ${({ theme }) => theme.spacing.l};
  `}
`;

export const Content = styled.div`
  max-width: 514px;
  box-sizing: border-box;
  position: relative;

  > ${Title} {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing.m};

    ${media.md`
      margin-bottom: ${({ theme }) => theme.spacing.l};
    `}
  }
`;

export const IllustrationContainer = styled.div`
  position: absolute;

  right: -50px;
  > svg {
    width: 250px;
    height: 250px;
  }

  ${media.md`
    top: 170px;
    right: -60px;
    transform: translateX(100%);

    > svg {
      width: auto;
      height: auto;
    }
  `}
`;
