import React, { memo } from 'react';
import { createGlobalStyle } from '../styled-components';
import { Normalize } from 'styled-normalize';

const DocumentStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }
  #root {
    box-sizing: border-box;
    min-height: 100%;
    display: flex;
  }
  body {
    padding: 0;
    width: 100%;
    font-size: 10px;
    font-family: ${({ theme }) => theme.fontFamily.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    text-decoration: none;
  }
`;

export const GlobalStyle = memo(() => (
  <>
    <style>
      @import
      url('https://fonts.googleapis.com/css?family=Assistant:300,400,600,700|Roboto');
    </style>
    <Normalize />
    <DocumentStyle />
  </>
));
