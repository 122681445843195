import { Sort } from '../types/Sort';

interface Named {
  name: string;
}

interface SortMethod<T> {
  (a: T, b: T): -1 | 1 | 0;
}

export const sortByName = <T extends Named>(sort: Sort, list: T[]) => {
  switch (sort) {
    case Sort.AlphabeticalAscending:
      return [...list].sort(sortAlphabeticalAscending);
    case Sort.AlphabeticalDescending:
      return [...list].sort(sortAlphabeticalDescending);
  }
  return list;
};

export const sortAlphabeticalAscending: SortMethod<Named> = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

const sortAlphabeticalDescending: SortMethod<Named> = (a, b) => {
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};
