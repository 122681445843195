import { useState } from 'react';
import { grantQuote } from '../api/grantQuote';
import { declineQuote } from '../api/declineQuote';
import { useOpportunities } from './useOpportunities';

interface State {
  loading?: boolean;
  error?: boolean;
  response?: any;
}

export const useQuoteReview = () => {
  const { refreshData } = useOpportunities();
  const [
    { loading = false, response, error = false },
    setState,
  ] = useState<State>({});

  const review = async (opportunityId: string, approve: boolean) => {
    setState({ loading: true });
    try {
      const request = approve ? grantQuote : declineQuote;
      const data = await request(opportunityId);
      const response = await data.json();
      await refreshData();
      setState({ response });
    } catch {
      setState({ error: true });
    }
  };

  return { loading, response, error, review };
};
