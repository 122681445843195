import React, { memo } from 'react';
import {
  Container,
  TitleContainer,
  StyledTitle,
  LogoContainer,
  PageOffset,
} from './styled';
import { ReactComponent as Logo } from '@meindach/ui-kit/dist/assets/logo/logo_empty_bw.svg';
import { useTranslation } from 'react-i18next';
import { UserInfo } from '../userInfo/UserInfo';
import { usePlatform } from '@meindach/ui-kit';
import { Menu } from '../menu/Menu';
import { PageTitle } from '../pageTitle/PageTitle';
import { Route } from '../../types/Route';

const PageOffsetMemo = memo(() => <PageOffset />);

export const Header = memo(() => {
  const { t } = useTranslation('pageTitle');
  const { desktop } = usePlatform();
  return (
    <Container>
      <PageOffsetMemo />
      <LogoContainer to={Route.Root}>
        <Logo />
      </LogoContainer>
      <TitleContainer>
        <StyledTitle s ellipsis>
          {desktop ? t('default') : <PageTitle />}
        </StyledTitle>
      </TitleContainer>
      {desktop ? <UserInfo /> : <Menu />}
    </Container>
  );
});
