import React, { FC } from 'react';
import { Actions } from './styled';
import { LinkAction, LinkActionProps } from '../linkAction/LinkAction';

interface Props {
  actions: LinkActionProps[];
}

export const PanelActions: FC<Props> = ({ actions }) => (
  <Actions>
    {actions.map(({ text, link, icon, onClick }) => (
      <LinkAction
        key={text as string}
        link={link}
        text={text}
        icon={icon}
        onClick={onClick}
      />
    ))}
  </Actions>
);
