import styled, { css } from '../../styled-components';
import { hexToRGBA, touchSupport } from '../../utils';

export const Text = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > svg {
    max-height: 23px;
    max-width: 23px;
    margin: 0 ${({ theme }) => theme.spacing.s} -${({ theme }) =>
        theme.spacing.xs} 0;
    flex-shrink: 0;
  }
`;

export interface ButtonProps {
  fullWidth?: boolean;
  disabled?: boolean;
  empty?: boolean;
  success?: boolean;
  small?: boolean;
  extraSmall?: boolean;
  confirmed?: boolean;
  emptyDanger?: boolean;
}

export const Button = styled.button<ButtonProps>`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  height: 56px;
  border: none;
  border-radius: 2px;
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.red};
  padding: 0 ${({ theme }) => theme.spacing.m};
  box-sizing: border-box;

  ${({ small }) =>
    small &&
    css`
      height: 44px;
    `}

  ${({ extraSmall }) =>
    extraSmall &&
    css`
      height: 28px;
      ${Text} {
        font-size: 14px;
      }
    `}

  ${!touchSupport &&
  css`
    &:hover {
      opacity: 0.8;
    }
  `}

  ${({ fullWidth, extraSmall }) =>
    fullWidth
      ? css`
          width: 100%;
        `
      : css`
          min-width: ${extraSmall ? 85 : 158}px;
        `}

  ${({ empty }) =>
    empty &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color.blue};
      color: ${({ theme }) => theme.color.blue};
    `}

  ${({ success }) =>
    success &&
    css`
      background-color: ${({ theme }) => theme.color.success};
    `}

  ${({ confirmed }) =>
    confirmed &&
    css`
      background-color: ${({ theme }) => hexToRGBA(theme.color.success, 0.1)};
      color: ${({ theme }) => theme.color.success};
      pointer-events: none;
    `}

  ${({ emptyDanger }) =>
    emptyDanger &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color.red};
      color: ${({ theme }) => theme.color.red};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) => theme.color.mercury};
      color: ${({ theme }) => theme.color.silver};
      border-color: transparent;
    `}
`;
