import React, { FC } from 'react';
import { StyledSelect } from './styled';
import { Option, SelectProps } from '@meindach/ui-kit';
import { trackEvent } from '../../util/trackEvent';
import { useOpportunityFilters } from '../../hooks/useOpportunityFilters';
import { RoofType } from '../../types/RoofType';
import { useFieldTranslations } from '../../hooks/useFieldTranslations';
import { translatedFieldNames } from '../../data/translatedFieldNames';

const Select = StyledSelect as FC<SelectProps<RoofType>>;
const valueList: RoofType[] = [
  'flat_roof',
  'pitched_roof',
  'winkelbungalow',
  'other_roof',
  'half_hipped_roof',
  'hipped_roof',
  'saddle_roof',
];

interface Props {
  name?: string;
  placeHolder?: string;
}

export const RoofTypeFilter: FC<Props> = ({ name, placeHolder }) => {
  const { fields } = useFieldTranslations(translatedFieldNames);
  const { roofTypes, setRoofTypes } = useOpportunityFilters();
  const options: Option<RoofType>[] = fields
    ? valueList.map((value) => ({
        value,
        label: fields.roofType[value],
      }))
    : [];
  return (
    <Select
      name={name}
      options={options}
      placeHolder={placeHolder}
      onChange={setRoofTypes}
      selectedValues={roofTypes}
      onBlur={() =>
        trackEvent(
          'filterOpportunities',
          'roofTypes',
          JSON.stringify(roofTypes),
        )
      }
      multiple
    />
  );
};
