import React, { FC } from 'react';
import { StyledStartDateSelect } from './styled';
import { Option, SelectProps } from '@meindach/ui-kit';
import { trackEvent } from '../../util/trackEvent';
import { useOpportunityFilters } from '../../hooks/useOpportunityFilters';
import { StartDate } from '../../types/StartDate';
import { useFieldTranslations } from '../../hooks/useFieldTranslations';
import { translatedFieldNames } from '../../data/translatedFieldNames';

const Select = StyledStartDateSelect as FC<SelectProps<StartDate>>;
const valueList: StartDate[] = [
  'in_2_to_4_weeks',
  'after_3_months',
  'in_1_to_2_months',
  'in_1_to_3_months',
  'in_2_to_4_months',
  'in_4_to_6_months',
  '4_or_more_months',
];

interface Props {
  name?: string;
  placeHolder?: string;
}

export const StartDateFilter: FC<Props> = ({ name, placeHolder }) => {
  const { fields } = useFieldTranslations(translatedFieldNames);
  const { startDates, setStartDates } = useOpportunityFilters();
  const options: Option<StartDate>[] = fields
    ? valueList.map((value) => ({
        value,
        label: fields.startDate[value],
      }))
    : [];
  return (
    <Select
      name={name}
      options={options}
      placeHolder={placeHolder}
      onChange={setStartDates}
      selectedValues={startDates}
      onBlur={() =>
        trackEvent(
          'filterOpportunities',
          'startDates',
          JSON.stringify(startDates),
        )
      }
      multiple
    />
  );
};
