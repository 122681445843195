import React from 'react';
import { Container, CardContainer } from './styled';
import { useFilteredRoofers } from '../../hooks/useFilteredRoofers';
import { TestAttribute } from '../../types/TestAttribute';
import { RooferMarker } from '../rooferMarker/RooferMarker';
import { RooferCard } from '../rooferCard/RooferCard';
import { useRoofer } from '../../hooks/useRoofer';
import { usePlatform } from '@meindach/ui-kit';
import { useSort } from '../../hooks/useSort';
import { sortByName } from '../../util/sortByName';
import { config } from '../../config';
import { Map } from '../map/Map';
import { useTranslation } from 'react-i18next';
import { Store } from '../../types/Store';
import { useId } from '../../hooks/useId';
import { usePositionAndZoom } from '../../hooks/usePositionAndZoom';

export const RooferMap = () => {
  const { t } = useTranslation();
  const { roofers } = useFilteredRoofers();
  const { desktop } = usePlatform();
  const { id: hoverId, setId: setHoverId } = useId(Store.RooferHoverId);
  const { setId } = useId(Store.RooferSelectedId);
  const { sort } = useSort(Store.RooferSort);
  const roofer = useRoofer(hoverId);
  const mapState = usePositionAndZoom(roofers);

  return (
    <Container data-test-id={TestAttribute.Map}>
      {!desktop && roofer && (
        <CardContainer>
          <RooferCard roofer={roofer} t={t} mobile={!desktop} setId={setId} />
        </CardContainer>
      )}
      <Map mapboxApiAccessToken={config.mapBoxApiToken} {...mapState}>
        {sortByName(sort, roofers).map(({ id, latLon }, index) => (
          <RooferMarker
            key={id}
            id={id}
            hovered={id === hoverId}
            {...latLon}
            index={index}
            setId={setId}
            setHoverId={setHoverId}
            desktop={desktop}
          />
        ))}
      </Map>
    </Container>
  );
};
