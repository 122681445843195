import { TestAttribute } from '../../types/TestAttribute';
import styled, { createGlobalStyle } from '../../styled-components';
import { media, Avatar, P } from '@meindach/ui-kit';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  height: 51px;
  padding: 0 0 0 ${({ theme }) => theme.spacing.s};
  background-color: ${({ theme }) => theme.color.white};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.mercury};
  border-top: 0;
  overflow: hidden;

  ${media.md`
    height: 44px;
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.color.silver};
  `}
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  overflow: hidden;
`;

export const StyledAvatar = styled(Avatar)`
  width: 33px;
  height: 33px;
  font-size: 16px;
  margin-right: ${({ theme }) => theme.spacing.s};
  flex-shrink: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  overflow: hidden;

  > ${P} {
    font-size: 14px;
    margin: 0;
    margin-bottom: -12px;
  }

  > div {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;

    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > svg {
      color: ${({ theme }) => theme.color.blue};
      margin-top: 2px;
      padding: ${({ theme }) => theme.spacing.s};
      width: 16px;
      height: 16px;
    }
  }
`;

export const CloseLink = styled(Link)`
  width: 48px;
  ${media.md`
    width: 55px;
  `}
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  > svg {
    cursor: pointer;
    color: ${({ theme }) => theme.color.red};
    padding: 8px 10px;
    border: 1px solid ${({ theme }) => theme.color.mercury};
    border-radius: 2px;
  }
`;

export const MapOffset = createGlobalStyle`
  [data-test-id=${TestAttribute.Map}] {
    && {
      height: calc(100% - 52px);
      ${media.md`
        height: 100%;
      `}
    }
  }
`;
