import React, { FC, lazy, Suspense, useEffect } from 'react';
import { Button, ModalProps, P, Title } from '@meindach/ui-kit';
import { Header, ReviewButtonContainer, StyledModal } from './styled';
import { ReactComponent as CloseIcon } from '@meindach/ui-kit/dist/assets/ui/close.svg';
import { ReactComponent as Loading } from '@meindach/ui-kit/dist/assets/loading/loading.svg';
import { TestAttribute } from '../../types/TestAttribute';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../util/trackEvent';
import { useOpportunityFile } from '../../hooks/useOpportunityFile';
import { FileType } from '../../types/FileType';
import { useQuoteReview } from '../../hooks/useQuoteReview';

interface Props extends ModalProps {
  id: string;
  type: FileType;
  owner?: boolean;
}

const LazyPdfViewer = lazy(() => import('./PdfViewer'));

export const FileModal: FC<Props> = ({ id, type, owner, onClose }) => {
  const { t } = useTranslation('pdf');
  const { file, error } = useOpportunityFile(id, type);
  const { loading, review, error: reviewError, response } = useQuoteReview();

  useEffect(() => {
    trackEvent('opportunity', type, id);
  }, [type, id]);

  const handleClose = loading ? undefined : onClose;
  return (
    <StyledModal onClose={handleClose}>
      <Header>
        <Title m>{t(type)}</Title>
        <div>
          <CloseIcon
            onClick={handleClose}
            data-test-id={TestAttribute.ModalClose}
          />
        </div>
      </Header>
      {error && <P>{t('loadError')}</P>}
      {!file && !error && <Loading />}
      <Suspense fallback={<Loading />}>
        {file && <LazyPdfViewer file={`data:application/pdf;base64,${file}`} />}
      </Suspense>
      {response && <P>{t('quoteReviewSuccess')}</P>}
      {!response && file && owner && (
        <div>
          <P>{t('quotePdfGrantInfo')}</P>
          {loading && <Loading />}
          {reviewError && <P>{t('quoteReviewError')}</P>}
          <ReviewButtonContainer>
            <Button onClick={() => review(id, false)} disabled={loading}>
              {t('decline')}
            </Button>
            <Button onClick={() => review(id, true)} disabled={loading} success>
              {t('grant')}
            </Button>
          </ReviewButtonContainer>
        </div>
      )}
    </StyledModal>
  );
};
