import { useState, useEffect } from 'react';
import { useConsultantFilters } from './useConsultantFilters';
import { Consultant } from '../types/Consultant';
import { useAllConsultants } from './useAllConsultants';

export const useFilteredAllConsultants = () => {
  const { consultants: allConsultants, fetching } = useAllConsultants();
  const { search } = useConsultantFilters();
  const [consultants, setConsultants] = useState<Consultant[]>([]);

  useEffect(() => {
    if (allConsultants.length < 1) {
      return;
    }
    setConsultants(
      allConsultants.filter(
        ({ fullName, postalCode }) =>
          fullName.toLowerCase().includes(search.toLowerCase()) ||
          postalCode.includes(search),
      ),
    );
  }, [allConsultants, search]);

  return { consultants, fetching };
};
