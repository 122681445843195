import React, { useCallback, useEffect } from 'react';
import { Container, SortContainer } from './styled';
import { useTranslation } from 'react-i18next';
import { useFilteredOpportunities } from '../../hooks/useFilteredOpportunities';
import { NoOpportunities } from './NoOpportunities';
import { Option, usePlatform } from '@meindach/ui-kit';
import { useSort } from '../../hooks/useSort';
import { LoadingOpportunities } from './LoadingOpportunities';
import { ListSort } from '../listSort/ListSort';
import { trackEvent } from '../../util/trackEvent';
import { Sort } from '../../types/Sort';
import { Store } from '../../types/Store';
import { useId } from '../../hooks/useId';
import { OpportunityCard } from '../opportunityCard/OpportunityCard';
import { OpportunitySearch } from '../opportunityFilters/OpportunitySearch';
import { sortByDate } from '../../util/sortByDate';

const options: Option<Sort>[] = [
  { value: Sort.Newest },
  { value: Sort.Oldest },
];

export const OpportunityList = () => {
  const { t } = useTranslation();
  const { sort, setSort } = useSort(Store.OpportunitySort);
  const { mobile } = usePlatform();
  const { opportunities, fetching } = useFilteredOpportunities();
  const { id, setId } = useId(Store.OpportunitySelectedId);
  const { id: hoverId, setId: setHoverId } = useId(Store.OpportunityHoverId);
  const sortChange = useCallback(
    (sort: Sort) => {
      trackEvent('filterOpportunities', 'sort', sort);
      setSort(sort);
    },
    [setSort],
  );
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    if (id) {
      setId(id);
    }
  }, [setId]);

  return (
    <Container>
      <SortContainer>
        <OpportunitySearch />
        <ListSort sort={sort} setSort={sortChange} options={options} />
      </SortContainer>
      {fetching && <LoadingOpportunities />}
      {!fetching && opportunities.length < 1 && <NoOpportunities />}
      {!fetching &&
        sortByDate(sort, opportunities).map((opportunity) => (
          <OpportunityCard
            key={opportunity.id}
            opportunity={opportunity}
            setId={setId}
            setHoverId={setHoverId}
            selected={opportunity.id === id}
            highlighted={opportunity.id === hoverId}
            mobile={mobile}
            t={t}
          />
        ))}
    </Container>
  );
};
