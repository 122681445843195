import React from 'react';
import { StyledModal } from './styled';
import { Title, Button, P } from '@meindach/ui-kit';
import { useTranslation, Trans } from 'react-i18next';
import { trackEvent } from '../../util/trackEvent';
import { ReactComponent as MailIcon } from '@meindach/ui-kit/dist/assets/icons/mail.svg';
import { useUser } from '../../hooks/useUser';

export const ApiErrorModal = () => {
  const { t } = useTranslation('apiError');
  const { error } = useUser();
  if (!error) {
    return null;
  }
  return (
    <StyledModal>
      <Title l>{t('title')}</Title>
      <P>
        <Trans i18nKey="apiError:text">
          Text
          <br />
          Text
          <br />
          Text
        </Trans>
      </P>
      <Button onClick={() => window.location.reload()}>{t('reload')}</Button>
      <a
        href="mailto:support@meindach.de"
        onClick={() => trackEvent('error', 'email')}
      >
        <Button empty>
          <MailIcon />
          {t('contact')}
        </Button>
      </a>
    </StyledModal>
  );
};
