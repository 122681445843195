import { fromJS } from 'immutable';
import MAP_STYLE from '../../assets/map/map-style-light-v10.json';

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
const dataLayers = [
  {
    id: 'areas',
    source: 'dataSource',
    type: 'fill',
    interactive: false,
    paint: {
      'fill-color': '#c2faff',
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'borders',
    source: 'dataSource',
    type: 'line',
    interactive: false,
    paint: {
      'line-color': '#00E1FF',
      'line-width': 2,
    },
  },
];

const defaultMapStyle = fromJS(MAP_STYLE);

export const mapStyle = defaultMapStyle.set(
  'layers',
  defaultMapStyle.get('layers').push(...dataLayers),
);
