import { config } from '../config';
import { getHeaders } from './getHeaders';
import { getEmailOverrideParams } from './getEmailOverrideParams';

export const grantQuote = (opportunityId: string) =>
  fetch(
    `${
      config.apiBaseUrl
    }/api/v1/opportunities/${opportunityId}/quote/grant${getEmailOverrideParams()}`,
    {
      headers: getHeaders(),
    },
  );
