import React from 'react';
import { ReactComponent as Icon } from '@meindach/ui-kit/dist/assets/icons/roofer-none.svg';
import { ReactComponent as Loading } from '@meindach/ui-kit/dist/assets/loading/loading.svg';
import { LoadingRoofersContainer } from './styled';
import { Title, P } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';

export const LoadingRoofers = () => {
  const { t } = useTranslation('rooferList');
  return (
    <LoadingRoofersContainer>
      <Icon />
      <Title m>{t('loadingTitle')}</Title>
      <P>{t('loadingText')}</P>
      <Loading />
    </LoadingRoofersContainer>
  );
};
