import React, { FC, memo } from 'react';
import { Border, Container, Image } from './styled';

interface Props {
  src?: string;
  firstName?: string;
  lastName?: string;
  className?: string;
  small?: boolean;
  big?: boolean;
}

const getDisplayName = (
  firstName: Props['firstName'],
  lastName: Props['lastName'],
) =>
  `${firstName ? firstName.charAt(0).toUpperCase() : ''}${
    lastName ? lastName.charAt(0).toUpperCase() : ''
  }` || '?';

export const Avatar: FC<Props> = memo(
  ({ firstName, lastName, src, className, small, big }) => (
    <Container className={className} small={small} big={big}>
      <Border />
      {getDisplayName(firstName, lastName)}
      {src && <Image src={src} />}
    </Container>
  ),
);
