import React, { FC } from 'react';
import { Opportunity } from '../../types/Opportunity';
import { timestampToDate, P } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { Entry, Details, MapContainer, PassiveAction, Loading } from './styled';
import { OpportunityPin } from '../../components/opportunityMarker/OpportunityPin';
import { SatelliteMap } from '../../components/map/SatelliteMap';
import { ReactComponent as CalendarIcon } from '@meindach/ui-kit/dist/assets/icons/calendar.svg';
import { OpportunityContactActions } from '../../components/opportunityContactActions/OpportunityContactActions';
import { useFieldTranslations } from '../../hooks/useFieldTranslations';
import { translatedFieldNames } from '../../data/translatedFieldNames';

type Props = Opportunity;

export const OpportunityDetails: FC<Props> = ({
  id,
  dateEntered,
  material,
  startDate,
  roofType,
  reason,
  tileShapeType,
  windowsNumberTarget,
  latLon,
  salesStage,
  phone,
  street,
  postalCode,
  city,
  accountId,
  accountName,
  hideMap,
}) => {
  const { t } = useTranslation('opportunityInfo');
  const { fields } = useFieldTranslations(translatedFieldNames);
  if (!fields) {
    return <Loading />;
  }
  const empty = t('common:undefined');
  const Pin = (
    <OpportunityPin salesStage={salesStage} roofType={roofType} hovered />
  );
  return (
    <Details>
      {!hideMap && (
        <MapContainer>
          <SatelliteMap {...latLon} pinIcon={Pin} />
        </MapContainer>
      )}
      <OpportunityContactActions
        id={id}
        latLon={latLon}
        phone={phone}
        street={street}
        postalCode={postalCode}
        city={city}
        accountId={accountId}
        accountName={accountName}
      />
      <PassiveAction>
        <P>
          <CalendarIcon />
        </P>
        <P>{fields.startDate[startDate]}</P>
      </PassiveAction>
      <Entry>
        <P>{t('date')}</P>
        <P>{dateEntered ? timestampToDate(dateEntered) : empty}</P>
      </Entry>
      <Entry>
        <P>{t('common:roofType')}</P>
        <P>{fields.roofType[roofType] || empty}</P>
      </Entry>
      <Entry>
        <P>{t('restorationReason')}</P>
        <P>{fields.reason[reason] || empty}</P>
      </Entry>
      <Entry>
        <P>{t('material')}</P>
        <P>{fields.material[material] || empty}</P>
      </Entry>
      <Entry>
        <P>{t('recovering')}</P>
        <P>{fields.tileShapeType[tileShapeType] || empty}</P>
      </Entry>
      <Entry>
        <P>{t('windowCount')}</P>
        <P>{windowsNumberTarget || empty}</P>
      </Entry>
    </Details>
  );
};
