import { useStore, createStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { Opportunity } from '../types/Opportunity';

interface Filters {
  salesStages: Opportunity['salesStage'][];
  startDates: Opportunity['startDate'][];
  roofTypes: Opportunity['roofType'][];
  zipCode: string;
  search: string;
}

interface Setters {
  setSalesStages: (stages: Filters['salesStages']) => void;
  setStartDates: (dates: Filters['startDates']) => void;
  setRoofTypes: (types: Filters['roofTypes']) => void;
  setZipCode: (zipCode: Filters['zipCode']) => void;
  setSearch: (zipCode: Filters['search']) => void;
  clear: () => void;
}

type Output = Filters & Setters & { active: boolean };

export const initialState: Filters = {
  salesStages: [],
  startDates: [],
  roofTypes: [],
  zipCode: '',
  search: '',
};
export const store = createStore<Filters>(
  Store.OpportunityFilters,
  initialState,
);

export const useOpportunityFilters = (): Output => {
  const [filters, setFilters] = useStore<Filters>(Store.OpportunityFilters);
  return {
    active: Object.values(filters).some((filter) => filter.length > 0),
    ...filters,
    setSalesStages: (salesStages) => setFilters({ ...filters, salesStages }),
    setStartDates: (startDates) => setFilters({ ...filters, startDates }),
    setRoofTypes: (roofTypes) => setFilters({ ...filters, roofTypes }),
    setZipCode: (zipCode) => setFilters({ ...filters, zipCode }),
    setSearch: (search) => setFilters({ ...filters, search }),
    clear: () => setFilters(initialState),
  };
};
