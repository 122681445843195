import React, { FC, memo } from 'react';
import { Container, InnerContainer } from './styled';
import { TestAttribute } from '../../types/TestAttribute';

export interface CardProps {
  selected?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  onClick?: () => any;
  onMouseEnter?: () => any;
  onMouseLeave?: () => any;
  className?: string;
}

export const Card: FC<CardProps> = memo(
  ({ selected, disabled, highlighted, children, ...rest }) => (
    <Container
      data-test-id={TestAttribute.Card}
      highlighted={highlighted}
      selected={selected}
      disabled={disabled}
      {...rest}
    >
      <InnerContainer
        highlighted={highlighted}
        selected={selected}
        disabled={disabled}
      >
        {children}
      </InnerContainer>
    </Container>
  ),
);
