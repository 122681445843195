import styled from '../../styled-components';
import { Title, P, media } from '@meindach/ui-kit';

export const Container = styled.div`
  width: 100%;
  ${media.md`
    overflow: auto;
  `}
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.alabaster};
  padding: ${({ theme }) => theme.spacing.xs};
`;

export const NoRoofersContainer = styled.div`
  text-align: center;
  max-width: 400px;
  margin: 28% auto 4%;

  > svg {
    width: 42px;
    height: 42px;
  }

  > ${Title} {
    margin: ${({ theme }) => theme.spacing.m} 0;
  }
  > ${P} {
    margin: 0;
  }
`;

export const LoadingRoofersContainer = styled(NoRoofersContainer)`
  > svg:last-child {
    width: 160px;
    height: 20px;
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.xs};

  > :first-child {
    margin-right: ${({ theme }) => theme.spacing.s};
    width: 100%;
  }
`;
