import styled, { css } from '../../styled-components';
import { Select, Label, P, NumericSearch, media } from '@meindach/ui-kit';
import { OpportunityOwnerBar } from '../opportunityOwnerBar/OpportunityOwnerBar';

export const StyledNumericSearch = styled(NumericSearch)`
  ${media.md`
    width: 10%;
    max-width: 135px;
  `}
`;

export const StyledSelect = styled(Select)`
  ${media.md`
    margin-right: ${({ theme }) => theme.spacing.m};
    width: 25%;
    max-width: 230px;
  `}
`;

export const StyledStartDateSelect = styled(StyledSelect)`
  ${media.md`
    margin-right: ${({ theme }) => theme.spacing.m};
    width: 20%;
    max-width: 180px;
  `}
`;

export const StyledOwnerBar = styled(OpportunityOwnerBar)`
  max-width: 300px;
  margin-right: ${({ theme }) => theme.spacing.m};
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  padding: ${({ theme }) => theme.spacing.s};

  > ${Label} {
    font-size: 18px;
    ${({ theme }) => css`
      margin: ${theme.spacing.m} 0 ${theme.spacing.s};
    `}
  }
`;

export const Clear = styled(P)`
  color: ${({ theme }) => theme.color.blue};
  font-weight: 600;
  margin: ${({ theme }) => theme.spacing.l} auto
    ${({ theme }) => theme.spacing.m};
  padding: ${({ theme }) => theme.spacing.m};
`;
