import React, { FC } from 'react';
import { Roofer } from '../../types/Roofer';
import { trackEvent } from '../../util/trackEvent';
import { ReactComponent as MapIcon } from '@meindach/ui-kit/dist/assets/icons/google-maps.svg';
import { ReactComponent as PhoneIcon } from '@meindach/ui-kit/dist/assets/icons/phone.svg';
import { ReactComponent as MailIcon } from '@meindach/ui-kit/dist/assets/icons/mail.svg';
import { getGoogleMapsUrl } from '../../util/getGoogleMapsUrl';
import { ModalActions } from '../../components/modalActions/ModalActions';

type Props = Pick<
  Roofer,
  'city' | 'street' | 'latLon' | 'postalCode' | 'id' | 'phone' | 'email'
>;

export const RooferActions: FC<Props> = ({
  id,
  phone,
  email,
  street,
  latLon,
  postalCode,
  city,
}) => (
  <ModalActions>
    <a href={`tel:${phone}`} onClick={() => trackEvent('roofer', 'call', id)}>
      <PhoneIcon />
    </a>
    <a
      href={getGoogleMapsUrl(`${street}, ${postalCode} ${city}`, latLon)}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => trackEvent('roofer', 'openMap', id)}
    >
      <MapIcon />
    </a>
    <a
      href={`mailto:${email}`}
      onClick={() => trackEvent('roofer', 'email', id)}
    >
      <MailIcon />
    </a>
  </ModalActions>
);
