import React, { FC, useCallback, useEffect } from 'react';
import { trackEvent } from '../../util/trackEvent';
import { ReactComponent as MapIcon } from '@meindach/ui-kit/dist/assets/icons/google-maps.svg';
import { ReactComponent as DownloadIcon } from '@meindach/ui-kit/dist/assets/icons/download-file.svg';
import { ReactComponent as QuoteIcon } from '@meindach/ui-kit/dist/assets/icons/euro.svg';
import { Opportunity } from '../../types/Opportunity';
import { getGoogleMapsUrl } from '../../util/getGoogleMapsUrl';
import { ModalActions } from '../../components/modalActions/ModalActions';
import { DisabledLink } from './styled';
import { useSelectedFile } from '../../hooks/useSelectedFile';
import { FileType } from '../../types/FileType';
import { isLeadPdfAvailable } from '../../util/isLeadPdfAvailable';
import { isQuoteAvailable } from '../../util/isQuoteAvailable';
import { User } from '../../types/User';

type Props = Pick<
  Opportunity,
  | 'city'
  | 'street'
  | 'latLon'
  | 'postalCode'
  | 'id'
  | 'salesStage'
  | 'consultantProposalCheck'
> & {
  userId: User['id'];
  onClose: () => void;
};

export const OpportunityActions: FC<Props> = ({
  id,
  street,
  latLon,
  postalCode,
  city,
  salesStage,
  consultantProposalCheck,
  userId,
}) => {
  const { setFile } = useSelectedFile();

  const onLeadClick = () => setFile({ id, type: FileType.Lead });
  const onQuoteClick = useCallback(
    () =>
      setFile({
        id,
        type: FileType.Quote,
        owner: consultantProposalCheck === userId,
      }),
    [consultantProposalCheck, id, setFile, userId],
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const check = params.get('check');
    if (check) {
      onQuoteClick();
      // Clear url params so the next selected opportunity wouldnt open the quote
      const state = `${window.location.pathname}${window.location.hash}`;
      window.history.replaceState({}, state, state);
    }
  }, [id, onQuoteClick]);

  return (
    <ModalActions>
      <a
        href={getGoogleMapsUrl(`${street}, ${postalCode} ${city}`, latLon)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => trackEvent('opportunity', 'openMap', id)}
      >
        <MapIcon />
      </a>
      {isLeadPdfAvailable(salesStage) ? (
        <button onClick={onLeadClick}>
          <DownloadIcon />
        </button>
      ) : (
        <DisabledLink>
          <DownloadIcon />
        </DisabledLink>
      )}
      {isQuoteAvailable(salesStage) ? (
        <button onClick={onQuoteClick}>
          <QuoteIcon />
        </button>
      ) : (
        <DisabledLink>
          <QuoteIcon />
        </DisabledLink>
      )}
    </ModalActions>
  );
};
