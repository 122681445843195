import React, { FC } from 'react';
import { useId } from '../../hooks/useId';
import { Store } from '../../types/Store';
import { ManagerInfoModal } from '../managerInfoModal/ManagerInfoModal';
import { useUser } from '../../hooks/useUser';

type Props = ReturnType<typeof useId>;

const Modal: FC<Props> = ({ id, setId }) => {
  const { user } = useUser();
  if (!user) {
    return null;
  }
  const manager = user.managedSalesManagers.find(
    ({ fullName }) => fullName === id,
  );
  if (!manager) {
    return null;
  }
  return <ManagerInfoModal manager={manager} onClose={() => setId(null)} />;
};

export const SelectedManagerModal = () => {
  const data = useId(Store.ManagerSelectedId);
  if (!data.id) {
    return null;
  }
  return <Modal {...data} />;
};
