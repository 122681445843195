import styled from '../../styled-components';
import { Title, media } from '@meindach/ui-kit';

export const Container = styled.div`
  background: ${({ theme }) => theme.color.alabaster};
  min-height: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.m};

  > *:last-child {
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`;

export const HeaderSection = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing.m};

  > ${Title} {
    margin: 0 0 ${({ theme }) => theme.spacing.m};
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  > p:not(${Title}) {
    font-family: ${({ theme }) => theme.fontFamily.primary};
    color: ${({ theme }) => theme.color.red};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin: 0;

    > span {
      border-bottom: 2px solid ${({ theme }) => theme.color.red};
    }
  }
`;

export const Entry = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};

  > * {
    margin: ${({ theme }) => theme.spacing.xs} 0;
    color: ${({ theme }) => theme.color.slate};

    &:first-child {
      color: ${({ theme }) => theme.color.storm};
      margin: ${({ theme }) => theme.spacing.s} 0 0 0;

      ${media.md`
        width: 50%;
        min-width: 150px;
        margin: ${({ theme }) => theme.spacing.s} 0;
      `}
    }

    ${media.md`
      display: inline-block;
      margin: ${({ theme }) => theme.spacing.s} 0;
    `}
  }
`;

export const LoadingContainer = styled.div`
  > svg {
    width: 160px;
  }
`;
