import React, { FC, memo, SVGProps } from 'react';
import {
  FlatPinSelected,
  FlatPin,
  PitchedPinSelected,
  PitchedPin,
} from './styled';
import { getOpportunityStatus } from '../../util/getOpportunityStatus';
import { Opportunity } from '../../types/Opportunity';

type Props = Pick<Opportunity, 'salesStage' | 'roofType'> &
  Omit<SVGProps<SVGSVGElement>, 'ref'> & { hovered: boolean };

const getPin = (roofType: Opportunity['roofType'], hovered: boolean) => {
  if (roofType === 'flat_roof') {
    return hovered ? FlatPinSelected : FlatPin;
  }
  return hovered ? PitchedPinSelected : PitchedPin;
};

export const OpportunityPin: FC<Props> = memo(
  ({ salesStage, roofType, hovered, ...rest }) => {
    const status = getOpportunityStatus(salesStage);
    const Component = getPin(roofType, hovered);

    return <Component status={status} {...rest} />;
  },
);
