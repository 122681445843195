import styled, { css } from '../../../styled-components';
import { media } from '../../../styles/media';

export interface LabelProps {
  disabled?: boolean;
  ellipsis?: boolean;
  error?: boolean;
}

export const Label = styled.label<LabelProps>`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;

  ${media.md`
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.2px;
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.color.silver};
    `}
  
  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.color.error};
    `}

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;
