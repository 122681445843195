const stripPrefix = (phoneNumber: string) => {
  if (phoneNumber.startsWith('49')) {
    return phoneNumber.replace('49', '');
  }
  if (phoneNumber.startsWith('0')) {
    return phoneNumber.replace('0', '');
  }
  return phoneNumber;
};

const normalize = (phoneNumber: string) => phoneNumber.replace(/[^0-9]/g, '');

export const formatPhoneNumber = (
  phoneNumber: string,
  prefix: string = '0',
) => {
  if (phoneNumber === '') {
    return phoneNumber;
  }
  const number = stripPrefix(normalize(phoneNumber));
  return `${prefix}${number.substr(0, 4)} ${number.substr(
    4,
    3,
  )} ${number.substr(7)}`;
};
