import React, { useState } from 'react';
import { MobileContainer, Clear } from './styled';
import { Label } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { PartnerFilter } from './PartnerFilter';
import { TypeFilter } from './TypeFilter';
import { ZipCodeFilter } from './ZipCodeFilter';
import { useRooferFilters } from '../../hooks/useRooferFilters';

export const MobileRooferFilters = () => {
  const { t } = useTranslation('filters');
  const [key, setKey] = useState<string>('');
  const { clear } = useRooferFilters();
  const onClear = () => {
    clear();
    setKey(`${Math.random()}`);
  };
  return (
    <MobileContainer key={key}>
      <Label htmlFor="partners">{t('partners')}</Label>
      <PartnerFilter name="partners" placeHolder={t('allPartners')} />
      <Label htmlFor="types">{t('type')}</Label>
      <TypeFilter name="types" placeHolder={t('allTypes')} />
      <Label htmlFor="zipCode">{t('zipCode')}</Label>
      <ZipCodeFilter name="zipCode" placeHolder={t('allZipCodes')} />
      <Clear onClick={onClear}>{t('clear')}</Clear>
    </MobileContainer>
  );
};
