import React, { FC, memo } from 'react';
import {
  Container,
  StyledAvatar,
  Content,
  Info,
  CloseLink,
  MapOffset,
} from '../infoBar/styled';
import { P } from '@meindach/ui-kit';
import { ReactComponent as InfoIcon } from '@meindach/ui-kit/dist/assets/icons/info.svg';
import { ReactComponent as CloseIcon } from '@meindach/ui-kit/dist/assets/ui/close.svg';

interface Props {
  title: string;
  firstName: string;
  lastName?: string;
  imageUrl?: string;
  closeRoute: string;
  className?: string;
  onClick: () => void;
}

const MapOffsetMemo = memo(() => <MapOffset />);

export const InfoBar: FC<Props> = memo(
  ({
    firstName,
    lastName,
    className,
    onClick,
    closeRoute,
    title,
    imageUrl,
  }) => (
    <Container className={className}>
      <MapOffsetMemo />
      <Info onClick={onClick}>
        <StyledAvatar
          firstName={firstName}
          lastName={lastName}
          small
          src={imageUrl}
        />
        <Content>
          <P>{title}</P>
          <div>
            <span>
              {firstName} {lastName}
            </span>
            <InfoIcon />
          </div>
        </Content>
      </Info>
      <CloseLink to={closeRoute}>
        <CloseIcon />
      </CloseLink>
    </Container>
  ),
);
