import { config } from '../config';
import { getHeaders } from './getHeaders';

export const getConsultants = (idLastSeen?: string) => {
  const options = {
    headers: getHeaders(),
  };

  return fetch(
    `${config.apiBaseUrl}/api/v2/sales-consultants${getParams(idLastSeen)}`,
    options,
  );
};

const getParams = (idLastSeen?: string) => {
  return idLastSeen ? `?idLastSeen=${encodeURIComponent(idLastSeen)}` : '';
};
