import React, { FC, memo } from 'react';
import { Container, ContainerProps } from './styled';
import { ReactComponent as SuccessIcon } from '../../assets/icons/confirmed.svg';
import { ReactComponent as DangerIcon } from '../../assets/icons/forbidden.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';

type Props = ContainerProps & {
  withIcon?: boolean;
};

const getIcon = (type: ContainerProps['type']) => {
  switch (type) {
    case 'danger':
      return <DangerIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'success':
      return <SuccessIcon />;
  }
  return <InfoIcon />;
};

export const FeedbackMessage: FC<Props> = memo(
  ({ type, withIcon = true, children, ...rest }) => (
    <Container type={type} {...rest}>
      {withIcon && getIcon(type)}
      {children}
    </Container>
  ),
);
