import React from 'react';
import { Search } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../util/trackEvent';
import { useOpportunityFilters } from '../../hooks/useOpportunityFilters';

export const OpportunitySearch = () => {
  const { search, setSearch } = useOpportunityFilters();
  const { t } = useTranslation('filters');
  return (
    <Search
      placeholder={t('search')}
      value={search}
      onChange={setSearch}
      onBlur={() => trackEvent('filterOpportunities', 'search', search)}
    />
  );
};
