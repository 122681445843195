import styled from '../../../styled-components';

export const Epigraph = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  color: ${({ theme }) => theme.color.storm};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.35px;
`;
