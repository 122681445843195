import React, { FC, memo } from 'react';
import {
  ActiveFilterIcon,
  Container,
  DisabledLink,
  IconContainer,
  PageOffset,
  StyledFilterIcon,
  StyledListIcon,
  StyledMapIcon,
} from './styled';
import { Tab, Tabs } from '@meindach/ui-kit';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Route } from '../../types/Route';
import { useTranslation } from 'react-i18next';

interface Tab {
  label: string;
  route?: Route;
  active?: boolean;
}

type Props = RouteComponentProps & {
  tabs: Tab[];
};

const icons = [StyledListIcon, StyledMapIcon, StyledFilterIcon];

const PageOffsetMemo = memo(() => <PageOffset />);

const Component: FC<Props> = memo(({ location, tabs }) => {
  const { t } = useTranslation('navigation');
  return (
    <Container>
      <PageOffsetMemo />
      <Tabs>
        {tabs.map(({ route, label, active }, index) => {
          const Icon = icons[index];
          const LinkComponent: React.ElementType = route ? Link : DisabledLink;
          return (
            <LinkComponent
              to={{
                pathname: route,
                search: location.search,
                hash: location.hash,
              }}
              key={route || index}
            >
              <Tab
                active={route && location.pathname === route}
                disabled={!route}
              >
                <IconContainer>
                  <Icon />
                  {active && <ActiveFilterIcon />}
                </IconContainer>
                {t(label)}
              </Tab>
            </LinkComponent>
          );
        })}
      </Tabs>
    </Container>
  );
});

export const MobileNavigation = withRouter(Component);
