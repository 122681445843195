import React, { FC } from 'react';
import { StyledSelect } from './styled';
import { Option, SelectProps } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { RooferType } from '../../types/RooferType';
import { useRooferFilters } from '../../hooks/useRooferFilters';
import { trackEvent } from '../../util/trackEvent';

const Select = StyledSelect as FC<SelectProps<RooferType>>;

const typeList: RooferType[] = ['plus', 'new'];

interface Props {
  name?: string;
  placeHolder?: string;
}

export const TypeFilter: FC<Props> = ({ name, placeHolder }) => {
  const { t } = useTranslation('filters');
  const { types, setTypes } = useRooferFilters();
  const options: Option<RooferType>[] = typeList.map((value) => ({
    value,
    label: t(`type:${value}`),
  }));
  return (
    <Select
      name={name}
      options={options}
      placeHolder={placeHolder}
      onChange={setTypes}
      selectedValues={types}
      onBlur={() => trackEvent('filterRoofers', 'types', JSON.stringify(types))}
      multiple
    />
  );
};
