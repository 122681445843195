import React, { FC } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { Route as RouteType } from '../types/Route';
import { useAuth } from '../hooks/useAuth';

interface Props extends RouteProps {
  component: FC<RouteComponentProps>;
}

export const PrivateRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const { authenticated } = useAuth();
  if (authenticated) {
    return <Route component={Component} {...rest} />;
  }
  return (
    <Redirect
      to={{ pathname: RouteType.Login, search: window.location.search }}
    />
  );
};
