import styled, { css } from '../../styled-components';
import { P, Card, media } from '@meindach/ui-kit';
import { OpportunityStatus } from '../../types/OpportunityStatus';

export const Status = styled.div`
  margin-left: ${({ theme }) => theme.spacing.s};
  ${media.md`
    margin-left: ${({ theme }) => theme.spacing.m};
  `}
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-width: 62px;
`;

export const IconContainer = styled.div<{ status: OpportunityStatus }>`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;

  > svg {
    width: 37px;
    height: 37px;
  }
  border: 1px solid ${({ theme }) => theme.color.mercury};
  background: ${({ theme }) => theme.color.white};

  ${({ status }) => {
    switch (status) {
      case 'open':
        return css`
          color: ${({ theme }) => theme.color.cyan};
        `;
      case 'done':
        return css`
          color: ${({ theme }) => theme.color.success};
        `;
    }
    return css`
      color: #ffae11;
    `;
  }}
`;

export const StyledCard = styled(Card)`
  width: 100%;
  cursor: pointer;
  > div {
    padding: 0;
  }
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.18px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.md`
    font-size: 18px;
    letter-spacing: 0.2px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  padding: 12px ${({ theme }) => theme.spacing.s};
  ${media.md`
    padding: 12px ${({ theme }) => theme.spacing.m};
  `}
  ${Name} {
    margin: 0;
  }
  ${P} {
    margin: 0;
    display: flex;
    align-items: center;

    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > svg {
      width: 20px;
      height: 20px;
      margin-left: -${({ theme }) => theme.spacing.xs};
      flex-shrink: 0;
    }
  }
`;

export const Details = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.color.slate};

  svg,
  div {
    margin-right: ${({ theme }) => theme.spacing.s};
  }

  svg {
    width: auto;
    height: 22px;
    flex-shrink: 0;

    &:nth-of-type(2) {
      margin-left: ${({ theme }) => theme.spacing.m};
      height: 16px;
    }
  }
`;
