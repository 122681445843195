import React, { FC, useCallback, useEffect } from 'react';
import { P } from '@meindach/ui-kit';
import {
  Action,
  CloseContainer,
  Content,
  DownloadActions,
  Header,
  Name,
} from './styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from '@meindach/ui-kit/dist/assets/icons/info.svg';
import { ReactComponent as DownloadIcon } from '@meindach/ui-kit/dist/assets/icons/download-file.svg';
import { ReactComponent as QuoteIcon } from '@meindach/ui-kit/dist/assets/icons/euro.svg';
import { ReactComponent as LeadIcon } from '@meindach/ui-kit/dist/assets/icons/lead-assigned.svg';
import { ReactComponent as Arrow } from '@meindach/ui-kit/dist/assets/icons/arrow-right.svg';
import { trackEvent } from '../../util/trackEvent';
import { Panel, PanelProps } from '../../components/panel/Panel';
import { InfoSection } from './InfoSection';
import { Opportunity } from '../../types/Opportunity';
import { OpportunityStatusIcon } from '../../components/opportunityCard/OpportunityStatus';
import { InfoTable } from './InfoTable';
import { PanelTabs } from '../../components/panel/PanelTabs';
import { isLeadPdfAvailable } from '../../util/isLeadPdfAvailable';
import { isQuoteAvailable } from '../../util/isQuoteAvailable';
import { useSelectedFile } from '../../hooks/useSelectedFile';
import { FileType } from '../../types/FileType';
import { User } from '../../types/User';

interface Props extends Pick<PanelProps, 'onClose'> {
  userId: User['id'];
  opportunity: Opportunity;
}

export const OpportunityPanel: FC<Props> = ({
  opportunity,
  onClose,
  userId,
}) => {
  const { t } = useTranslation('opportunityInfo');
  const { setFile } = useSelectedFile();
  const {
    id,
    name,
    fullName,
    roofType,
    salesStage,
    consultantProposalCheck,
  } = opportunity;
  const referenceNumber = name.split(' ')[0];

  const onLeadClick = () => setFile({ id, type: FileType.Lead });
  const onQuoteClick = useCallback(
    () =>
      setFile({
        id,
        type: FileType.Quote,
        owner: consultantProposalCheck === userId,
      }),
    [id, consultantProposalCheck, setFile, userId],
  );

  useEffect(() => {
    trackEvent('opportunity', 'openInfo', id);
    const params = new URLSearchParams(window.location.search);
    const check = params.get('check');
    if (check) {
      onQuoteClick();
      // Clear url params so the next selected opportunity wouldnt open the quote
      const state = `${window.location.pathname}${window.location.hash}`;
      window.history.replaceState({}, state, state);
    }
  }, [id, onQuoteClick]);

  return (
    <Panel onClose={onClose} id={id}>
      <CloseContainer>
        <Arrow onClick={onClose} />
        <P onClick={onClose}>{t('common:backToMap')}</P>
      </CloseContainer>
      <Header>
        <div>
          <OpportunityStatusIcon roofType={roofType} salesStage={salesStage} />
        </div>
        <Content>
          <P>{referenceNumber}</P>
          <Name m ellipsis>
            {fullName}
          </Name>
        </Content>
        <DownloadActions>
          <Action
            disabled={!isLeadPdfAvailable(salesStage)}
            onClick={onLeadClick}
          >
            <DownloadIcon />
            {t('downloadLeadPdf')}
            <Arrow />
          </Action>
          <Action
            disabled={!isQuoteAvailable(salesStage)}
            onClick={onQuoteClick}
          >
            <QuoteIcon />
            {t('downloadQuotePdf')}
            <Arrow />
          </Action>
        </DownloadActions>
      </Header>
      <PanelTabs
        tabs={[
          {
            icon: InfoIcon,
            label: t('common:info'),
            component: <InfoSection {...opportunity} />,
          },
          {
            icon: LeadIcon,
            label: t('common:state'),
            component: <InfoTable {...opportunity} />,
          },
        ]}
      />
    </Panel>
  );
};
