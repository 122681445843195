import { FC, memo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { trackEvent } from '../../util/trackEvent';
import { Route } from '../../types/Route';

const Component: FC<RouteComponentProps> = memo(({ location }) => {
  trackEvent('pageView', location.pathname as Route);
  return null;
});

export const PageViewTracker = withRouter(Component);
