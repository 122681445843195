import React, { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { Route as RouteType } from '../types/Route';
import { useAuth } from '../hooks/useAuth';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteProps {
  component: FC<RouteComponentProps>;
}

export const PublicRoute = ({ component: Component, ...rest }: Props) => {
  const { authenticated } = useAuth();
  if (authenticated) {
    return <Redirect to={RouteType.Roofers} />;
  }
  return <Route component={Component} {...rest} />;
};
