import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConsultantFilters } from '../../hooks/useConsultantFilters';
import { trackEvent } from '../../util/trackEvent';
import { getZipCode } from '../../api/getZipCode';
import { ReactComponent as LocationIcon } from '@meindach/ui-kit/dist/assets/icons/location.svg';
import { StyledSearch } from './styled';

export const ConsultantSearch = memo(() => {
  const { t } = useTranslation('filters');
  const { search, setSearch } = useConsultantFilters();
  const [key, setKey] = useState('');
  const [zipCode, setZipCode] = useState<string | null>(null);

  const updateSearch = (zipCode: string) => {
    setSearch(zipCode);
    setKey(`${Math.random()}`);
  };

  const positionSuccess: PositionCallback = async ({ coords }) => {
    const { longitude, latitude } = coords;
    const zipCode = await getZipCode(latitude, longitude);
    if (!zipCode) {
      return;
    }
    setZipCode(zipCode);
    updateSearch(zipCode);
  };

  const locate = () =>
    zipCode
      ? updateSearch(zipCode)
      : navigator.geolocation.getCurrentPosition(positionSuccess);

  return (
    <StyledSearch
      key={key}
      value={search}
      onChange={setSearch}
      onBlur={() => trackEvent('filterConsultants', 'search', search)}
      icon={<LocationIcon onClick={locate} />}
      placeholder={t('search')}
    />
  );
});
