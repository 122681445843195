export enum TestAttribute {
  LoginButton = 'login-button',
  UserDataList = 'user-data-list',
  Map = 'map',
  RooferServices = 'roofer-services',
  NoRoofersFound = 'no-roofers-found',
  NoConsultants = 'no-consultants',
  LoadingConsultants = 'loading-consultants',
  Card = 'card',
  EmailVerifiedFeedback = 'email-verified-feedback',
  ModalClose = 'modal-close',
}
