/**
 * Convert Hex color to RGBA
 * @param hex string hex color
 * @param alpha number 0-1
 */
export const hexToRGBA = (hex: string, alpha: number = 1): string => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r},${g},${b},${alpha})`;
};
