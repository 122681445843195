import { Auth0DecodedHash, Auth0Error, WebAuth } from 'auth0-js';
import { config } from '../config';
import { StorageId } from '../types/StorageId';
import { useCallback, useEffect } from 'react';
import { createStore, useStore } from 'react-hookstore';
import { Store } from '../types/Store';

const isAuthenticated = () =>
  new Date().getTime() < parseInt(localStorage.getItem(StorageId.ExpiresAt)!);

interface Auth {
  authenticated: boolean;
  error?: string;
}

export const store = createStore<Auth>(Store.Auth, {
  authenticated: isAuthenticated(),
});

interface Output extends Auth {
  login: () => void;
  logout: () => void;
  authenticate: () => void;
  changePassword: (email: string) => Promise<Auth0Error | string>;
}

const auth0 = new WebAuth({
  domain: config.auth0.domain,
  clientID: config.auth0.clientId,
  responseType: 'token id_token',
});

const login = () =>
  auth0.authorize({
    redirectUri: `${config.auth0.callbackUrl}${window.location.search}`,
  });
const logout = () => {
  auth0.logout({ returnTo: config.auth0.logoutUrl });
  localStorage.removeItem(StorageId.IdToken);
  localStorage.removeItem(StorageId.ExpiresAt);
};

const saveSession = ({ expiresIn, idToken }: Auth0DecodedHash) => {
  const expiresAt = expiresIn! * 1000 + new Date().getTime();
  localStorage.setItem(StorageId.IdToken, idToken!);
  localStorage.setItem(StorageId.ExpiresAt, expiresAt.toString());
};

const changePassword = (email: string) =>
  new Promise<Auth0Error | string>((resolve, reject) =>
    auth0.changePassword(
      { connection: 'Username-Password-Authentication', email },
      (error: Auth0Error | null, result: string) =>
        error ? reject(error) : resolve(result),
    ),
  );

export const useAuth = (): Output => {
  const [auth, setAuth] = useStore<Auth>(Store.Auth);

  useEffect(() => {
    if (auth.authenticated && !isAuthenticated()) {
      setAuth({ ...auth, authenticated: false });
    }
  });

  const authenticate = useCallback(
    () =>
      auth0.parseHash((error, result) => {
        if (!error && result) {
          saveSession(result);
          setAuth({ authenticated: true });
          return;
        }
        setAuth({
          authenticated: false,
          error: error ? error.error : 'Authentication error',
        });
      }),
    [setAuth],
  );

  return {
    login,
    logout,
    authenticate,
    changePassword,
    ...auth,
  };
};
