/**
 * Format timestamp into a date string of dd.mm.yyyy
 * @example 1554120490 => '01.04.2019'
 * @param timeStamp Unix timestamp in seconds
 */
export const timestampToDate = (timeStamp: number) => {
  const date = new Date(timeStamp * 1000);
  const formatDigit = (date: number): string =>
    date < 10 ? `0${date}` : `${date}`;

  const dd = formatDigit(date.getDate());
  const mm = formatDigit(date.getMonth() + 1);
  const yyyy = date.getUTCFullYear();

  return `${dd}.${mm}.${yyyy}`;
};
