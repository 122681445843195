import React, { FC } from 'react';
import { ActiveConsultantBar } from '../activeConsultantBar/ActiveConsultantBar';
import { ActiveRooferBar } from '../activeRooferBar/ActiveRooferBar';

interface Props {
  className?: string;
}

export const OpportunityOwnerBar: FC<Props> = ({ className }) => {
  const id = window.location.hash.replace('#', '');
  if (!id) {
    return null;
  }
  if (id.includes('@')) {
    return <ActiveConsultantBar className={className} />;
  }
  return <ActiveRooferBar id={id} className={className} />;
};
