import React, {
  FC,
  useState,
  useEffect,
  ReactElement,
  Children,
  memo,
  useCallback,
} from 'react';
import MapGL, { ViewState, MapboxProps } from 'react-map-gl';
import { MarkerStyle } from './styled';
import { sortByKeyDesc } from '@meindach/ui-kit';
import { mapStyle as defaultMapStyle } from './mapStyle';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Controls } from './Controls';

interface DataSource {
  type: string;
  features: any[];
}

type Props = Partial<ViewState> &
  Pick<MapboxProps, 'mapboxApiAccessToken'> & {
    dataSource?: DataSource;
    interactive?: boolean;
  };

const sortByLatitude = sortByKeyDesc('lat');
const sortChildren = (a: ReactElement, b: ReactElement) =>
  sortByLatitude(a.props, b.props);

const MapStyle = memo(() => <MarkerStyle />);

export const Map: FC<Props> = memo(
  ({
    latitude = 51.1634,
    longitude = 10.4477,
    zoom = 5,
    interactive = true,
    mapboxApiAccessToken,
    dataSource,
    children,
  }) => {
    const [mapStyle, setMapStyle] = useState<any>(null);
    const [viewport, setViewport] = useState<ViewState>({
      latitude,
      longitude,
      zoom,
    });

    useEffect(() => {
      setViewport({ latitude, longitude, zoom });
    }, [latitude, longitude, zoom]);
    useEffect(() => {
      setMapStyle(
        defaultMapStyle.setIn(['sources', 'dataSource'], {
          type: 'geojson',
          data: dataSource || { type: 'FeatureCollection', features: [] },
        }),
      );
    }, [dataSource]);

    const onViewportChange = interactive
      ? useCallback((vp: ViewState) => setViewport(vp), [])
      : undefined;
    return (
      <>
        <MapStyle />
        <MapGL
          {...viewport}
          reuseMaps
          width="100%"
          height="100%"
          mapStyle={mapStyle}
          mapboxApiAccessToken={mapboxApiAccessToken}
          onViewportChange={onViewportChange}
        >
          {(Children.toArray(children) as ReactElement[]).sort(sortChildren)}
          {interactive && <Controls onViewportChange={onViewportChange} />}
        </MapGL>
      </>
    );
  },
);
