import React, { FC } from 'react';
import { Container, Info } from './styled';
import { Avatar } from '@meindach/ui-kit';
import { Route } from '../../types/Route';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { ReactComponent as Loading } from '@meindach/ui-kit/dist/assets/loading/loading-small.svg';
import { useAuth } from '../../hooks/useAuth';
import { config } from '../../config';

const Component: FC<RouteComponentProps> = ({ location }) => {
  const { authenticated } = useAuth();
  const { user, fetching } = useUser();
  if (!authenticated) {
    return null;
  }
  return (
    <Link to={Route.Account}>
      <Container highlighted={location.pathname === Route.Account}>
        {user ? (
          <Avatar
            small
            firstName={user.firstName}
            lastName={user.lastName}
            src={
              user.imageHash &&
              `${config.apiBaseUrl}/api/v1/public/images/sales-consultants/${user.id}/${user.imageHash}/size?assetType=THUMBNAIL`
            }
          />
        ) : (
          <Avatar small />
        )}
        <Info>{user && <span>{user.fullName}</span>}</Info>
        {fetching && <Loading />}
      </Container>
    </Link>
  );
};

export const UserInfo = withRouter(Component);
