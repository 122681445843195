import React, { FC, useEffect } from 'react';
import { Avatar, P, PartnerLogo, Tag } from '@meindach/ui-kit';
import { CloseContainer, Company, Content, Header, Name } from './styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as OpportunityIcon } from '@meindach/ui-kit/dist/assets/icons/house.svg';
import { ReactComponent as HandshakeIcon } from '@meindach/ui-kit/dist/assets/icons/handshake.svg';
import { ReactComponent as Arrow } from '@meindach/ui-kit/dist/assets/icons/arrow-right.svg';
import { ReactComponent as InfoIcon } from '@meindach/ui-kit/dist/assets/icons/info.svg';
import { trackEvent } from '../../util/trackEvent';
import { Panel, PanelProps } from '../../components/panel/Panel';
import { Roofer } from '../../types/Roofer';
import { Route } from '../../types/Route';
import { InfoSection } from './InfoSection';
import { PanelActions } from '../../components/panel/PanelActions';
import { PanelTabs } from '../../components/panel/PanelTabs';
import { config } from '../../config';

interface Props extends Pick<PanelProps, 'onClose'> {
  roofer: Roofer;
}

export const RooferInfoPanel: FC<Props> = ({ roofer, onClose }) => {
  const { t } = useTranslation('rooferPanel');
  const {
    id,
    name,
    partner,
    plusPartner,
    new: newPartner,
    classification,
    opportunityIds,
    imageHash,
  } = roofer;

  // Track event for opening
  useEffect(() => {
    trackEvent('roofer', 'openInfo', id);
  }, [id]);

  return (
    <Panel onClose={onClose} id={id}>
      <CloseContainer>
        <Arrow onClick={onClose} />
        <P onClick={onClose}>{t('common:backToMap')}</P>
      </CloseContainer>
      <Header>
        <div>
          <Avatar
            big
            firstName={name}
            src={
              imageHash &&
              `${config.apiBaseUrl}/api/v1/public/images/roofers/${roofer.id}/${imageHash}/size?assetType=THUMBNAIL`
            }
          />
        </div>
        <Content>
          <Name m ellipsis>
            {name}
          </Name>
          <Company>
            <PartnerLogo partner={partner} />
            <Tag type="success">
              <HandshakeIcon />
              {classification && classification.conversionRate}%
            </Tag>
            {plusPartner && <Tag>{t('type:plus')}</Tag>}
            {newPartner && <Tag type="warning">{t('type:new')}</Tag>}
          </Company>
        </Content>
        <PanelActions
          actions={[
            {
              icon: OpportunityIcon,
              text: `${opportunityIds.length} ${t('common:opportunities')}`,
              link: {
                pathname: Route.Opportunities,
                hash: `#${id}`,
                search: window.location.hash
                  ? `?consultant=${window.location.hash.substr(1)}`
                  : '',
              },
            },
          ]}
        />
      </Header>
      <PanelTabs
        tabs={[
          {
            icon: InfoIcon,
            label: t('common:info'),
            component: <InfoSection {...roofer} />,
          },
        ]}
      />
    </Panel>
  );
};
