import styled from '../../styled-components';
import { Card, CardProps, media, Avatar, P } from '@meindach/ui-kit';

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m};

  ${media.md`
    justify-content: center;
  `}
`;

export const StyledAvatar = styled(Avatar)`
  width: 52px;
  height: 52px;
`;

interface Props extends CardProps {
  directorView: boolean;
}

export const StyledCard = styled(Card)<Props>`
  width: 100%;
  height: 84px;
  cursor: pointer;
  > div {
    padding: 0;
  }

  ${({ selected, highlighted }) =>
    !selected &&
    highlighted &&
    media.md`
      ${AvatarContainer} {
        color: ${({ theme }) => theme.color.blue};
      }
    `};

  ${media.md`
    width: calc((100% - 8px) / 3);
    height: 169px;
    display: inline-block;

    &:nth-of-type(3n - 1) {
      margin: 0 4px;
    }
    
    > div {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  `}

  ${({ directorView }) =>
    directorView &&
    media.md`
      height: 202px;
    `}
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.18px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.md`
    font-size: 18px;
    letter-spacing: 0.2px;
    padding: 0 ${({ theme }) => theme.spacing.s};
  `}
`;

export const Content = styled.div`
  height: 52px;
  margin: auto 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;

  ${Name} {
    margin: 0;
  }
  ${media.md`
    margin: 0;
  `}
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-right: ${({ theme }) => theme.spacing.s};
  }

  ${media.md`
    justify-content: center;
  `}

  ${P} {
    margin: 0 0 8px;
    color: ${({ theme }) => theme.color.blue};

    > svg {
      margin-bottom: 2px;
      margin-left: ${({ theme }) => theme.spacing.xs};
      transform: rotate(-90deg);
    }
  }
`;

export const Actions = styled.div`
  display: none;
  border-left: 1px solid ${({ theme }) => theme.color.mercury};
  ${media.md`
    display: flex;
    border-left: none;
  `}

  flex-direction: column;
  justify-content: space-evenly;

  > div {
    width: 51px;
    height: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.color.blue};

    > svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }

    ${media.md`
      width: 100%;
      height: 31px;

      display: flex;
      border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
      &:first-child {
        margin-top: ${({ theme }) => theme.spacing.s};
        border-top: 1px solid ${({ theme }) => theme.color.mercury};
        
      }

      > span {
        font-size: 14px;
        letter-spacing: 0.16px;
        font-family: ${({ theme }) => theme.fontFamily.primary};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: ${({ theme }) => theme.spacing.s};
      }
      > svg:first-child {
        margin: 0 ${({ theme }) => theme.spacing.s};
      }
      > :nth-child(3) {
        margin-left: auto;
        margin-right: ${({ theme }) => theme.spacing.s};
        color: ${({ theme }) => theme.color.mercury};
        transform: rotate(-90deg);
        width: 13px;
      }
    `}
  }

  > a:first-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  }
`;
