import React from 'react';
import { ReactComponent as Icon } from '@meindach/ui-kit/dist/assets/icons/lead-none.svg';
import { EmptyListContainer } from './styled';
import { Title, P } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';

export const NoOpportunities = () => {
  const { t } = useTranslation('opportunityList');
  return (
    <EmptyListContainer>
      <Icon />
      <Title m>{t('emptyListTitle')}</Title>
      <P>{t('emptyListText')}</P>
    </EmptyListContainer>
  );
};
