import { useStore, createStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { FileType } from '../types/FileType';

interface File {
  id: string;
  type: FileType;
  owner?: boolean;
}

interface Output {
  file: File | null;
  setFile: (file: File | null) => void;
}

createStore<File | null>(Store.SelectedFile, null);

export const useSelectedFile = (): Output => {
  const [file, setFile] = useStore<File | null>(Store.SelectedFile);
  return { file, setFile };
};
