import React, { FC, useEffect } from 'react';
import { ModalProps, P } from '@meindach/ui-kit';
import { Roofer } from '../../types/Roofer';
import {
  Content,
  Header,
  Links,
  Name,
  StyledAvatar,
  StyledModal,
} from './styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '@meindach/ui-kit/dist/assets/ui/close.svg';
import { ReactComponent as OpportunityIcon } from '@meindach/ui-kit/dist/assets/icons/house.svg';
import { RooferModalDetails } from './RooferModalDetails';
import { TestAttribute } from '../../types/TestAttribute';
import { trackEvent } from '../../util/trackEvent';
import { RooferActions } from './RooferActions';
import { Route } from '../../types/Route';
import { LinkAction } from '../../components/linkAction/LinkAction';
import { config } from '../../config';

interface Props extends ModalProps {
  roofer: Roofer;
}

export const RooferModal: FC<Props> = ({ roofer, onClose }) => {
  const { t } = useTranslation('rooferInfo');
  const { id, name, opportunityIds, imageHash } = roofer;
  useEffect(() => {
    trackEvent('roofer', 'openInfo', id);
  }, [id]);

  return (
    <StyledModal onClose={onClose}>
      <Header>
        <div>
          <StyledAvatar
            firstName={name}
            src={
              imageHash &&
              `${config.apiBaseUrl}/api/v1/public/images/roofers/${roofer.id}/${imageHash}/size?assetType=THUMBNAIL`
            }
          />
        </div>
        <Content>
          <P>{t('title')}</P>
          <Name>{name}</Name>
          <Links>
            <LinkAction
              link={{
                pathname: Route.Opportunities,
                hash: `#${id}`,
                search: window.location.hash
                  ? `?consultant=${window.location.hash.substr(1)}`
                  : '',
              }}
              onClick={onClose}
              icon={OpportunityIcon}
              text={opportunityIds.length}
            />
          </Links>
        </Content>
        <div>
          <CloseIcon
            onClick={onClose}
            data-test-id={TestAttribute.ModalClose}
          />
        </div>
      </Header>
      <RooferModalDetails {...roofer} />
      <RooferActions {...roofer} />
    </StyledModal>
  );
};
