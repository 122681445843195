import React from 'react';
import { Entry, LoadingContainer } from './styled';
import { useUser } from '../../hooks/useUser';
import { useTranslation } from 'react-i18next';
import { TestAttribute } from '../../types/TestAttribute';
import { capitalizeFirstLetter, P } from '@meindach/ui-kit';
import { ReactComponent as Loading } from '@meindach/ui-kit/dist/assets/loading/loading.svg';

export const UserDataList = () => {
  const { t } = useTranslation('account');
  const { user, fetching } = useUser();
  if (fetching) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }
  if (!user) {
    return <P>{t('loadError')}</P>;
  }
  const { fullName, email, phone, type, brand, salesConsultantManager } = user;
  return (
    <div data-test-id={TestAttribute.UserDataList}>
      <Entry>
        <P>{t('name')}</P>
        <P>{fullName}</P>
      </Entry>
      <Entry>
        <P>{t('email')}</P>
        <P>{email}</P>
      </Entry>
      <Entry>
        <P>{t('phone')}</P>
        <P>{phone}</P>
      </Entry>
      <Entry>
        <P>{t('type')}</P>
        <P>{t(`userType:${type}`)}</P>
      </Entry>
      {salesConsultantManager && (
        <Entry>
          <P>{t('manager')}</P>
          <P>{salesConsultantManager}</P>
        </Entry>
      )}
      <Entry>
        <P>{t('common:brand')}</P>
        <P>{capitalizeFirstLetter(brand)}</P>
      </Entry>
    </div>
  );
};
