/**
 * Pad a number to specified width and padding symbol
 * @param num Number to be padded
 * @param width Default 2
 * @param padSymbol Default '0'
 */
export const padNumber = (
  num: number,
  width: number = 2,
  padSymbol: string = '0',
): string => {
  const text = `${num}`;
  return text.length >= width
    ? text
    : new Array(width - text.length + 1).join(padSymbol) + text;
};
