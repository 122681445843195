import styled, { createGlobalStyle } from '../../styled-components';
import { Link } from 'react-router-dom';
import { PageContainer } from '../../layout/styled';
import { Title, media } from '@meindach/ui-kit';

export const PageOffset = createGlobalStyle`
  ${PageContainer} {
    padding-top: 52px;
    ${media.md`
      padding-top: 64px;
    `};
  }
  
  ${media.md`
    #root > nav {
      padding-top: 64px;
    }
  `};
`;

export const Container = styled.header`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  background-color: ${({ theme }) => theme.color.white};
  display: flex;

  height: 52px;
  ${media.md`
    height: 64px;
  `};
`;

export const LogoContainer = styled(Link)`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  width: 50px;
  ${media.md`
    width: 64px;
  `}

  svg {
    height: 33px;
    width: 33px;
    ${media.md`
      height: 44px;
      width: 44px;
    `}
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex: 1;

  display: flex;
  align-items: center;
  overflow: hidden;
  border-right: 1px solid ${({ theme }) => theme.color.mercury};

  ${media.md`
    padding: ${({ theme }) => theme.spacing.s};
  `};
`;

export const StyledTitle = styled(Title)`
  font-weight: 300;
  margin: 0;

  ${media.md`
    font-size: 20px;
    font-weight: 400;
  `}
`;
