import React, { FC } from 'react';
import { trackEvent } from '../../util/trackEvent';
import { ReactComponent as PhoneIcon } from '@meindach/ui-kit/dist/assets/icons/phone.svg';
import { ReactComponent as MapIcon } from '@meindach/ui-kit/dist/assets/icons/map.svg';
import { ReactComponent as RooferIcon } from '@meindach/ui-kit/dist/assets/icons/handshake.svg';
import { Action, LinkAction } from './styled';
import { formatPhoneNumber, P } from '@meindach/ui-kit';
import { Opportunity } from '../../types/Opportunity';
import { getGoogleMapsUrl } from '../../util/getGoogleMapsUrl';
import { Route } from '../../types/Route';

export type ContactActionsProps = Pick<
  Opportunity,
  | 'id'
  | 'phone'
  | 'latLon'
  | 'street'
  | 'postalCode'
  | 'city'
  | 'accountId'
  | 'accountName'
>;

export const OpportunityContactActions: FC<ContactActionsProps> = ({
  id,
  phone,
  latLon,
  street,
  postalCode,
  city,
  accountId,
  accountName,
}) => {
  const address = `${street}, ${postalCode} ${city}`;

  const params = new URLSearchParams(window.location.search);
  const consultant =
    params.get('consultant') || window.location.hash.replace('#', '');
  const hash = consultant ? `#${consultant}` : '';
  return (
    <>
      <LinkAction to={`${Route.Roofers}?id=${accountId}${hash}`}>
        <RooferIcon />
        {accountName}
      </LinkAction>
      <Action
        href={getGoogleMapsUrl(address, latLon)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => trackEvent('opportunity', 'openMap', id)}
      >
        <MapIcon />
        <P>{address}</P>
      </Action>
      <Action
        href={`tel:${phone}`}
        onClick={() => trackEvent('consultant', 'call', id)}
      >
        <PhoneIcon />
        <P>{formatPhoneNumber(phone)}</P>
      </Action>
    </>
  );
};
