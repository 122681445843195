import styled, { css } from '../../styled-components';
import { media } from '../../styles/media';

export const Error = styled.p`
  font-size: 12px;
  margin: ${({ theme }) => theme.spacing.xs} 0;
  min-height: 16px;
  color: ${({ theme }) => theme.color.error};
  font-style: italic;
`;

export const Label = styled.p<{ error?: boolean }>`
  font-size: 14px;
  font-weight: 600;
  margin: ${({ theme }) => theme.spacing.xs} 0;

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${media.md`
    font-size: 18px;
  `}

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.color.error};
    `}
`;
