import { useCallback, useEffect } from 'react';
import { createStore, useStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { useAuth } from './useAuth';
import { Opportunity } from '../types/Opportunity';
import { getOpportunities } from '../api/getOpportunities';

type State = Record<string, OpportunitiesState>;

interface OpportunitiesState {
  opportunities?: Opportunity[];
  error?: boolean;
  fetching?: boolean;
}

const defaultUser = 'me';

export const store = createStore<State>(Store.Opportunities, {});

const fetchOpportunities = async (
  setState: (state: OpportunitiesState) => void,
  key?: string,
) => {
  setState({ fetching: true });
  try {
    const response = await getOpportunities(key);
    if (response.ok) {
      setState({ opportunities: await response.json() });
    } else {
      setState({ error: response.status === 500 });
    }
  } catch {
    setState({ error: true });
  }
};

export const useOpportunities = () => {
  const key = window.location.hash.replace('#', '') || defaultUser;
  const { authenticated } = useAuth();
  const [state, setState] = useStore<State>(Store.Opportunities);
  const { opportunities = null, error = false, fetching = false } =
    state[key] || {};

  const refreshData = useCallback(
    () =>
      fetchOpportunities(
        (opportunitiesState: OpportunitiesState) =>
          setState({ ...state, [key]: opportunitiesState }),
        key === defaultUser ? undefined : key,
      ),
    [key, setState, state],
  );

  useEffect(() => {
    if (authenticated) {
      // Get fetching through store to get latest value while hook will only be updated after
      const opportunitiesState = store.getState()[key];
      if (
        !opportunities &&
        (!opportunitiesState || !opportunitiesState.fetching)
      ) {
        refreshData();
      }
    } else if (Object.keys(state).length > 0) {
      setState({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, key]);

  return { opportunities, fetching, error, refreshData };
};
