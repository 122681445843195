import React, { FC } from 'react';
import { StyledSelect } from './styled';
import { Option, SelectProps } from '@meindach/ui-kit';
import { trackEvent } from '../../util/trackEvent';
import { useOpportunityFilters } from '../../hooks/useOpportunityFilters';
import { SalesStage } from '../../types/SalesStage';
import { useFieldTranslations } from '../../hooks/useFieldTranslations';
import { translatedFieldNames } from '../../data/translatedFieldNames';

const Select = StyledSelect as FC<SelectProps<SalesStage>>;
const valueList: SalesStage[] = [
  'roofer_picking',
  'Resubmission',
  'inspection',
  'proposal_creation',
  'proposal_tracking',
  'contract_creation',
  'execution',
  'project_delayed',
  'completion',
  'Cleared',
];

interface Props {
  name?: string;
  placeHolder?: string;
}

export const SalesStageFilter: FC<Props> = ({ name, placeHolder }) => {
  const { fields } = useFieldTranslations(translatedFieldNames);
  const { salesStages, setSalesStages } = useOpportunityFilters();
  const options: Option<SalesStage>[] = fields
    ? valueList.map((value) => ({
        value,
        label: fields.salesStage[value],
      }))
    : [];
  return (
    <Select
      name={name}
      options={options}
      placeHolder={placeHolder}
      onChange={setSalesStages}
      selectedValues={salesStages}
      onBlur={() =>
        trackEvent(
          'filterOpportunities',
          'salesStages',
          JSON.stringify(salesStages),
        )
      }
      multiple
    />
  );
};
