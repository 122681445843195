import { useEffect } from 'react';
import { getRoofers } from '../api/getRoofers';
import { createStore, useStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { useAuth } from './useAuth';
import { Roofer } from '../types/Roofer';

type State = Record<string, RoofersState>;

interface RoofersState {
  roofers?: Roofer[];
  error?: boolean;
  fetching?: boolean;
}

const defaultUser = 'me';

export const store = createStore<State>(Store.Roofers, {});

const fetchRoofers = async (
  setState: (state: RoofersState) => void,
  email: string = '',
  allowEmailOverride?: boolean,
) => {
  setState({ fetching: true });
  try {
    const response = await getRoofers(
      email.includes('@') ? email : undefined,
      allowEmailOverride,
    );
    if (response.ok) {
      setState({ roofers: await response.json() });
    } else {
      setState({ error: response.status === 500 });
    }
  } catch {
    setState({ error: true });
  }
};

export const useRoofers = (
  userEmail: string = window.location.hash.replace('#', '') || defaultUser,
  allowEmailOverride: boolean = true,
) => {
  const { authenticated } = useAuth();
  const [state, setState] = useStore<State>(Store.Roofers);
  const { roofers = null, error = false, fetching = false } =
    state[userEmail] || {};

  useEffect(() => {
    if (authenticated) {
      // Get fetching through store to get latest value while hook will only be updated after
      const roofersState = store.getState()[userEmail];
      if (!roofers && (!roofersState || !roofersState.fetching)) {
        fetchRoofers(
          (roofersState: RoofersState) =>
            setState({ ...state, [userEmail]: roofersState }),
          userEmail === defaultUser ? undefined : userEmail,
          allowEmailOverride,
        );
      }
    } else if (Object.keys(state).length > 0) {
      setState({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, userEmail, allowEmailOverride]);

  return { roofers, fetching, error };
};
