import styled from '../../styled-components';
import { P, Modal, Title } from '@meindach/ui-kit';

export const StyledModal = styled(Modal)`
  max-width: 410px;
  margin: 0 auto;
`;

export const Name = styled(Title)`
  margin: 0;
`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spacing.m};
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;

  > div {
    &:first-child {
      flex: 0;
      margin-right: ${({ theme }) => theme.spacing.m};
    }
    &:nth-child(2) {
      flex: 1;
    }
  }
  > a {
    display: flex;
    align-items: center;
    margin-left: ${({ theme }) => theme.spacing.m};
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  height: 52px;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.m};

  > svg,
  > ${P} {
    cursor: pointer;
  }
  &,
  > ${P} {
    color: ${({ theme }) => theme.color.blue};
  }
  > ${P} {
    font-weight: 600;
    margin: 0;
    padding: ${({ theme }) => theme.spacing.s} 0;
  }
  > svg {
    width: 16px;
    height: 20px;
    transform: rotate(180deg);
    padding: ${({ theme }) => theme.spacing.s};
    margin-left: -${({ theme }) => theme.spacing.s};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${Name} {
    margin: 0;
  }
  ${P} {
    margin: 0;
    display: flex;
    align-items: center;

    > svg {
      width: 20px;
      height: 20px;
      margin-left: -${({ theme }) => theme.spacing.xs};
    }
  }
`;

export const Company = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${({ theme }) => theme.color.red};
    margin: 0;
    margin-right: ${({ theme }) => theme.spacing.m};
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.18px;
  }
  *:not(p) {
    margin-right: ${({ theme }) => theme.spacing.s};
    margin-top: ${({ theme }) => theme.spacing.xs};
  }

  > svg {
    width: auto;
    height: 24px;
  }
`;

export const Entry = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  display: flex;

  > ${P} {
    flex: 70;
    margin: ${({ theme }) => theme.spacing.s} 0;

    &:first-child {
      flex: 30;
      display: flex;
      color: ${({ theme }) => theme.color.silver};
    }

    > svg {
      margin-right: ${({ theme }) => theme.spacing.s};
      width: 25px;
      height: 25px;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const Details = styled.div`
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m};

  > ${P} {
    font-size: 16px;
    margin: 0;
    line-height: 43px;
    border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  }
`;

export const Action = styled.a`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  height: 43px;
  display: flex;
  align-items: center;

  > * {
    color: ${({ theme }) => theme.color.blue};
  }

  > ${P} {
    font-weight: 600;
  }

  > svg {
    margin-right: ${({ theme }) => theme.spacing.s};
    width: 25px;
    height: 25px;
  }
`;
