import styled, { css } from '../../styled-components';

interface Props {
  highlighted: boolean;
}

export const Container = styled.div<Props>`
  height: 100%;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.black};

  padding: ${({ theme }) => theme.spacing.m};

  > svg {
    width: 26px;
    display: block;
  }

  ${({ highlighted }) =>
    highlighted &&
    css`
      background: ${({ theme }) => theme.color.alabaster};
    `};
`;

export const Info = styled.div`
  display: block;

  margin-left: ${({ theme }) => theme.spacing.s};

  span {
    font-size: 16px;
    letter-spacing: -0.13px;
  }

  svg {
    margin-left: ${({ theme }) => theme.spacing.xs};
    width: 8px;
  }
`;
