import { StorageId } from '../types/StorageId';
import { config } from '../config';
import { getHeaders } from './getHeaders';

export const getRoofers = (email?: string, allowOverride: boolean = true) => {
  const overrideEmail =
    email || (allowOverride && localStorage.getItem(StorageId.EmailOverride));
  const params = overrideEmail
    ? `?emailOverride=${encodeURIComponent(overrideEmail)}`
    : '';
  return fetch(
    `${config.apiBaseUrl}/api/v1/sales-consultants/me/roofers${params}`,
    {
      headers: getHeaders(),
    },
  );
};
