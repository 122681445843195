import React, { FC } from 'react';
import { trackEvent } from '../../util/trackEvent';
import { ReactComponent as PhoneIcon } from '@meindach/ui-kit/dist/assets/icons/phone.svg';
import { ReactComponent as MailIcon } from '@meindach/ui-kit/dist/assets/icons/mail.svg';
import { Action } from './styled';
import { P, formatPhoneNumber } from '@meindach/ui-kit';
import { Consultant } from '../../types/Consultant';

export type ContactActionsProps = Pick<Consultant, 'id' | 'phone' | 'email'>;

export const ContactActions: FC<ContactActionsProps> = ({
  id,
  phone,
  email,
}) => (
  <>
    <Action
      href={`tel:${phone}`}
      onClick={() => trackEvent('consultant', 'call', id)}
    >
      <PhoneIcon />
      <P>{formatPhoneNumber(phone)}</P>
    </Action>
    <Action
      href={`mailto:${email}`}
      onClick={() => trackEvent('consultant', 'email', id)}
    >
      <MailIcon />
      <P>{email}</P>
    </Action>
  </>
);
