import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Home } from './routes/home/Home';
import { Login } from './routes/login/Login';
import { Callback } from './routes/callback/Callback';
import { Route as RouteType } from './types/Route';
import { Roofers } from './routes/roofers/Roofers';
import { PrivateRoute } from './routes/PrivateRoute';
import { PublicRoute } from './routes/PublicRoute';
import { Account } from './routes/account/Account';
import { EmailVerified } from './routes/emailVerified/EmailVerified';
import { Help } from './routes/help/Help';
import { Logout } from './routes/logout/Logout';
import { Consultants } from './routes/consultants/Consultants';
import { Opportunities } from './routes/opportunities/Opportunities';

export const Routes = () => (
  <Switch>
    <Route path={RouteType.Root} exact component={Home} />
    <Route path={RouteType.Help} component={Help} />
    <Route path={RouteType.Callback} component={Callback} />
    <PublicRoute path={RouteType.Login} component={Login} />
    <PublicRoute path={RouteType.Logout} component={Logout} />
    <PublicRoute path={RouteType.EmailVerified} component={EmailVerified} />
    <PrivateRoute path={RouteType.Consultants} component={Consultants} />
    <PrivateRoute path={RouteType.Roofers} component={Roofers} />
    <PrivateRoute path={RouteType.Opportunities} component={Opportunities} />
    <PrivateRoute path={RouteType.Account} component={Account} />
    <Redirect to={RouteType.Root} />
  </Switch>
);
