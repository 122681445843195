import React, { FC, memo, MouseEvent } from 'react';
import {
  AvatarContainer,
  StyledAvatar,
  Content,
  Tags,
  Name,
  StyledCard,
  Actions,
} from './styled';
import { ReactComponent as RooferIcon } from '@meindach/ui-kit/dist/assets/icons/roofer.svg';
import { ReactComponent as OpportunityIcon } from '@meindach/ui-kit/dist/assets/icons/house.svg';
import { ReactComponent as InfoIcon } from '@meindach/ui-kit/dist/assets/icons/info.svg';
import { ReactComponent as UserIcon } from '@meindach/ui-kit/dist/assets/icons/consultant.svg';
import { ReactComponent as Chevron } from '@meindach/ui-kit/dist/assets/ui/chevronDown.svg';
import { Tag, CardProps, P, scrollParentToChildPos } from '@meindach/ui-kit';
import { Consultant } from '../../types/Consultant';
import { config } from '../../config';

interface Props extends Omit<CardProps, 'onClick' | 'onMouseEnter'> {
  consultant: Pick<
    Consultant,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'rooferCount'
    | 'salesConsultantManager'
    | 'opportunityCount'
    | 'imageHash'
  >;
  mobile?: boolean;
  onClick?: (id: string) => any;
  onMouseEnter?: (id: string) => any;
  onManagerClick?: (name: string) => void;
  t: (key: string) => string;
}

export const scrollToCard = (id: string, instant: boolean = false) =>
  scrollParentToChildPos(
    document.querySelector(`[data-id=card${id}]`),
    instant,
  );

export const ConsultantCard: FC<Props> = memo(
  ({
    consultant,
    onClick: handleClick,
    onMouseEnter: handleEnter,
    onManagerClick: handleManagerClick,
    mobile,
    t,
    ...rest
  }) => {
    const {
      id,
      firstName,
      lastName,
      rooferCount,
      salesConsultantManager,
      opportunityCount,
      imageHash,
    } = consultant;
    const onClick = handleClick ? () => handleClick(id) : undefined;
    const onMouseEnter = handleEnter ? () => handleEnter(id) : undefined;
    const onManagerClick = handleManagerClick
      ? () => handleManagerClick(salesConsultantManager)
      : undefined;
    return (
      <StyledCard
        onMouseEnter={onMouseEnter}
        {...rest}
        data-id={`card${id}`}
        directorView={!!onManagerClick}
      >
        <AvatarContainer onClick={onClick}>
          <StyledAvatar
            firstName={firstName}
            lastName={lastName}
            src={
              imageHash &&
              `${config.apiBaseUrl}/api/v1/public/images/sales-consultants/${id}/${imageHash}/size?assetType=THUMBNAIL`
            }
          />
        </AvatarContainer>
        <Content onClick={onClick}>
          <Name>
            {firstName} {lastName}
          </Name>
          <Tags>
            {mobile && onManagerClick && (
              <P
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  onManagerClick();
                }}
              >
                {salesConsultantManager}
                <Chevron />
              </P>
            )}
            {!mobile && (
              <>
                <Tag>
                  <RooferIcon />
                  {rooferCount}
                </Tag>
                <Tag>
                  <OpportunityIcon />
                  {opportunityCount}
                </Tag>
              </>
            )}
          </Tags>
        </Content>
        <Actions>
          {!mobile && onManagerClick && (
            <div onClick={onManagerClick}>
              <UserIcon />
              <span>{salesConsultantManager}</span>
              <Chevron />
            </div>
          )}
          <div onClick={onClick}>
            <InfoIcon />
            <span>{t('consultantCard:info')}</span>
            <Chevron />
          </div>
        </Actions>
      </StyledCard>
    );
  },
);
