import styled, { css } from '../../styled-components';
import { Label } from '../typography/label/Label';
import { media } from '../../styles/media';

interface ContainerProps {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
}

export const Container = styled.div<ContainerProps>`
  transition: color 0.25s, border-color 0.25s, transform 0.25s;
  position: relative;
  height: 22px;
  width: 22px;
  border: 1px solid ${({ theme }) => theme.color.silver};
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.silver};
  border-radius: 4px;
  box-sizing: border-box;

  flex-shrink: 0;
  flex-grow: 0;

  ${media.md`
    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  `}

  input {
    position: absolute;
    width: 22px;
    height: 22px;
    opacity: 0;
    cursor: pointer;
  }

  svg {
    transition: opacity 0.1s, margin 0.25s;
    width: 100%;
    height: 100%;
    padding: 3px;
    box-sizing: border-box;
    pointer-events: none;
    opacity: 0;
    margin-top: 10px;
    overflow: hidden;

    ${({ checked }) =>
      checked &&
      css`
        opacity: 1;
        margin-top: 1px;
      `}
  }

  ${({ checked }) =>
    checked &&
    css`
      color: ${({ theme }) => theme.color.blue};
      border-color: ${({ theme }) => theme.color.blue};
    `}

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.color.error};
      border-color: ${({ theme }) => theme.color.error};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.color.silver};
      background-color: ${({ theme }) => theme.color.alabaster};
      border-color: ${({ theme }) => theme.color.silver};
    `}
`;

export const CheckBoxLabel = styled(Label)`
  margin-left: ${({ theme }) => theme.spacing.s};
  margin-top: 2px;
  user-select: none;

  ${media.md`
    margin-top: 0;
  `}
`;

interface GroupProps {
  hasLabel: boolean;
}

export const Group = styled.div<GroupProps>`
  display: flex;

  ${({ hasLabel }) =>
    hasLabel &&
    media.md`
      ${Container} {
        margin-top: ${({ theme }) => theme.spacing.xs};
      }
    `}
`;
