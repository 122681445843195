import styled, { css } from '../../styled-components';

export interface ContainerProps {
  /**
   * @default 'default'
   */
  type?: 'default' | 'info' | 'warning' | 'success';
}

export const Container = styled.div<ContainerProps>`
  height: 25px;
  border-radius: 16px;
  padding: 0 ${({ theme }) => theme.spacing.s};

  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 25px;
  text-align: center;

  display: inline-flex;
  justify-content: center;

  > svg {
    max-height: 15px;
    max-width: 15px;
    margin: auto ${({ theme }) => theme.spacing.xs} auto 0;
  }

  ${({ type }) => {
    switch (type) {
      case 'warning':
        return css`
          border: 1px solid #ffae11;
          color: #ffae11;
        `;
      case 'success':
        return css`
          border: 1px solid #4e945b;
          color: #4e945b;
        `;
      case 'info':
        return css`
          border: 1px solid #6f809e;
          color: #6f809e;
        `;
    }
    return css`
      border: 1px solid ${({ theme }) => theme.color.slate};
      color: ${({ theme }) => theme.color.slate};
    `;
  }}
`;
