import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import de from './de.json';

interface Resources {
  [language: string]: any;
}

const resources: Resources = { de };
const languages = ['de'];

const debug = process.env.NODE_ENV === 'development';
if (debug) {
  // Casting as de to make sure both resources have matching keys
  resources.en = en as typeof de;
  languages.push('en');
}

i18n.languages = languages;
i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    debug,
    resources,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false, // not needed for react
    },
  });

export default i18n;
