import React, { FC, memo } from 'react';
import { Container, StyledLabel, RadioButtonContainer } from './styled';

interface Props {
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  error?: boolean;
  onClick?: () => any;
  className?: string;
}

export const RadioButton: FC<Props> = memo(
  ({ name, value, checked, disabled, error, label, onClick, className }) => (
    <RadioButtonContainer
      hasLabel={!!label}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      className={className}
    >
      <Container disabled={disabled} checked={checked} error={error}>
        <input
          type="radio"
          name={name}
          value={value}
          defaultChecked={checked}
          disabled={disabled}
        />
      </Container>
      {label && (
        <StyledLabel htmlFor={name} checked={checked} disabled={disabled}>
          {label}
        </StyledLabel>
      )}
    </RadioButtonContainer>
  ),
);
