import styled from '../../styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const CardContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
`;
