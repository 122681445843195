import React, { useState, ChangeEvent, InputHTMLAttributes } from 'react';
import { Input } from './Input';

type InputProps = InputHTMLAttributes<HTMLInputElement>;
export type NumericInputProps = Omit<
  InputProps,
  'onChange' | 'onBlur' | 'type'
> & {
  /**
   * Boolean whether float values are allowed. If set to false, only integer values will be valid.
   */
  float?: boolean;
  onChange: (value: number) => void;
  onBlur?: () => void;
  error?: boolean;
};

export const NumericInput = ({
  value: controlledValue = '',
  float,
  onBlur,
  onChange,
  ...props
}: NumericInputProps) => {
  const [value, setValue] = useState<string>(`${controlledValue}`);
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    const isValid = () =>
      float
        ? /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/.test(value)
        : /^-?(0|[1-9][0-9]*)$/.test(value);
    if (
      (!Number.isNaN(parseFloat(value)) && isValid()) ||
      value === '' ||
      value === '-'
    ) {
      onChange(parseFloat(value));
      setValue(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    if (value === '-' || (float && value.charAt(value.length - 1) === '.')) {
      const newValue = value.slice(0, -1);
      onChange(parseFloat(newValue));
      setValue(newValue);
      return;
    }
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <Input
      {...(float
        ? { pattern: '[0-9]+([.,][0-9]+)?', step: '0.01' }
        : { pattern: '[0-9]*' })}
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
