import styled, { css } from '../../styled-components';
import { Link, P } from '@meindach/ui-kit';

const actionStyle = css`
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  height: 43px;
  display: flex;
  align-items: center;

  > * {
    color: ${({ theme }) => theme.color.blue};
  }

  > ${P} {
    font-weight: 600;
  }

  > svg {
    margin-right: ${({ theme }) => theme.spacing.s};
    width: 25px;
    height: 25px;
  }
`;

export const LinkAction = styled(Link)`
  ${actionStyle}
`;

export const Action = styled.a`
  ${actionStyle}
`;
