import React, { FC } from 'react';
import { Opportunity } from '../../types/Opportunity';
import { useTranslation } from 'react-i18next';
import { EntryLabel, EntryValue, InfoContainer, Loading } from './styled';
import { capitalizeFirstLetter } from '@meindach/ui-kit';
import { useFieldTranslations } from '../../hooks/useFieldTranslations';
import { translatedFieldNames } from '../../data/translatedFieldNames';

export const InfoTable: FC<Opportunity> = ({
  requestType,
  reason,
  buildingType,
  roofSize,
  roofType,
  maskCurrentTile,
  tileShapeType,
  windowTypes,
  windowsNumberActual,
  windowsNumberTarget,
  insulation,
  maskEnergyRoof,
  maskEnergeticRoof,
  maskTopFloor,
  maskAtticInsulation,
  constructionYear,
  tileColorType,
  tileFinishType,
  financeTypes,
  maskSpecialities,
  maskConstructionPlans,
  budget,
}) => {
  const { t } = useTranslation('opportunityInfoTable');
  const { fields } = useFieldTranslations(translatedFieldNames);
  if (!fields) {
    return <Loading />;
  }
  const empty = '-';
  return (
    <InfoContainer>
      <EntryLabel>{t('requestType')}</EntryLabel>
      <EntryValue>{fields.requestType[requestType] || empty}</EntryValue>
      <EntryLabel>{t('opportunityInfo:restorationReason')}</EntryLabel>
      <EntryValue>{fields.reason[reason] || empty}</EntryValue>
      <EntryLabel>{t('buildingType')}</EntryLabel>
      <EntryValue>{fields.buildingType[buildingType] || empty}</EntryValue>
      <EntryLabel>{t('constructionYear')}</EntryLabel>
      <EntryValue>{constructionYear || empty}</EntryValue>
      <EntryLabel>{t('roofType')}</EntryLabel>
      <EntryValue>{fields.roofType[roofType] || empty}</EntryValue>
      <EntryLabel>{t('roofSize')}</EntryLabel>
      <EntryValue>
        {isNaN(parseFloat(roofSize)) ? roofSize : `ca. ${roofSize} m²`}
      </EntryValue>
      <EntryLabel>{t('maskCurrentTile')}</EntryLabel>
      <EntryValue>
        {fields.maskCurrentTile[maskCurrentTile] || empty}
      </EntryValue>
      <EntryLabel>{t('tileShapeType')}</EntryLabel>
      <EntryValue>{fields.tileShapeType[tileShapeType] || empty}</EntryValue>
      <EntryLabel>{t('tileColorType')}</EntryLabel>
      <EntryValue>{fields.tileColorType[tileColorType] || empty}</EntryValue>
      <EntryLabel>{t('tileFinishType')}</EntryLabel>
      <EntryValue>{fields.tileFinishType[tileFinishType] || empty}</EntryValue>
      <EntryLabel>{t('maskAtticInsulation')}</EntryLabel>
      <EntryValue>
        {maskAtticInsulation ? t(`common:${maskAtticInsulation}`) : empty}
      </EntryValue>
      <EntryLabel>{t('maskTopFloor')}</EntryLabel>
      <EntryValue>
        {maskTopFloor ? t(`common:${maskTopFloor}`) : empty}
      </EntryValue>
      <EntryLabel>{t('maskEnergeticRoof')}</EntryLabel>
      <EntryValue>{maskEnergeticRoof || empty}</EntryValue>
      <EntryLabel>{t('insulation')}</EntryLabel>
      <EntryValue>{fields.insulation[insulation] || empty}</EntryValue>
      <EntryLabel>{t('budget')}</EntryLabel>
      <EntryValue>{fields.maskBudget[budget] || empty}</EntryValue>
      <EntryLabel>{t('financeTypes')}</EntryLabel>
      <EntryValue>
        {financeTypes.map((type) => fields.financeType[type]).join(', ') ||
          empty}
      </EntryValue>
      <EntryLabel>{t('windowType')}</EntryLabel>
      <EntryValue>
        {windowTypes.map((type) => fields.windowType[type]).join(', ') || empty}
      </EntryValue>
      <EntryLabel>{t('windowCountActual')}</EntryLabel>
      <EntryValue>{windowsNumberActual || empty}</EntryValue>
      <EntryLabel>{t('windowCountTarget')}</EntryLabel>
      <EntryValue>{windowsNumberTarget || empty}</EntryValue>
      <EntryLabel>{t('maskEnergy')}</EntryLabel>
      <EntryValue>{fields.maskEnergyRoof[maskEnergyRoof] || empty}</EntryValue>
      <EntryLabel>{t('maskSpecialities')}</EntryLabel>
      <EntryValue>
        {maskSpecialities
          .map((type) => fields.maskSpecialities[type])
          .join(', ') || empty}
      </EntryValue>
      <EntryLabel>{t('maskConstructionPlans')}</EntryLabel>
      <EntryValue>
        {maskConstructionPlans
          ? capitalizeFirstLetter(maskConstructionPlans)
          : empty}
      </EntryValue>
    </InfoContainer>
  );
};
