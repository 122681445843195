import React, { FC } from 'react';
import { useRoofer } from '../../hooks/useRoofer';
import { RooferModal } from '../rooferModal/RooferModal';
import { useId } from '../../hooks/useId';
import { Store } from '../../types/Store';
import { usePlatform } from '@meindach/ui-kit';
import { RooferInfoPanel } from '../rooferInfoPanel/RooferInfoPanel';

type Props = ReturnType<typeof useId>;

const Info: FC<Props> = ({ id, setId }) => {
  const { mobile } = usePlatform();
  const roofer = useRoofer(id);
  if (!roofer) {
    return null;
  }
  const Component = mobile ? RooferModal : RooferInfoPanel;
  return <Component roofer={roofer} onClose={() => setId(null)} />;
};

export const SelectedRooferInfo = () => {
  const data = useId(Store.RooferSelectedId);
  if (!data.id) {
    return null;
  }
  return <Info {...data} />;
};
