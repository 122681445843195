import { useEffect, useState } from 'react';
import { Coordinates } from '../types/Coordinates';
import { getZoomLevel } from '../util/getZoomLevel';

interface State {
  latitude?: number;
  longitude?: number;
  zoom?: number;
}

interface Entry {
  latLon: Coordinates;
}

export const usePositionAndZoom = (list: Entry[]) => {
  const [state, setState] = useState<State>({});

  useEffect(() => {
    if (state.latitude || state.longitude) {
      return;
    }
    const coordinates = list.map(({ latLon }) => latLon);
    const averageLat =
      coordinates.reduce((a, b) => a + b.lat, 0) / coordinates.length;
    const averageLon =
      coordinates.reduce((a, b) => a + b.lon, 0) / coordinates.length;

    setState({
      latitude: isNaN(averageLat) ? undefined : averageLat,
      longitude: isNaN(averageLon) ? undefined : averageLon,
      zoom: getZoomLevel(coordinates),
    });
  }, [state.latitude, state.longitude, list]);

  return state;
};
