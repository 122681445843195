import React, { useCallback, useEffect } from 'react';
import { Container, SortContainer } from './styled';
import { RooferCard } from '../rooferCard/RooferCard';
import { useTranslation } from 'react-i18next';
import { useFilteredRoofers } from '../../hooks/useFilteredRoofers';
import { NoRoofersFound } from './NoRoofersFound';
import { usePlatform } from '@meindach/ui-kit';
import { useSort } from '../../hooks/useSort';
import { sortByName } from '../../util/sortByName';
import { LoadingRoofers } from './LoadingRoofers';
import { RooferSearch } from '../rooferFilters/RooferSearch';
import { ListSort } from '../listSort/ListSort';
import { Sort } from '../../types/Sort';
import { trackEvent } from '../../util/trackEvent';
import { Store } from '../../types/Store';
import { useId } from '../../hooks/useId';

export const RooferList = () => {
  const { t } = useTranslation();
  const { sort, setSort } = useSort(Store.RooferSort);
  const { mobile } = usePlatform();
  const { roofers, fetching } = useFilteredRoofers();
  const { id, setId } = useId(Store.RooferSelectedId);
  const { id: hoverId, setId: setHoverId } = useId(Store.RooferHoverId);
  const sortChange = useCallback(
    (sort: Sort) => {
      trackEvent('roofer', 'sort', sort);
      setSort(sort);
    },
    [setSort],
  );
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    if (id) {
      setId(id);
    }
  }, [setId]);
  return (
    <Container>
      <SortContainer>
        <RooferSearch />
        <ListSort sort={sort} setSort={sortChange} />
      </SortContainer>
      {fetching && <LoadingRoofers />}
      {!fetching && roofers.length < 1 && <NoRoofersFound />}
      {!fetching &&
        sortByName(sort, roofers).map((roofer, index) => (
          <RooferCard
            key={roofer.id}
            id={index + 1}
            roofer={roofer}
            setId={setId}
            setHoverId={setHoverId}
            selected={roofer.id === id}
            highlighted={roofer.id === hoverId}
            mobile={mobile}
            t={t}
          />
        ))}
    </Container>
  );
};
