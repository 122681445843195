import { useRoofers } from './useRoofers';
import { useRooferFilters } from './useRooferFilters';
import { useMemo } from 'react';

export const useFilteredRoofers = () => {
  const { roofers: allRoofers, fetching } = useRoofers();
  const { partners, types, zipCode, search } = useRooferFilters();

  const roofers = useMemo(() => {
    if (!allRoofers) {
      return [];
    }
    return allRoofers
      .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
      .filter(
        ({ partner }) => partners.length < 1 || partners.includes(partner),
      )
      .filter(
        (roofer) =>
          types.length < 1 ||
          (types.includes('new') && roofer.new) ||
          (types.includes('plus') && roofer.plusPartner),
      )
      .filter(
        ({ postalCode }) =>
          zipCode.length < 1 || postalCode.startsWith(zipCode),
      );
  }, [allRoofers, partners, types, zipCode, search]);

  return { roofers, fetching };
};
