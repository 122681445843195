import React, { FC, useState, memo } from 'react';
import { Group } from './styled';
import { RadioButton } from './RadioButton';

export interface RadioGroupOption {
  value: string;
  label?: string;
  disabled?: boolean;
}

interface Props {
  name?: string;
  selectedOption?: string;
  options: RadioGroupOption[];
  onChange: (value: string) => any;
}

export const RadioGroup: FC<Props> = memo(
  ({ name = `${Math.random()}`, options, selectedOption, onChange }) => {
    const [selected, setSelected] = useState(selectedOption);
    const onClick = (value: string) => {
      setSelected(value);
      onChange(value);
    };
    return (
      <Group>
        {options.map(({ value, ...rest }) => (
          <RadioButton
            key={value}
            name={name}
            checked={value === selected}
            value={value}
            {...rest}
            onClick={() => selected !== value && onClick(value)}
          />
        ))}
      </Group>
    );
  },
);
