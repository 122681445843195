import React, { FC, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Title, P } from '@meindach/ui-kit';
import { useTranslation, Trans } from 'react-i18next';
import { Container, IllustrationContainer, Content } from './styled';
import { ReactComponent as Illustration } from '../../assets/illustration-start.svg';
import { RouteComponentProps } from 'react-router';
import { LoginButton } from '../../components/loginButton/LoginButton';

type Props = Pick<RouteComponentProps, 'location'>;

export const Login: FC<Props> = ({ location }) => {
  const { t } = useTranslation('login');
  const { login } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const redirect = params.get('redirect');
    if (redirect) {
      login();
    }
  }, [location.search, login]);

  return (
    <Container>
      <Content>
        <Title xl>{t('title')}</Title>
        <P>
          <Trans i18nKey="login:info">
            <strong>Bold</strong>Rest
          </Trans>
        </P>
        <LoginButton />
        <IllustrationContainer>
          <Illustration />
        </IllustrationContainer>
      </Content>
    </Container>
  );
};
