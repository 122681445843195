import React from 'react';
import { Container, HeaderSection } from './styled';
import { Button, Title, usePlatform } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { UserDataList } from './UserDataList';
import { trackEvent } from '../../util/trackEvent';

export const Account = () => {
  const { t } = useTranslation('account');
  const { logout } = useAuth();
  const { mobile } = usePlatform();
  const onClick = () => {
    trackEvent('user', 'logout');
    logout();
  };
  return (
    <Container>
      <HeaderSection>
        <Title l>{t('pageTitle:account')}</Title>
        <p>
          <span>{t('subtitle')}</span>
        </p>
      </HeaderSection>
      <UserDataList />
      <Button onClick={onClick} fullWidth={mobile}>
        {t('logout')}
      </Button>
    </Container>
  );
};
