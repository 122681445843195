import styled, { css } from '../../styled-components';
import { Label } from '../typography/label/Label';
import { touchSupport } from '../../utils/touchSupport';
import { ReactComponent as ChevronDown } from '../../assets/ui/chevronDown.svg';

interface SelectProps {
  small?: boolean;
  error?: boolean;
}

interface ContainerProps extends SelectProps {
  open?: boolean;
  selected?: boolean;
}

export const SelectContainer = styled.div<SelectProps & { desktop: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.s};
  box-sizing: border-box;

  height: ${({ small }) => (small ? 30 : 42)}px;

  > select {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    /* Set as 16px for mobiles to not zoom in when clicking */
    font-size: 16px;

    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}
  }

  > svg {
    margin-left: ${({ theme }) => theme.spacing.s};
    flex-shrink: 0;
  }
`;

export const StyledChevron = styled(ChevronDown)``;

export const Container = styled.div<ContainerProps>`
  position: relative;
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.silver};
  box-sizing: border-box;

  border-radius: 2px;
  ${({ selected, theme }) => css`
    border: 1px solid ${selected ? theme.color.blue : theme.color.silver};
  `}

  width: 100%;
  cursor: pointer;

  ${SelectContainer} > svg {
    color: ${({ theme }) => theme.color.blue};
  }

  ${({ open }) =>
    open &&
    css`
      border-color: ${({ theme }) => theme.color.blue};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      ${SelectContainer} > ${StyledChevron} {
        transform: rotate(180deg);
      }
    `}

  ${({ error }) =>
    error
      ? css`
          border-color: ${({ theme }) => theme.color.error};
        `
      : !touchSupport &&
        css`
          &:hover {
            border-color: ${({ theme }) => theme.color.blue};
          }
        `}
`;

export const StyledLabel = styled(Label)`
  width: 100%;
  color: ${({ theme }) => theme.color.storm};
  pointer-events: none;
`;

export const Placeholder = styled(Label)`
  width: 100%;
  pointer-events: none;
`;

export const OptionsContainer = styled.div<SelectProps>`
  z-index: 1;
  position: absolute;
  top: ${({ small }) => (small ? 30 : 42)}px;
  left: -1px;
  overflow-y: auto;

  max-height: 154px;
  background: ${({ theme }) => theme.color.white};

  border-radius: 0 0 2px 2px;
  border: 1px solid ${({ theme }) => theme.color.blue};

  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.color.error};
    `}
`;

interface Props {
  value: string;
  disabled?: boolean;
  selected?: boolean;
}

export const Option = styled.div<Props>`
  color: ${({ theme }) => theme.color.silver};
  height: 44px;
  padding: ${({ theme }) => theme.spacing.s};
  box-sizing: border-box;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  > * {
    pointer-events: none;
  }

  > :nth-child(2) {
    margin-left: ${({ theme }) => theme.spacing.s};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      background: ${({ theme }) => theme.color.mercury};
      > * {
        color: ${({ theme }) => theme.color.storm};
      }
    `}

  ${({ selected, disabled }) =>
    !disabled &&
    selected &&
    css`
      background: ${({ theme }) => theme.color.cyan200};

      ${!touchSupport &&
      css`
        &:hover > * {
          color: ${({ theme }) => theme.color.blue};
        }
      `}
    `}

  ${({ selected, disabled }) =>
    !disabled &&
    !selected &&
    !touchSupport &&
    css`
      &:hover {
        background: ${({ theme }) => theme.color.cyan100};
        > * {
          color: ${({ theme }) => theme.color.blue};
        }
      }
    `}
`;
