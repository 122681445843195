import React, { FC } from 'react';
import { trackEvent } from '../../util/trackEvent';
import { useOpportunityFilters } from '../../hooks/useOpportunityFilters';
import { StyledNumericSearch } from './styled';

interface Props {
  name?: string;
  placeHolder?: string;
}

export const ZipCodeFilter: FC<Props> = ({ name, placeHolder }) => {
  const { zipCode, setZipCode } = useOpportunityFilters();
  return (
    <StyledNumericSearch
      name={name}
      placeholder={placeHolder}
      value={zipCode}
      onChange={(value: number) => setZipCode(isNaN(value) ? '' : `${value}`)}
      onBlur={() => trackEvent('filterOpportunities', 'zipCode', zipCode)}
    />
  );
};
