import React, { FC, useEffect } from 'react';
import { Avatar, P } from '@meindach/ui-kit';
import { Content, Name, Header, CloseContainer } from './styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as OpportunityIcon } from '@meindach/ui-kit/dist/assets/icons/house.svg';
import { ReactComponent as Arrow } from '@meindach/ui-kit/dist/assets/icons/arrow-right.svg';
import { ReactComponent as InfoIcon } from '@meindach/ui-kit/dist/assets/icons/info.svg';
import { ReactComponent as RooferIcon } from '@meindach/ui-kit/dist/assets/icons/roofer.svg';
import { trackEvent } from '../../util/trackEvent';
import { Panel, PanelProps } from '../../components/panel/Panel';
import { Route } from '../../types/Route';
import { InfoSection } from './InfoSection';
import { Consultant } from '../../types/Consultant';
import { PanelActions } from '../../components/panel/PanelActions';
import { PanelTabs } from '../../components/panel/PanelTabs';
import { UserType } from '../../types/UserType';
import { config } from '../../config';

interface Props extends Pick<PanelProps, 'onClose'> {
  consultant: Consultant;
  userType: UserType;
}

export const ConsultantInfoPanel: FC<Props> = ({
  consultant,
  userType,
  onClose,
}) => {
  const { t } = useTranslation('consultantInfo');
  const {
    id,
    email,
    firstName,
    lastName,
    opportunityCount,
    rooferCount,
    imageHash,
  } = consultant;

  // Track event for opening
  useEffect(() => {
    trackEvent('consultant', 'openInfo', id);
  }, [id]);

  return (
    <Panel onClose={onClose} id={id}>
      {userType !== UserType.Consultant && (
        <CloseContainer>
          <Arrow onClick={onClose} />
          <P onClick={onClose}>{t('common:backToMap')}</P>
        </CloseContainer>
      )}
      <Header>
        <div>
          <Avatar
            big
            firstName={firstName}
            lastName={lastName}
            src={
              imageHash &&
              `${config.apiBaseUrl}/api/v1/public/images/sales-consultants/${id}/${imageHash}/size?assetType=THUMBNAIL`
            }
          />
        </div>
        <Content>
          <P>{t('title')}</P>
          <Name m ellipsis>
            {firstName} {lastName}
          </Name>
        </Content>
        {userType !== UserType.Consultant && (
          <PanelActions
            actions={[
              {
                icon: OpportunityIcon,
                text: `${opportunityCount} ${t('common:opportunities')}`,
                link: `${Route.Opportunities}#${email}`,
              },
              {
                icon: RooferIcon,
                text: `${rooferCount} ${t('common:roofers')}`,
                link: `${Route.Roofers}#${email}`,
              },
            ]}
          />
        )}
      </Header>
      <PanelTabs
        tabs={[
          {
            icon: InfoIcon,
            label: t('common:info'),
            component: (
              <InfoSection
                {...consultant}
                directorView={userType === UserType.Director}
              />
            ),
          },
        ]}
      />
    </Panel>
  );
};
