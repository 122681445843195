import styled, { css } from '../../../styled-components';
import { media } from '../../../styles/media';

export interface TitleProps {
  xl?: boolean;
  l?: boolean;
  m?: boolean;
  s?: boolean;
  xs?: boolean;
  ellipsis?: boolean;
}

export const Title = styled.p<TitleProps>`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  color: ${({ theme }) => theme.color.midnight};

  ${({ xl, l, m, s }) => {
    switch (true) {
      case xl:
        return fontXL;
      case l:
        return fontL;
      case m:
        return fontM;
      case s:
        return fontS;
    }
    return fontXS;
  }}

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;

const fontXL = css`
  font-weight: 300;
  font-size: 32px;
  line-height: 37px;
  letter-spacing: -0.27px;

  ${media.md`
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.4px;
  `}
`;

const fontL = css`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.2px;

  ${media.md`
    font-size: 32px;
    line-height: 37px;
    letter-spacing: -0.27px;
  `}
`;

const fontM = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.18px;

  ${media.md`
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
  `}
`;

const fontS = css`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  ${media.md`
    font-size: 20px;
    line-height: 33px;
  `}
`;

const fontXS = css`
  color: ${({ theme }) => theme.color.storm};
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.35px;

  ${media.md`
    font-size: 16px;
    line-height: initial;
    letter-spacing: 0.4px;
  `}
`;
