import { Sort } from '../types/Sort';
import { sortByKey, sortByKeyDesc } from '@meindach/ui-kit';
import { sortAlphabeticalAscending, sortByName } from './sortByName';

interface Type {
  dateEntered: number;
  name: string;
}

interface SortMethod<T> {
  (a: T, b: T): -1 | 1 | 0;
}

const sortBy = <T>(
  first: SortMethod<T>,
  second: SortMethod<T>,
): SortMethod<T> => (a, b) => {
  const result = first(a, b);
  return result === 0 ? second(a, b) : result;
};

const sortByNewest = sortByKeyDesc('dateEntered');
const sortByOldest = sortByKey('dateEntered');

const sortNewestByName = sortBy<Type>(sortByNewest, sortAlphabeticalAscending);
const sortOldestByName = sortBy<Type>(sortByOldest, sortAlphabeticalAscending);

export const sortByDate = <T extends Type>(sort: Sort, list: T[]) => {
  switch (sort) {
    case Sort.Newest:
      return [...list].sort(sortNewestByName);
    case Sort.Oldest:
      return [...list].sort(sortOldestByName);
  }
  return sortByName(sort, list);
};
