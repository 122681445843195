import { useState, useEffect } from 'react';
import { Opportunity } from '../types/Opportunity';
import { useOpportunityFilters } from './useOpportunityFilters';
import { useOpportunities } from './useOpportunities';

export const useFilteredOpportunities = () => {
  const { opportunities: allOpportunities, fetching } = useOpportunities();
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const {
    salesStages,
    startDates,
    roofTypes,
    zipCode,
    search,
  } = useOpportunityFilters();

  useEffect(() => {
    if (!allOpportunities) {
      setOpportunities([]);
      return;
    }
    const filteredRoofers = allOpportunities
      .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
      .filter(
        ({ salesStage }) =>
          salesStages.length < 1 || salesStages.includes(salesStage),
      )
      .filter(
        ({ startDate }) =>
          startDates.length < 1 || startDates.includes(startDate),
      )
      .filter(
        ({ roofType }) => roofTypes.length < 1 || roofTypes.includes(roofType),
      )
      .filter(
        ({ postalCode }) =>
          zipCode.length < 1 || postalCode.startsWith(zipCode),
      );
    setOpportunities(filteredRoofers);
  }, [allOpportunities, salesStages, startDates, roofTypes, zipCode, search]);

  return { opportunities, fetching };
};
