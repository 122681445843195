export const theme = {
  color: {
    red: '#C73838',
    red100: '#F5DBDB',
    red200: '#E9AFAF',
    red300: '#DF8E8E',
    red400: '#D56A6A',
    red500: '#C73838',
    red600: '#9E2D2D',
    red700: '#7B1F1F',

    blue: '#004976',
    blue100: '#D1DEE6',
    blue200: '#99B6C8',
    blue300: '#6795B2',
    blue400: '#407799',
    blue500: '#004976',
    blue600: '#00314F',
    blue700: '#00253B',

    cyan: '#9BD0F2',
    cyan100: '#EDF6FD',
    cyan200: '#D7ECFA',
    cyan300: '#C9E7FA',
    cyan400: '#B4DCF6',
    cyan500: '#9BD0F2',
    cyan600: '#4DB1EA',
    cyan700: '#0093E3',

    white: '#FFFFFF',
    pearl: '#FAFAFA',
    alabaster: '#F8F8F8',
    pumice: '#F5F5F8',
    fog: '#ECECEC',
    mercury: '#E7E7E7',
    midGrey: '#DFDFDF',
    smoke: '#D3D3D3',
    steel: '#BDBDBD',
    silver: '#9F9F9F',
    storm: '#636363',
    slate: '#3D3D3D',
    charcoal: '#262829',
    midnight: '#171717',

    alert: '#FFBB00',
    error: '#FE1515',
    success: '#67D798',

    nag: '#EA4E3D',
    nagDark: '#D13A2A',
    nagAcc: '#DC2C18',
    nagAccDark: '#B71200',
    green: '#92BB3E',
    greenDark: '#7FA334',
    greenAcc: '#5E7A29',
    greenAccDark: '#5E7A29',

    black: '#000000',
    shadow: 'rgba(0, 0, 0, 0.05)',
  },
  fontFamily: {
    primary: `'Assistant', Arial, sans-serif`,
    secondary: `'Roboto', sans-serif`,
  },
  header: {
    height: '100px',
    heightMobile: '70px',
  },
  footer: {
    height: '100px',
  },
  nav: {
    width: '250px',
    widthCollapsed: '50px',
    heightMobile: '80px',
  },
  layout: {
    maxWidth: '1440px',
  },
  button: {
    boxShadow:
      '0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)',
  },
  spacing: {
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '32px',
    xl: '64px',
  },
};
