import React, { useCallback } from 'react';
import { Container } from './styled';
import { NoConsultants } from './NoConsultants';
import { usePlatform } from '@meindach/ui-kit';
import { useSort } from '../../hooks/useSort';
import { sortByFullName } from '../../util/sortByFullName';
import { LoadingList } from './LoadingList';
import { ConsultantCard } from '../consultantCard/ConsultantCard';
import { useId } from '../../hooks/useId';
import { Store } from '../../types/Store';
import { useFilteredConsultants } from '../../hooks/useFilteredConsultants';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../types/UserType';
import { useUser } from '../../hooks/useUser';
import { ConsultantSort } from './ConsultantSort';

export const ConsultantList = () => {
  const { t } = useTranslation();
  const { sort } = useSort(Store.ConsultantSort);
  const { mobile } = usePlatform();
  const { id, setId } = useId(Store.ConsultantSelectedId);
  const { id: hoverId, setId: setHoverId } = useId(Store.ConsultantHoverId);
  const { setId: setManagerId } = useId(Store.ManagerSelectedId);
  const { user, fetching } = useUser();
  const { consultants } = useFilteredConsultants();

  const callback = useCallback((id: string) => setHoverId(id), [setHoverId]);
  const onMouseEnter = mobile ? undefined : callback;
  const onClick = useCallback(
    (id: string) => {
      setId(id);
      if (mobile) {
        setHoverId(id);
      }
    },
    [mobile, setId, setHoverId],
  );
  const isDirector = !!user && user.type === UserType.Director;
  const onManagerClick = isDirector ? setManagerId : undefined;

  return (
    <Container>
      <ConsultantSort />
      {fetching && <LoadingList />}
      {!fetching && consultants.length < 1 && <NoConsultants />}
      {!fetching &&
        sortByFullName(sort, consultants).map((consultant) => (
          <ConsultantCard
            key={consultant.id}
            consultant={consultant}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onManagerClick={onManagerClick}
            selected={consultant.id === id}
            highlighted={consultant.id === hoverId}
            mobile={mobile}
            t={t}
          />
        ))}
    </Container>
  );
};
