import { breakpoints } from '../styles/media';
import { useState, useEffect } from 'react';

interface Platform {
  desktop: boolean;
  mobile: boolean;
}

const mediaQueryList = matchMedia(`(min-width: ${breakpoints.md}px)`);

export const usePlatform = (): Platform => {
  const [desktop, setDesktop] = useState<boolean>(mediaQueryList.matches);

  useEffect(() => {
    const onChange = () => setDesktop(mediaQueryList.matches);

    mediaQueryList.addListener(onChange);
    return () => {
      mediaQueryList.removeListener(onChange);
    };
  }, []);

  return {
    mobile: !desktop,
    desktop,
  };
};
