import React, { FC } from 'react';
import {
  Details,
  MapContainer,
  PassiveAction,
  Interests,
  Loading,
} from './styled';
import { P } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import {
  OpportunityContactActions,
  ContactActionsProps,
} from '../../components/opportunityContactActions/OpportunityContactActions';
import { Opportunity } from '../../types/Opportunity';
import { SatelliteMap } from '../../components/map/SatelliteMap';
import { OpportunityPin } from '../../components/opportunityMarker/OpportunityPin';
import { ReactComponent as CalendarIcon } from '@meindach/ui-kit/dist/assets/icons/calendar.svg';
import { useFieldTranslations } from '../../hooks/useFieldTranslations';
import { translatedFieldNames } from '../../data/translatedFieldNames';

type Props = Pick<
  Opportunity,
  | 'id'
  | 'latLon'
  | 'roofType'
  | 'salesStage'
  | 'startDate'
  | 'specialInterests'
  | 'hideMap'
> &
  ContactActionsProps;

export const InfoSection: FC<Props> = ({
  id,
  latLon,
  roofType,
  salesStage,
  startDate,
  specialInterests,
  hideMap,
  ...rest
}) => {
  const { t } = useTranslation('opportunityInfo');
  const { fields } = useFieldTranslations(translatedFieldNames);
  if (!fields) {
    return <Loading />;
  }
  const Pin = (
    <OpportunityPin salesStage={salesStage} roofType={roofType} hovered />
  );
  return (
    <Details>
      {!hideMap && (
        <MapContainer>
          <SatelliteMap {...latLon} pinIcon={Pin} />
        </MapContainer>
      )}
      <OpportunityContactActions id={id} latLon={latLon} {...rest} />
      <PassiveAction>
        <P>
          <CalendarIcon />
        </P>
        <P>{fields.startDate[startDate]}</P>
      </PassiveAction>
      <br />
      <P>{t('features')}</P>
      <Interests>
        {specialInterests
          ? specialInterests.split('\n').map((interest, index) => (
              <span key={index}>
                {interest}
                <br />
              </span>
            ))
          : t('emptyFeatures')}
      </Interests>
    </Details>
  );
};
