import React, { FC } from 'react';
import { useId } from '../../hooks/useId';
import { Store } from '../../types/Store';
import { useUser } from '../../hooks/useUser';
import { ConsultantInfoModal } from '../consultantInfoModal/ConsultantInfoModal';
import { UserType } from '../../types/UserType';
import { useConsultants } from '../../hooks/useConsultants';
import { usePlatform } from '@meindach/ui-kit';
import { ConsultantInfoPanel } from '../consultantInfoPanel/ConsultantInfoPanel';
import { useAllConsultants } from '../../hooks/useAllConsultants';

type Props = ReturnType<typeof useId>;

const Info: FC<Props> = ({ id, setId }) => {
  const { user } = useUser();
  const { mobile } = usePlatform();
  const isConsultant = !!user && user.type === UserType.Consultant;
  const allConsultants = useAllConsultants().consultants;
  const managedConsultants = useConsultants();
  const consultants = isConsultant ? allConsultants : managedConsultants;

  const consultant = consultants.find((consultant) => consultant.id === id);
  if (!user || !consultant) {
    return null;
  }
  const Component = mobile ? ConsultantInfoModal : ConsultantInfoPanel;
  return (
    <Component
      consultant={consultant}
      userType={user.type}
      onClose={() => setId(null)}
    />
  );
};

export const SelectedConsultantInfo = () => {
  const data = useId(Store.ConsultantSelectedId);
  if (!data.id) {
    return null;
  }
  return <Info {...data} />;
};
