import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { Container, PanelStyle } from './styled';
import { useLocationChangeCallback } from '../../hooks/useLocationChangeCallback';
import { withRouter, RouteComponentProps } from 'react-router';
import { useUniquePanel } from '../../hooks/useUniquePanel';

export interface PanelProps {
  id: string;
  onClose: () => void;
}

type Props = PanelProps & RouteComponentProps;

const Component: FC<Props> = ({ id, location, onClose, children }) => {
  const container = document.getElementById('panelContainer');

  useUniquePanel(id, onClose);
  useLocationChangeCallback(location, onClose);

  if (!container) {
    return null;
  }
  return createPortal(
    <Container>
      <PanelStyle />
      {children}
    </Container>,
    container,
  );
};

export const Panel = withRouter(Component);
