import React from 'react';
import { StyledOwnerBar } from './styled';
import { useTranslation } from 'react-i18next';
import { RoofTypeFilter } from './RoofTypeFilter';
import { ZipCodeFilter } from './ZipCodeFilter';
import { PageHeader } from '../pageHeader/PageHeader';
import { SalesStageFilter } from './SalesStageFilter';
import { StartDateFilter } from './StartDateFilter';

export const DesktopOpportunityFilters = () => {
  const { t } = useTranslation('filters');
  return (
    <PageHeader>
      <StyledOwnerBar />
      <RoofTypeFilter placeHolder={t('roofTypes')} />
      <SalesStageFilter placeHolder={t('salesStages')} />
      <StartDateFilter placeHolder={t('startDates')} />
      <ZipCodeFilter placeHolder={t('zipCode')} />
    </PageHeader>
  );
};
