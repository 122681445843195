import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/ui/close.svg';

export const StyledCloseIcon = styled(CloseIcon)``;

export const Container = styled.div`
  position: relative;

  input {
    padding-right: 44px;
  }

  &:hover {
    /* stylelint-disable-next-line selector-type-no-unknown */
    svg:not(${StyledCloseIcon}) {
      color: ${({ theme }) => theme.color.blue};
    }
  }

  svg {
    pointer-events: none;
    color: ${({ theme }) => theme.color.silver};
    width: 26px;
    height: 26px;

    position: absolute;
    right: 0;
    padding: 9px;
  }
  ${StyledCloseIcon} {
    pointer-events: all;
    padding: 12px;
    width: 20px;
    height: 20px;

    &:hover {
      color: ${({ theme }) => theme.color.blue};
    }
  }
`;
