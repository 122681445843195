import React from 'react';
import { StyledConsultantBar } from './styled';
import { useTranslation } from 'react-i18next';
import { PartnerFilter } from './PartnerFilter';
import { TypeFilter } from './TypeFilter';
import { ZipCodeFilter } from './ZipCodeFilter';
import { PageHeader } from '../pageHeader/PageHeader';

export const DesktopRooferFilters = () => {
  const { t } = useTranslation('filters');
  return (
    <PageHeader>
      <StyledConsultantBar />
      <PartnerFilter placeHolder={t('partners')} />
      <TypeFilter placeHolder={t('type')} />
      <ZipCodeFilter placeHolder={t('zipCode')} />
    </PageHeader>
  );
};
