import React, { FC } from 'react';
import { Details } from './styled';
import { Entry } from './styled';
import { Roofer } from '../../types/Roofer';
import { P, capitalizeFirstLetter, timestampToDate } from '@meindach/ui-kit';
import { useTranslation } from 'react-i18next';
import { TestAttribute } from '../../types/TestAttribute';
import { ContactActions, ContactActionsProps } from './ContactActions';
import { useFieldTranslations } from '../../hooks/useFieldTranslations';
import { translatedFieldNames } from '../../data/translatedFieldNames';

type Props = Pick<
  Roofer,
  'name' | 'dateCreated' | 'associatedPartners' | 'roofType' | 'services'
> &
  ContactActionsProps;

export const InfoSection: FC<Props> = ({
  name,
  dateCreated,
  associatedPartners,
  roofType,
  services,
  ...rest
}) => {
  const { t } = useTranslation('rooferInfo');
  const { fields } = useFieldTranslations(translatedFieldNames);
  return (
    <Details>
      <P>{t('common:contactInfo')}</P>
      <ContactActions {...rest} />
      <P>{t('common:info')}</P>
      <Entry>
        <P>{t('name')}</P>
        <P>{name}</P>
      </Entry>
      <Entry>
        <P>{t('createdAt')}</P>
        <P>{timestampToDate(dateCreated)}</P>
      </Entry>
      <Entry>
        <P>{t('brands')}</P>
        <P>{associatedPartners.map(capitalizeFirstLetter).join(', ')}</P>
      </Entry>
      <Entry>
        <P>{t('common:roofType')}</P>
        <P>{(fields && fields.roofType[roofType]) || t('common:undefined')}</P>
      </Entry>
      <Entry>
        <P>{t('services')}</P>
        <P data-test-id={TestAttribute.RooferServices}>
          {services
            .map((service) => t(`service:${service}`))
            .sort()
            .map((service, index) => (
              <span key={service}>
                {service}
                {index !== services.length - 1 && ','}
                <br />
              </span>
            ))}
        </P>
      </Entry>
    </Details>
  );
};
