import React, { FC, useState, useEffect } from 'react';
import { Container, CheckBoxLabel, Group } from './styled';
import { ReactComponent as Check } from '../../assets/ui/check.svg';

interface Props {
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  label?: string;
  onChange?: (checked: boolean) => any;
  className?: string;
}

export const Checkbox: FC<Props> = ({
  name,
  checked,
  disabled,
  onChange,
  required,
  error,
  label,
  className,
}) => {
  const [selected, setSelected] = useState(!!checked);
  const toggle = () => {
    if (disabled) {
      return;
    }
    setSelected(!selected);
    if (onChange) {
      onChange(!selected);
    }
  };
  useEffect(() => {
    if (selected !== !!checked) {
      setSelected(!!checked);
    }
  }, [checked]);

  return (
    <Group hasLabel={!!label} onClick={toggle} className={className}>
      <Container disabled={disabled} checked={selected} error={error}>
        <input
          type="checkbox"
          name={name}
          checked={selected}
          disabled={disabled}
          onChange={toggle}
          required={required}
        />
        <Check />
      </Container>
      {label && (
        <CheckBoxLabel htmlFor={name} disabled={disabled}>
          {label}
        </CheckBoxLabel>
      )}
    </Group>
  );
};
