import React, { memo, FC, ReactElement } from 'react';
import { config } from '../../config';
import GoogleMapReact, { MapOptions, Maps } from 'google-map-react';
import { Pin } from './styled';
import { Coordinates } from '../../types/Coordinates';

type Props = Coordinates & {
  pinIcon: ReactElement;
};

const getMapOptions = (maps: Maps): MapOptions => ({
  mapTypeId: maps.MapTypeId.SATELLITE,
  fullscreenControl: false,
  rotateControl: false,
  tilt: 0,
});

export const SatelliteMap: FC<Props> = memo(
  ({ lat, lon: lng, pinIcon: PinIcon }) => (
    <GoogleMapReact
      defaultZoom={19}
      options={getMapOptions}
      bootstrapURLKeys={{ key: config.googleMapsApiToken }}
      center={{ lat, lng }}
    >
      <Pin lat={lat} lng={lng}>
        {PinIcon}
      </Pin>
    </GoogleMapReact>
  ),
);
