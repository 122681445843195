import React from 'react';
import { usePlatform } from '@meindach/ui-kit';
import { Container, StickyBar } from './styled';
import { Route, Redirect, Switch } from 'react-router';
import { Route as RouteType } from '../../types/Route';
import { MobileNavigation } from '../../components/mobileNavigation/MobileNavigation';
import { OpportunityList } from '../../components/opportunityList/OpportunityList';
import { DesktopOpportunityFilters } from '../../components/opportunityFilters/DesktopOpportunityFilters';
import { MobileOpportunityFilters } from '../../components/opportunityFilters/MobileOpportunityFilters';
import { OpportunityMap } from '../../components/opportunityMap/OpportunityMap';
import { PanelContainer } from '../../components/panel/PanelContainer';
import { useOpportunityFilters } from '../../hooks/useOpportunityFilters';

export const Opportunities = () => {
  const { desktop } = usePlatform();
  const { active } = useOpportunityFilters();
  if (desktop) {
    return (
      <Switch>
        <Route
          exact
          path={RouteType.Opportunities}
          render={() => (
            <>
              <DesktopOpportunityFilters />
              <Container>
                <OpportunityList />
                <PanelContainer>
                  <OpportunityMap />
                </PanelContainer>
              </Container>
            </>
          )}
        />
        <Redirect to={RouteType.Opportunities} />
      </Switch>
    );
  }
  return (
    <>
      <StickyBar />
      <Container>
        <Switch>
          <Route
            exact
            path={RouteType.Opportunities}
            component={OpportunityList}
          />
          <Route
            exact
            path={RouteType.OpportunityMap}
            component={OpportunityMap}
          />
          <Route
            exact
            path={RouteType.OpportunityFilters}
            component={MobileOpportunityFilters}
          />
          <Redirect to={RouteType.Opportunities} />
        </Switch>
      </Container>
      <MobileNavigation
        tabs={[
          { route: RouteType.Opportunities, label: 'list' },
          { route: RouteType.OpportunityMap, label: 'map' },
          { route: RouteType.OpportunityFilters, label: 'filters', active },
        ]}
      />
    </>
  );
};
