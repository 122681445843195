import { NavigationControl, FullscreenControl } from 'react-map-gl';
import styled, { createGlobalStyle } from '../../styled-components';

export const MarkerStyle = createGlobalStyle`
  .mapboxgl-marker {
    transform: translate(-50%, -100%);
  }
`;

export const StyledNavigationControl = styled(NavigationControl)`
  position: absolute;
  right: 6px;
  bottom: 42px;
`;

export const StyledFullscreenControl = styled(FullscreenControl)`
  position: absolute;
  right: 6px;
  bottom: 110px;
`;

interface PinProps {
  lat: number;
  lng: number;
}

export const Pin = styled.div<PinProps>`
  > svg {
    width: auto;
    height: 35px;
    transform: translate(-50%, -100%);
  }
`;
