import React, { FC } from 'react';
import { StyledSelect } from './styled';
import { capitalizeFirstLetter, Option, SelectProps } from '@meindach/ui-kit';
import { ExistingPartner } from '../../types/Partner';
import { useRooferFilters } from '../../hooks/useRooferFilters';
import { trackEvent } from '../../util/trackEvent';

const Select = StyledSelect as FC<SelectProps<ExistingPartner>>;
const partnerList: ExistingPartner[] = [
  'braas',
  'icopal',
  'isover',
  'rathscheck',
  'rheinzink',
  'vedag',
  'velux',
  'wolfin',
];

interface Props {
  name?: string;
  placeHolder?: string;
}

export const PartnerFilter: FC<Props> = ({ name, placeHolder }) => {
  const { partners, setPartners } = useRooferFilters();
  const options: Option<ExistingPartner>[] = partnerList.map((value) => ({
    value,
    label: capitalizeFirstLetter(value),
  }));
  return (
    <Select
      name={name}
      options={options}
      placeHolder={placeHolder}
      onChange={setPartners}
      selectedValues={partners}
      onBlur={() =>
        trackEvent('filterRoofers', 'partners', JSON.stringify(partners))
      }
      multiple
    />
  );
};
