import styled from '../../styled-components';

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
