import React from 'react';
import { FileModal } from './FileModal';
import { useSelectedFile } from '../../hooks/useSelectedFile';

export const SelectedFileModal = () => {
  const { file, setFile } = useSelectedFile();
  if (!file) {
    return null;
  }
  return <FileModal {...file} onClose={() => setFile(null)} />;
};
